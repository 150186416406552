import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView, Vibration,Platform, TouchableOpacity} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Keyboard } from 'react-native';
import { LoadingView} from '../myComponent';
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMyContext } from '../MyContext';
import * as Haptics from 'expo-haptics';
import Typewriter from "../typeWriter";
import axios from 'axios';
import { stylesComponent } from '../myComponent';
const AdminHomePage = ({ navigation, route }) => {






  const {contextTrigger, setcontextTrigger}=useMyContext();


    //const while loading
    const [Loading, setLoading] = useState(false);
    //hold errors
      const[Error, setError]=useState('');


    //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
//variable t show all
const{Sellers, setSellers}=useMyContext();
const{Users, setUsers}=useMyContext();
const{Products, setProducts}=useMyContext();
const{Orders, setOrders}=useMyContext();
const{adminOrderIdData, setadminOrderIdData}=useMyContext();
const{adminOrderIdDataKopie, setadminOrderIdDataKopie}=useMyContext();


//admin is logged in
const{Admin, setAdmin}= useMyContext({});
const{AdminIsLoggedIn, setAdminIsLoggedIn}=useMyContext();

   


//if user not set throw the user out
  useEffect(() => {
    if(!AdminIsLoggedIn){
        navigation.navigate('home');}
  }, [AdminIsLoggedIn]);
//fetch all info 



const myFetchDataFunction = () => {


  const admin_mail=Admin[0].email;
  const Admin_password=Admin[0].passwort;


  axios.post('https://tunizoon.com/appgate/AdminHomePage.php?action=FetchAll', `Admin_mail=${admin_mail}&Admin_password=${Admin_password}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
     
          .then((response) => {
            const json = response.data;

            setSellers(json.sellers);
            setUsers(json.users);
            setProducts(json.products);
            setOrders(json.orders);
            setadminOrderIdData(json.ordersIdData);
            setadminOrderIdDataKopie(json.ordersIdData);
  
       
          })
          .catch((error) => {
            console.error(error);
          });
        };

        

  
        //use effect to call fetch


        //use effect to call fetch
        useEffect(() => {
            myFetchDataFunction();
        }, [contextTrigger]);


const logout=()=>{

setAdmin();
setSellers();
setProducts();
setOrders();
setUsers();
navigation.navigate('home'); 


}
  



if (Admin === undefined||Sellers === undefined||Users=== undefined||Products === undefined||Orders === undefined) {
  return <SafeAreaView style={styles.androidSafeArea}>

<LoadingView/>


  </SafeAreaView>; // or any other loading indicator
}


  






  return (
    <KeyboardAvoidingView>


<SafeAreaView style={styles.androidSafeArea}>
<View style={styles.HeadHomePageAdmin}>
      <View style={styles.HeadHomePageAdminNameAndLogout}>
        <View style={styles.HeadHomePageAdminNameView}>
             <Text style={styles.HeadHomePageAdmintext}>Admin: {Admin[0].email}</Text>
        </View>

        <TouchableOpacity onPress={logout} style={styles.HeadHomePageAdminLogout}><Text style={styles.HeadHomePageAdmintext}>Logout</Text></TouchableOpacity>

     </View>
</View>


{/**menu for admin */}
<View style={styles.MenuChildView}>

  <TouchableOpacity onPress={()=>{navigation.navigate('adminSellers')}}   style={styles.MenuChildViewButton}><Text style={styles.MenuChildViewText}>Sellers <Text style={{color:"green"}}>{Sellers.length}</Text></Text></TouchableOpacity>
  <TouchableOpacity  onPress={()=>{navigation.navigate('adminUsers')}} style={styles.MenuChildViewButton}><Text style={styles.MenuChildViewText}>Users <Text style={{color:"green"}}>{Users.length}</Text></Text></TouchableOpacity>

  <TouchableOpacity onPress={()=>{navigation.navigate('adminProducts')}} style={styles.MenuChildViewButton}><Text style={styles.MenuChildViewText}>Products <Text style={{color:"green"}}>{Products.length}</Text></Text></TouchableOpacity>


  <TouchableOpacity  onPress={()=>{navigation.navigate('adminOrders')}} style={styles.MenuChildViewButton}><Text style={styles.MenuChildViewText}>Orders <Text style={{color:"green"}}>{Orders.length}</Text></Text></TouchableOpacity>


  <TouchableOpacity style={styles.MenuChildViewButton}><Text style={styles.MenuChildViewText}>claim</Text></TouchableOpacity>

  <TouchableOpacity style={styles.MenuChildViewButton}><Text style={styles.MenuChildViewText}>Task</Text></TouchableOpacity>


</View>


</SafeAreaView>
    </KeyboardAvoidingView>
  );
};
const styles = StyleSheet.create({
  androidSafeArea: { height: "100%",paddingTop: Platform.OS === 'android' ? 25 : 0,
 backgroundColor: "#fcb103"},
  HeadHomePageAdmin:{
height:"auto", width:"100%", flex:1, flexDirection:"column",
 backgroundColor: "red", maxHeight:100,


  },
  HeadHomePageAdminNameAndLogout:{

    height:"auto", width:"100%", 
    flex:1, flexDirection:"row",justifyContent:"space-between",
    backgroundColor: "#fcb103",paddingHorizontal:20,paddingVertical:30
  },
  HeadHomePageAdminNameView:{
    height:40, width:"auto",  justifyContent:"center", alignSelf:"center",paddingHorizontal:10,
  },
  HeadHomePageAdmintext:{fontWeight:"bold",color:"white"},
  HeadHomePageAdminLogout:{
    height:40, width:100, backgroundColor:"#360099", justifyContent:"center",
     alignItems:"center", alignSelf:"center",paddingHorizontal:10,borderRadius:4,
  },
//menu for admin
MenuChildView:{

  width:"100%", height:"auto", paddingHorizontal:20,paddingVertical:50,backgroundColor:"white"
},


MenuChildViewButton:{
  height:40, width:"90%", marginHorizontal:"5%",marginVertical:20,
  justifyContent:"center", alignItems:"center",paddingHorizontal:10,borderWidth:1,borderRadius:10,
},

MenuChildViewText:{fontWeight:"bold"},


});

export default AdminHomePage;
