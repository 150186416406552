import React, { useRef, useEffect, useState } from 'react';
import { View, Image, StyleSheet, Animated, Platform ,Text} from 'react-native';
import BigTunizoonLogo from './bigTunizoonLogo';

export const SplashScreen = () => {
  const slideAnim = useRef(new Animated.Value(-200)).current;
 

const [backgroundcolor,setbackgroundcolor]=useState('#00BFFF');

const setBackgroundcolor=(color)=>{

  setbackgroundcolor(color);

}
  return (
    <View style={ { height:"100%", width:"100%",
 backgroundColor:backgroundcolor}}>
      <View

        style={
          stylesSplashScreen.imageContainer
          }
      >
   
     <BigTunizoonLogo setBackgroundColor={setBackgroundcolor}/>

      </View>
    </View>
  );
};

export const stylesSplashScreen = StyleSheet.create({




});

export default SplashScreen;
