

import React, { useEffect, useState,useRef, useCallback, useContext} from 'react';
import { Modal,ActivityIndicator,VirtualizedList, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform,Animated,Dimensions, Touchable, TouchableOpacityBase} from 'react-native';
import { LoadingView, Category, SocialMediaContainer } from './myComponent';

import { Audio} from 'expo-av'; 
import SplashScreen from './splashScreen';
import Autocomplete from './Autocomplete';
import { Ionicons } from '@expo/vector-icons';
import Cart from './cart';
import NotificationUser from './notificationUser';
import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import MenuLoginUser from './menuLoginForUser';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';

import { Entypo } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons'; 
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import Typewriter from "./typeWriter";
import { useMyContext } from './MyContext';
import { TouchableOpacity } from 'react-native-gesture-handler';
import * as Haptics from 'expo-haptics';
import axios from 'axios';
import  TunizoonLogo from './tunizoonlogo';
import BigTunizoonLogo from './bigTunizoonLogo';
import TermAndConditions from "./termAndConditions";
import { useFonts } from 'expo-font';



const isWeb = Platform.OS === 'web';










const HomeScreen = ({ navigation})  => {

//hide and show modal for term and conditions
const[showTermAndConditions, setshowTermAndConditions]=useState(false);


const showAndHideTermAndCondition = () => {
  triggerHapticFeedback();
  setshowTermAndConditions(!showTermAndConditions);
};

  //to hide the splash screen after the first display
const {SplashScreenShowEnded,setSplashScreenShowEnded}=useMyContext(false);
//input search to show user products based on inputs
const {userSearchInput, setuserSearchInput}=useMyContext('');





//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
 













//  notification text, notification new top, date, time 
//holding errors
const [Error, setError] = useState('');


  useFocusEffect(
    React.useCallback(() => {
      
        setLoading(true);
      

      // allow virtualized list to rerender
      setVirtualzedShown(true);
   
      setTimeout(() => {
        setLoading(false);
      }, 400);
      return () => {
            // disallow virtualized list to rerender
        setVirtualzedShown(false);

 

     

      };
    }, [])
  );
















  //var for modal menu
  const [showMenu, setshowMenu] = useState(false);

  //var fpr modal notification
  const [showNotification,setshowNotification]=useState(false);
  //show modal filtering
  const [showFilter,setshowFilter]=useState(false);

//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);


};

//function to show menu
const toggleFilter= () => {
  triggerHapticFeedback();

setshowFilter(!showFilter);

};


//function to show menu
const toggleNotification= () => {

  triggerHapticFeedback();

  setshowNotification(!showNotification);

};
//Notification user 
//user notification
const {OrdersNotificationUser , setOrdersNotificationUser}= useMyContext({});
const {ReplyNotificationUserProduct , setReplyNotificationUserProduct}= useMyContext({});
const {ReplyNotificationUserSeller , setReplyNotificationUserSeller}= useMyContext({});
const {OrdersNotificationsFrames, setOrdersNotificationsFrames}= useMyContext({});
const {contextTrigger, setcontextTrigger}=useMyContext();


const [Loading, setLoading]=useState(false);
//fetch notification 
//count notification will be later updated with the web socket
const {userNotificationCount, setuserNotificationCount}= useMyContext();



const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();









//fetch notifications
useEffect(() => {
  if (contextTrigger) {
    if (User && Object.entries(User).length !== 0) {
      const key = "10876767594ewjhe";
      const user_id = User[0].user_id;
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded
    
      };

      // Construct the form data
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('Key', key);

  
      axios.post('https://tunizoon.com/appgate/userNotification.php?action=fetchNotification', formData, {
        headers: headers, 
      })
      

        .then(response => {
          if (!response.data) {
            throw new Error(`No data received from the API`);
          }

          setOrdersNotificationUser(response.data.Orders);
          setReplyNotificationUserProduct(response.data.replyProduct);
          setReplyNotificationUserSeller(response.data.replySellerShop);
          setuserNotificationCount(response.data.countNotificationsUser);
          setOrdersNotificationsFrames(response.data.OrdersNotificationsFrames);
          setcontextTrigger(!contextTrigger);
        })
        .catch(error => {
          console.error('API error:', error);
        });
    }
  }
}, [contextTrigger, User, setOrdersNotificationUser, setReplyNotificationUserProduct, setReplyNotificationUserSeller]);








//when aDD to button is pressed feedback
const [pressedButton, setPressedButton] = useState(null);





const[currentScrolledCategory, setcurrentScrolledCategory]=useState("");
  const handleListScrollEndDrag = (containerCategory) => () => {
    setcurrentScrolledCategory(containerCategory);
    
  };
  const screenWidth = Dimensions.get('window').width;

  const screenHeight = Dimensions.get('window').height;
  
  const [rerenderTrigger, setRerenderTrigger] = useState({});
  const [extraData, setExtraData] = useState({
    category: "nothing",
    Plus: true,
    Minus: false,
    scrollPosition:0,
    DistanceFromEnd:0,
  distanceFromStartToEnd:0
  });


  const [StartIndex,setStartIndex]=useState({});
  const [EndIndex,setEndIndex]=useState({});

  const [image, setImage] = useState(null);


/*

  useEffect(() => {
    
    // This effect will be triggered whenever `rerenderTrigger` changes
    // You can put any additional logic here that should be executed on re-render
  }, [rerenderTrigger,extraData,StartIndex,EndIndex,countProductCart]);

*/
















//user and seller Objects
  const{User, setUser}= useMyContext({});
  const{Seller, setSeller}= useMyContext([]);



const {VirtualzedShown,setVirtualzedShown}=useMyContext();





//const using useEffect and State to show and hide the scrollView for cart


  const ScrollSpyRef=useRef(null);
//const for the scrollspy function nb all ref are created with loop and stored in arrays
  const ViewRefs = useRef([]);
 const  FlatLists=useRef([]);
  const ButtonsRefs=useRef([]);
  const SellerDetailInCartRef=useRef([]);

  const TextButtonsMenuRef=useRef([]);



  const [userId, setUserId] = useState('');
  const [userSku, setUserSku] = useState('');
  const [name, setname] = useState('');


  const [ProductCategoriesData, setProductCategoriesData] = useState({});
  const [ProductData, setProductData] = useState({});







  //user and seller objects


//const to save the products, count, sellers, and total price when added 
const {sellersArray, setsellersArray}=useMyContext();
const { cart, setCart } = useMyContext();
const{totalPrice, settotalPrice} = useMyContext();
const{totalDeliveryForAll, setTotalDeliveryForAll} = useMyContext();

const {countProductCart, setCount} = useMyContext();


const {sellersSku, setsellersSku} = useMyContext([]);



const [showSellerInfoCartViewRef, setshowSellerInfoCartViewRef]=useState(false);


  const ScrollViewRef = useRef(null);
 





const handleClickButtonsAddToCart = async (productSku, sellerSku, productWeight, sellerToCart, productToCart) => {
triggerHapticFeedback();


  try {
    const existingProductIndex = cart.findIndex((item) => item[productSku] !== undefined);
    const existingSellerIndex = sellersSku.findIndex((item) => item[sellerSku] !== undefined);

    if (existingProductIndex >= 0) {
      const existingCartItem = cart[existingProductIndex][productSku];
      existingCartItem.productQuantity += 1;
      const existingSellerItem = sellersSku[existingSellerIndex][sellerSku];
      existingSellerItem.productQuantityProSeller += 1;
      existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) + parseFloat(productWeight);
  
      const price = existingCartItem.productPrice * 1;
      existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(price);

      settotalPrice((prevtotalPrice) => {
        const totalPrice = prevtotalPrice + price;
        return Number(totalPrice.toFixed(2));
      });
    } else {
      const newCartItem = { [productSku]: productToCart[productSku] };
      newCartItem[productSku].productQuantity = 1;






      setCart((prevCart) => [...prevCart, newCartItem]);

      const price = newCartItem[productSku].productPrice * 1;
      settotalPrice((prevtotalPrice) => {
        const totalPrice = prevtotalPrice + price;
        return Number(totalPrice.toFixed(2));
  
      });


      if (existingSellerIndex >= 0) {
        const existingSellerItem = sellersSku[existingSellerIndex][sellerSku];
        existingSellerItem.productQuantityProSeller += 1;
        existingSellerItem.productWeightProSeller = (existingSellerItem.productWeightProSeller*1) +(productWeight*1);
      

        existingSellerItem.totalSeller = (existingSellerItem.totalSeller*1) + (price*1);
      } else {
        const newSeller = { [sellerSku]: sellerToCart[sellerSku] };

       
   
        setsellersSku((prevsellersSku) => [...prevsellersSku, newSeller]);
      }
    }

  } catch (error) {
    // Handle error
 
  } finally {



    setCount((countProductCart) => countProductCart + 1);
  }
};


// Function to calculate and return the modified value of the weight in cart foreach seller
const calculateWeight = (weight) => {
  if (weight >= 1000) {
    weight /= 1000;

  }
  return Number(weight.toFixed(3));
};
























// Function that construct the stars for rating 
  function StarRating({ score }) {

    if(score){
    const filledStars = Array(parseInt(score)).fill().map((_, index) => (
      <AntDesign key={`filled-${index}`} name="star" size={14} color="#fcb103" />
    ));
    const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
      <AntDesign key={`empty-${index}`} name="star" size={14} color="grey" />
    ));
    const stars = [...filledStars, ...emptyStars];
    return <Text>{stars}</Text>;
  }
  }
  const [visibleData, setVisibleData]=useState([]);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20); // Number of items to load per page



  const [showSplaschScreen, setshowSplaschScreen]=useState(true);
  const [NetworkProblem, setNetworkProblem]=useState('');
      //function to fetch data from tunizoon.com

const [LoadingFilter,setLoadingFilter]=useState(false);



//funciton to set and reset the tree
const settargetCategory =(text) => {
  setLoadingFilter(true);
  triggerHapticFeedback();
  settargetCategoryText(text);

  if (!grandpa) {
    setgrandpa(text);
    setcontextTrigger(!contextTrigger);
  }
if(grandpa&&!father){

  setfather(text);
setcontextTrigger(!contextTrigger);

}
if(grandpa&&father&&!son){

  setson(text);
setcontextTrigger(!contextTrigger);

}

if(grandpa&&father&&son){
 setgrandSon(text);
 setcontextTrigger(!contextTrigger);
}


}

const resetCategorySearch=()=>{
 setLoadingFilter(true);
  triggerHapticFeedback();

  setgrandpa('');
  setfather('');
  setson('');
  setgrandSon(''); 
  setcontextTrigger(!contextTrigger);

}



      //target category to set the tree
    const[targetCategoryText, settargetCategoryText]=useState('');
  const[grandpa,setgrandpa]=useState('');
  const[father,setfather]=useState('');
  const[son,setson]=useState('');
  const[grandSon,setgrandSon]=useState('');



  const myFetchDataFunction = () => {

    setLoading(true);
let product_sku;
let seller_sku;
let user_id;
if (UserIsLoggedIn) {

  user_id=User[0].user_id;
}else{
  user_id='';
}

//find product to navigate to from url
if(Platform.OS==="web"){
    const url = window.location.href;
    const regex = /[?&]product=([^&]+)/;
    const match = url.match(regex);
    
    if (match) {
      const productValue = match[1];
   
      product_sku=productValue/93;
      setSplashScreenShowEnded(true);
    } 
    
  }

  //find shop to navigate to from url
if(Platform.OS==="web"){
  const url = window.location.href;
  const regex = /[?&]seller=([^&]+)/;
  const match = url.match(regex);
  
  if (match) {
    const sellerSku= match[1];

    seller_sku=sellerSku/43;
    setSplashScreenShowEnded(true);
 
  } 
  
}

    
    


axios.post('https://tunizoon.com/endkunde.php?action=fetchAll', `product_sku=${product_sku}&seller_sku=${seller_sku}&targetcategorytext=${targetCategoryText}&grandpa=${grandpa}&father=${father}&son=${son}&grandson=${grandSon}&user_search_input=${userSearchInput}&user_id=${user_id}`, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})
   
        .then((response) => {
          const json = response.data;
          setProductCategoriesData(json.products_categories);
          setProductData(json.Products_data);
          
          setVisibleData(json.Products_data);
          setsellersArray(json.Sellers);
   
          if(json.product_link&&!SplashScreenShowEnded){
            setSplashScreenShowEnded(true);
          navigation.navigate('productPage', {propName:json.product_link });
    
        }

        if(json.seller_shop&&!SplashScreenShowEnded){
          setSplashScreenShowEnded(true);
          navigation.navigate('sellerPage', {propName:json.seller_shop});
         
        }



            if(json&&SplashScreenShowEnded){
       
                setLoadingFilter(false);
  
                setLoading(false);
              setTimeout(() => {
             
                setshowSplaschScreen(true);
              }, 100);
            
 }
            else{
        
             // setNetworkProblem("Vous avez un problème avec votre connexion internet. Essayez plus tard ou cherchez un autre emplacement où le réseau n'est pas faible, walla arja3 8odwa mela bech twennsa \u{1F607}");
         
            }
       

        })

        .catch((error) => {
          console.error(error);
        });
        setTimeout(() => {
          setLoading(false);
        }, 350);

      };
    
      useEffect(() => {

          myFetchDataFunction();
      
      }, [contextTrigger]);



  
  
  
  
  
  
  

 


 

 







//Function when a button clicked jump to the section that have the category
  const jumpToCategory = (Category) => {

  



    if (Platform.OS!=="web") {
    triggerHapticFeedback();
    const ViewRef = ViewRefs.current[Category];
    if (ViewRef && ScrollViewRef.current) {
    ViewRef.measureLayout(
      ScrollViewRef.current,
      (x, y) => {
    
        ScrollViewRef.current.scrollTo({y: y-140, animated: true});

      },
   
   
    );
    }
  }

  else {
    const container = ScrollViewRef.current;
    const ViewRef = ViewRefs.current[Category];
    if (ViewRef) {
      const { top, bottom } = ViewRef.getBoundingClientRect();
      
      // Calculate the desired scroll position based on the element's position
      const scrollX = 0; // Horizontal position (0 for the left)
     const scrollY=top+ window.scrollY -140;

    
    
      
      // Scroll the window to the desired position
      window.scrollTo({
        top: scrollY,
        left: scrollX,
        behavior: 'auto', // 'auto' for instant scroll, 'smooth' for animated scroll
      });
    }
    
  }




  }







//show detail of seller in cart, wieght price...
  const showDetailSellerCartText=(targetView)=>{
    const targetViewToChange = SellerDetailInCartRef.current[targetView];


    if (targetViewToChange!= null) {
      Platform.OS === 'web'
      ? (targetViewToChange && (targetViewToChange.style.display= showSellerInfoCartViewRef? 'none' :'flex'))
      : 
      targetViewToChange.setNativeProps({
        style: {
         display:showSellerInfoCartViewRef? 'none' :'flex'
        }
      });

setshowSellerInfoCartViewRef(!showSellerInfoCartViewRef);
   
  }

}




const [HighlightedButton, setHighlightedButton] = useState('');
let highlightedButton="";
const [headHeight, setheadHeight]=useState(170);










//Function to  imlement the ScrollSpy behavior to the menu of categories when scrolled
//on screen to get wich list are actually on screen


const [showFilterView, setshowFilterView]=useState(true);

  const onScroll = (event, ProductCatDataForWeb) => {
 

    //head part

let Offset;
if(!isWeb){
 Offset=event.nativeEvent.contentOffset.y;
}
else{
  Offset=window.scrollY;

}

if(  Offset>100){

  setshowFilterView(true);
}
else{
  setshowFilterView(true);
}










  //for web
// Web version
if (Platform.OS === 'web') {



  ProductCatDataForWeb.map((ContainerCategory, index) => {
   
    const element = ViewRefs.current[`${ContainerCategory}q`];
    const container = ScrollSpyRef.current;
    const Button = ButtonsRefs.current[`${ContainerCategory}`];
  
    // Get the bounding box of the element's content relative to the viewport
    const { top, bottom,left} = element.getBoundingClientRect();
  
    if (top<160  && top>=-200 &&top!==0) {
      
      // Setting the Buttons Background when the View that has the category is on the Top of Scroll Container
      ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({
        style: { backgroundColor: 'black' , borderRadius:10,},
      });
     
  

     
    
      if (highlightedButton !== ContainerCategory)  {
  
        // Scroll the container to bring the element into view
        const containerLeft = container.getBoundingClientRect().left;
        const ButtonLeft = Button.getBoundingClientRect().left;
        // Button.innerHTML = ButtonLeft.toString();
        container.scrollLeft = ButtonLeft-10;
        highlightedButton=ContainerCategory;
   


      }

    
  
 
      // Setting the text of the buttons color when the View that has the category is on the Top of Scroll Container
      TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({
        style: { color: 'white' },
      });
    } else {
      // Setting the Buttons Background when the View that has the category is not more on the first position
      ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({
        style: { backgroundColor: 'white' },
      });
  
      // Setting the text of the buttons color when the View that has the is not more on the first position
      TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({
        style: { color: 'black' },
      });
    }
  });
  }
  

  //for mobile native
  if (Platform.OS !== 'web') {

    ProductCategoriesData.map((ContainerCategory, index) => {
      ViewRefs.current[`${ContainerCategory}q`].measure((x, y, width, height, pageX, pageY) => {

 if(pageY<200  && pageY>=-165){
        



  ButtonsRefs.current[`${ContainerCategory}`].measureLayout(
    ScrollSpyRef.current,
    (x, y, width, height) => {
      let scrollTarget=x-10;
      if(HighlightedButton!==ContainerCategory){
        //triggerHapticFeedback();
        }
   // Update the state based on the previous state
   setHighlightedButton(prevState => {
    return ContainerCategory;
  });

  
  ScrollSpyRef.current.scrollTo({ x: scrollTarget, animated: false});


  //Setting the Buttons Background when the View that have the category is on the Top ofScroll Container

  ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({style: {backgroundColor: 'black' , borderRadius:10,} });

  //Setting the text of the buttons color when the View that have the category is on the Top ofScroll Container
   TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({style: {color: 'white', } });


    }
  );







 }
 else 
 {
        //Setting the Buttons Background when the View that have the category is not more on the first position

        ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({ style: { backgroundColor: 'white' } });
        //Setting the text of the buttons color when the View that have the is not more on the first position

        TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({style: { color: 'black', }});
}

     
});
    

});

}



};
  

//Function to  render flatlist of products<Text>{StartIndex[`${ContainerCategory}start`]}//  {index}//  {EndIndex[`${ContainerCategory}end`]}</Text>




const renderListItem = ({item, index, startIndex,ContainerCategory}) => {
  const uniqueKey = `${item.product_sku}_${index}`;

    // Find the item with the matching productSku in cart to reduce quantity that user can put in cart when stock is not enough
    let productQuantityinCart=0;
    const productInCart = cart.find((obj) => obj[Object.keys(obj)[0]].productSku === item.product_sku);

    if(productInCart){
     productQuantityinCart=productInCart[item.product_sku].productQuantity;
    }

  return (
  
    <View key={uniqueKey} style={styles.renderContainer}>


          <View style={styles.wrapperProductView} >


    <View style={styles.productView} >



    <Pressable style={styles.wrapperProductViewPressable} onPress={() => {triggerHapticFeedback();navigation.navigate('productPage', {propName: item});}} >
 

   <Image source={{ uri: `https://tunizoon.com/${item.product_image0}` }} style={styles.productImage} />


<View style={styles.productnameView}>


<Text style={styles.productName}>{item.product_name.slice(0, 14)+ '...'}</Text>
</View>


<View style={styles.productScoreView}>

<StarRating  score={item.product_score} />


</View>

<View style={styles.productDescriptionView}>

<Text style={styles.productDescritption}>{item.product_description.slice(0, 20)+ '...'}</Text>
</View>





</Pressable>

{sellersArray.length > 0 && sellersArray.map((seller, indexSeller) => (


<View key={indexSeller}>
{seller.sku_seller===item.seller_sku? ( 

<View>

{item.stock>0&&item.stock-productQuantityinCart>0?(

<Pressable
       //onPressIn={() => handlePressIn(product.product_sku)}
      // onPressOut={handlePressOut}
      
    onPress={() => {
      const sellerToCart={
        [`${item.seller_sku}`]:{
          productSellerName: `${item.seller_name}`,
          productSellerSku: `${item.seller_sku}`,
          productSellerEmail: `${item.seller_email}`,
          productSellerPhoneNumber:`${seller.tel}`,
          productSellerBankAccount: `${seller.seller_bank_account}`,
          productSellerBankName: `${seller.seller_bank_institution}`,
          productSellerScore:`${seller.seller_score}`,
          productSellerImage: `${item.seller_image}`,
          productQuantityProSeller:1,
          productWeightProSeller:`${item.product_weight}`,
          totalSeller:item.sale ?  `${((item.product_price/100)*(100-item.sale)).toFixed(2)}`:`${item.product_price}`,
        }
      };

const productToCart = {
  [`${item.product_sku}`]: {
    productSku: `${item.product_sku}`,
    productName: `${item.product_name}`,
    productScore:`${item.product_score}`,
    productImage: `${item.product_image0}`,
    productSellerName: `${item.seller_name}`,
    productSellerSku: `${item.seller_sku}`,
    productSellerEmail: `${item.seller_email}`,
    productSellerTel: `${item.seller_tel}`,
    productSellerAdress: `${item.seller_adress}`,
    productSellerImage: `${item.seller_image}`,
    returnPolicy:`${item.return_policy}`,
    productPrice: item.sale ?  `${((item.product_price/100)*(100-item.sale)).toFixed(2)}`:`${item.product_price}`,
    productStock: item.stock,
    productWeight: `${item.product_weight}`,
    productQuantity:1,
  }

}


; 

handleClickButtonsAddToCart(item.product_sku, item.seller_sku,item.product_weight, sellerToCart, productToCart);
}}


      

style={({pressed}) => [
  {
    opacity: pressed ? 0.5 : 1,
  },
  styles.productPriceButton,
]}
    > 


{item.sale?(
     <Text style={styles.productPriceTextSaleOldPrice}>{item.product_price} Tnd </Text>):null}
      <Text style={styles.productPriceText}>
       

   {item.sale?((item.product_price/100)*(100-item.sale)).toFixed(2)+' Tnd ':item.product_price+' Tnd '}<AntDesign name="shoppingcart" size={16} color="black" />
  </Text>
     </Pressable>

):(<Pressable style={styles.outOfStockButton}><Text style={styles.outOfStockButtonText}>stock épuisé</Text></Pressable>)}














     </View>):(null)}
     </View>
     
))}


    </View> 
  
    <View style={styles.sellerNameAndLink}>
    <Image source={{ uri: `https://tunizoon.com/${item.seller_image}` }} style={styles.sellerImageProductView} ></Image>
    <Pressable onPress={() => {triggerHapticFeedback();navigation.navigate('sellerPage', {propName:item});}}  style={styles.sellerNameProductView}>
      <Text style={styles.sellerNameProductViewText}>{item.seller_name}</Text></Pressable>

   </View>

   {item.sale?(<Pressable style={styles.productSale}><Text style={styles.productSaleText}>- {item.sale}%</Text></Pressable>):null}

   
   </View>



</View>
  );

 

  

  
 
    






};


useEffect(() => {
  const handleScroll = (event) => {
    onScroll(event, ProductCategoriesData);
  };

  if (isWeb ) {
    window.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (isWeb) {
      window.removeEventListener('scroll', handleScroll);
    }
  };
}, [isWeb, ProductCategoriesData]);

/*check if user verified mail


useEffect(() => {
  if (UserIsLoggedIn && User[0].user_verification !== "1") {

      navigation.navigate('confirmUserMail', { UserMail: User[0].email });


 
  }
}, [UserIsLoggedIn,contextTrigger]);

*/

  return (



//the Safe Area View



<SafeAreaView    style={Platform.OS!=="web"?styles.androidSafeArea:styles.WebSafeArea}  >



<Modal
          animationType={'slide'}
          transparent={true}
          visible={showTermAndConditions}
          onRequestClose={() => {
         
          }}
>

<TermAndConditions  closeTermAndConditions={showAndHideTermAndCondition} acceptAndcloseTermAndConditions={showAndHideTermAndCondition} />
</Modal>






{!SplashScreenShowEnded?(<View  style={styles.splashScreenWrapper} >



  {NetworkProblem?(
 <View style={styles.SuccessView}>
 <Typewriter text={NetworkProblem} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setNetworkProblem();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }
<SplashScreen />


</View>):null}




{/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
         
          }}
>

<MenuLoginUser  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>


{/*notification section */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showNotification}
          onRequestClose={() => {
         
          }}
>

<NotificationUser Customnavigation={navigation} toggle={toggleNotification}/>

</Modal>







{/*filter modal  */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showFilter}
          onRequestClose={() => {
         
          }}
>
<SafeAreaView style={styles.filterView}>



  <View style={styles.filterViewHead}>
<TouchableOpacity style={styles.filterViewHeadButtons} onPress={resetCategorySearch}>
  <Text style={styles.filterViewHeadButtonsText}><MaterialIcons name="replay" size={30} color="#333333" /></Text></TouchableOpacity>
<TouchableOpacity style={styles.filterViewHeadButtons}  onPress={toggleFilter}>
 <Text style={styles.filterViewHeadButtonsText}><AntDesign name="close" size={30} color="#333333" /></Text>
</TouchableOpacity>

</View>


<View style={styles.filterViewBodyFlex}>





{LoadingFilter?(
<View style={styles.filterViewBodyFlexLoading}>

<ActivityIndicator size="large" color="black" style={{ alignSelf: 'center'}} />

</View>


):(
<View>

  {/*The loop through ProductCategoriesData to create the Buttons foreach category */}
  {ProductCategoriesData.length > 0 &&
    ProductCategoriesData.map((ContainerCategory, index) => (
      ContainerCategory=="Top Deals"||ContainerCategory=="produits soldés"|| ContainerCategory=="sélectionné pour vous"
      ?null:(
 <View style={styles.FilterViewButtonsView} key={index}>
{/*The Category Buttons each point to View in the next section */}


<Pressable
onPress={()=>{settargetCategory(ContainerCategory)}}
  style={styles.buttonsFilterView}>
  <Text style={styles.textButtonsScrollSpy}>{ContainerCategory}</Text>
</Pressable>




      </View>
      )


    ))}
</View>
)}
















<View>
<View style={styles.pathView}>

<Text style={styles.pathViewButtonText}>
  {grandpa?<Text>{grandpa}<Entypo name="chevron-thin-right" size={14} color="black" /> </Text>:null}
  {father?<Text>{father}<Entypo name="chevron-thin-right" size={14} color="black" /> </Text>:null}
  {son?<Text>{son} <Entypo name="chevron-thin-right" size={14} color="black" /> </Text>:null}
    {grandSon?<Text>{grandSon} <Entypo name="chevron-thin-right" size={14} color="black" />  </Text>:null}


</Text>
</View>
<View>
<TouchableOpacity style={styles.buttonSeeResults} onPress={toggleFilter}>
{ProductData.length>1?(
  <Text style={styles.buttonSeeResultsText}>{ProductData.length} résultats </Text>
):(
  <Text style={styles.buttonSeeResultsText}>{ProductData.length} résultat </Text>
)}
</TouchableOpacity>
</View>
</View>









</View>

</SafeAreaView>

</Modal>










































{/*page head section only for web */}

 {Platform.OS==="web"?(
  <>
<View  style={styles.headSectionFixedForWeb}>
{/*container for head section only for web*/}
<View style={{height:70, overflow:"hidden",transition: 'height 0.02s ease' }}>
<View  style={styles.logoAndButtonContainer}>




{userNotificationCount&&userNotificationCount>0?( <Pressable style={styles.notificationButton} onPress={toggleNotification}>
    <Text><AntDesign name="bells" size={30} color="black"/></Text>
    <View style={styles.notificationButtonTextView}>
    <Text style={styles.notificationButtonText}>{userNotificationCount}</Text>
    </View>
    </Pressable>):<Pressable style={styles.notificationButton} >
    <Text><AntDesign name="bells" size={30} color="black"/></Text>
 
    </Pressable>}
 
    <View><TunizoonLogo/></View>
    <TouchableOpacity   onPress={toggleMenu}  style={styles.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></TouchableOpacity >

</View>






  {/*container for headt text only for web*/}




  </View>






</View>
{/*Impoting the Autocomlpete function from Autocomplete.js*/}

<View style={styles.stickyTop}>
<Autocomplete navigation={navigation} />





{/*Scrollspymenu*/}
<ScrollView ref={ScrollSpyRef} horizontal={true} showsHorizontalScrollIndicator={false} style={styles.scrollSpyMenu}>

  {/*The loop through ProductCategoriesData to create the Buttons foreach category */}
  {ProductCategoriesData.length > 0 &&
    ProductCategoriesData.map((ContainerCategory, index) => (
 <View key={index}>
{/*The Category Buttons each point to View in the next section */}
<Pressable
ref={(ref) => {ButtonsRefs.current[ContainerCategory] = ref}} 
onPress={() => jumpToCategory(`${ContainerCategory}q`)}
  style={styles.buttonsScrollSpy}>
  <Text ref={(ref) => {TextButtonsMenuRef.current[ContainerCategory] = ref}} style={styles.textButtonsScrollSpy}>{ContainerCategory}</Text>
</Pressable>

      </View>
    ))}
<Pressable
  style={styles.buttonsScrollSpy}>
  <Text></Text>
</Pressable>
</ScrollView>

</View>

</>
 ):null}




























{/*the Body of the page that contain all products sorted in different categories */}



  <ScrollView stickyHeaderIndices={[1]} style={styles.root} scrollEnabled={true} 
 ref={ScrollViewRef} veritcal={true} showsVerticalScrollIndicator={false} 
   scrollEventThrottle={20} 
   onScroll={(event) => onScroll(event, ProductCategoriesData)}
  
  keyboardShouldPersistTaps='handled'
  
  >
    

   



  {/*container for head section only for native*/}
 {Platform.OS!=="web"?(<View  style={styles.logoAndButtonContainer}>


  
{userNotificationCount&&userNotificationCount>0?( <Pressable style={styles.notificationButton} onPress={toggleNotification}>
    <Text><AntDesign name="bells" size={30} color="black"/></Text>
    <View style={styles.notificationButtonTextView}>
    <Text style={styles.notificationButtonText}>{userNotificationCount}</Text>
    </View>
    </Pressable>):( <Pressable style={styles.notificationButton}>
    <Text><AntDesign name="bells" size={30} color="black"/></Text>
  
    </Pressable>)}

    <View><TunizoonLogo/></View>
    <TouchableOpacity   onPress={toggleMenu}  style={styles.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></TouchableOpacity >

</View>

):null}










{/*Impoting the Autocomlpete function from Autocomplete.js*/}
 {Platform.OS!=="web"?(
  <View style={{backgroundColor:"#00BFFF"}}>
<Autocomplete navigation={navigation} />


{/*Scrollspymenu*/}
<ScrollView ref={ScrollSpyRef} horizontal={true} showsHorizontalScrollIndicator={false} style={styles.scrollSpyMenu}>

  {/*The loop through ProductCategoriesData to create the Buttons foreach category */}
  {ProductCategoriesData.length > 0 &&
    ProductCategoriesData.map((ContainerCategory, index) => (
 <View key={index}>
{/*The Category Buttons each point to View in the next section */}
<Pressable
ref={(ref) => {ButtonsRefs.current[ContainerCategory] = ref}} 
onPress={() => jumpToCategory(`${ContainerCategory}q`)}
  style={styles.buttonsScrollSpy}>
  <Text ref={(ref) => {TextButtonsMenuRef.current[ContainerCategory] = ref}} style={styles.textButtonsScrollSpy}>{ContainerCategory}</Text>
</Pressable>

      </View>
    ))}
<Pressable
  style={styles.buttonsScrollSpy}>
  <Text></Text>
</Pressable>
</ScrollView>

</View>
):null}




{/**filter buttons View */}


<ScrollView horizontal={true} showsHorizontalScrollIndicator={false}  style={styles.filterButtonView}>
  

{showFilterView?(
  <View style={{flex:1, flexDirection:"row"}}>
{!grandpa&&showFilterView?(
<TouchableOpacity style={styles.filterButton}onPress={toggleFilter}>
  <Text style={styles.filterButtonText}>filtrer <Ionicons name="filter" size={18} color="black" /></Text>
</TouchableOpacity>):(<TouchableOpacity style={styles.filterButton}onPress={resetCategorySearch}>
  <Text style={styles.filterButtonText}>reset <MaterialIcons name="replay" size={18} color="black" /></Text>
</TouchableOpacity>)
}

{grandpa&&showFilterView?(
  <TouchableOpacity style={styles.filterButton}onPress={toggleFilter}>
  <Text style={styles.filterButtonText}>{grandpa}</Text>
  <TouchableOpacity style={styles.filterButtonClose} onPress={()=>{setgrandpa('');setfather('');setson('');setgrandSon('');setcontextTrigger(!contextTrigger)}}>
    <Text><AntDesign name="close" size={16} color="black" /></Text></TouchableOpacity>
  </TouchableOpacity>


):null

}


{grandpa&&father&&showFilterView?(
  <TouchableOpacity style={styles.filterButton}onPress={toggleFilter}>
  <Text style={styles.filterButtonText}>{father}</Text>
  <TouchableOpacity style={styles.filterButtonClose} onPress={()=>{setfather('');setson('');setgrandSon('');setcontextTrigger(!contextTrigger)}}>
    <Text><AntDesign name="close" size={16} color="black" /></Text></TouchableOpacity>

</TouchableOpacity>

):null

}
  

{grandpa&&father&&son&&showFilterView?(
  <TouchableOpacity style={styles.filterButton}onPress={toggleFilter}>
  <Text style={styles.filterButtonText}>{son}</Text>
  <TouchableOpacity style={styles.filterButtonClose} onPress={()=>{setson('');setgrandSon('');setcontextTrigger(!contextTrigger)}}>
    <Text><AntDesign name="close" size={16} color="black" /></Text></TouchableOpacity>

</TouchableOpacity>

):null

}
{grandpa&&father&&son&&grandSon&&showFilterView?(
  <TouchableOpacity style={styles.filterButton}onPress={toggleFilter}>
  <Text style={styles.filterButtonText}>{grandSon}</Text>
  <TouchableOpacity style={styles.filterButtonClose} onPress={()=>{setgrandSon('');setcontextTrigger(!contextTrigger)}}>
    <Text><AntDesign name="close" size={16} color="black" /></Text></TouchableOpacity>

</TouchableOpacity>

):null

}
</View>):null}
  </ScrollView>
  
{/*the View that contains product for each category */}

{/* The loop to render container categories */}
{ProductCategoriesData.length > 0 &&
  ProductCategoriesData.map((ContainerCategory, CategorieIndex) => {
 return (
      <View ref={(ref) => { ViewRefs.current[`${ContainerCategory}q`] = ref; }} style={styles.productViewConatiner} key={CategorieIndex}>
  
        {/* The head of the product container */}
        <View style={styles.productViewContainerHead}>
        <Category name={ContainerCategory} />
    
        </View>
      
        {/* The body of the product container */}
        {visibleData.length > 0 && (() => {
          
          let startIndex={};
          let endIndex={};
         let slicedData={};

        if(rerenderTrigger.ContainerCategory === ContainerCategory){
           startIndex[ContainerCategory] = rerenderTrigger.startIndexTrigger;
           endIndex[ContainerCategory] = rerenderTrigger.endIndexTrigger;
         }
         else {
 startIndex[ContainerCategory] = 0;
            endIndex[ContainerCategory] = 10;
  }

        const loadMoreData=(containerCategory, length)=>{


         
          if (containerCategory === ContainerCategory && 
            containerCategory===currentScrolledCategory &&
             currentScrolledCategory !== ""
            ) {
              if(endIndex[ContainerCategory]<length-10){
            endIndex[ContainerCategory] += 10;
          
              }
              else {
                endIndex[ContainerCategory]=  endIndex[ContainerCategory]+(length-endIndex[ContainerCategory]);
             
              }
            
          const newTrigger = {
            ...rerenderTrigger,
            ContainerCategory: ContainerCategory,
            startIndexTrigger: startIndex[ContainerCategory],
            endIndexTrigger: endIndex[ContainerCategory],
            arrayLength: length,
          };
        
          setRerenderTrigger(newTrigger);
        } 
      
        }
      

let filteredData;


if(!grandpa&&!father){
  filteredData = visibleData.filter(item => item.grandpa === ContainerCategory);
 }
if(grandpa&&!father){
   filteredData = visibleData.filter(item => item.father === ContainerCategory);
  }
  if(grandpa&&father&&!son){
    filteredData = visibleData.filter(item => item.son === ContainerCategory);
   }

   if(grandpa&&father&&son){
    filteredData = visibleData.filter(item => item.grandson === ContainerCategory);
   }


      slicedData[ContainerCategory] = filteredData.slice(startIndex[ContainerCategory] , endIndex[ContainerCategory] );

 const isIPad =Platform.OS === 'web' && screenWidth > 500;

     // Define the threshold values based on isIPad
  const threshold = isIPad ? 0.4 : 0.7;
     
      const getItemLayout = (data, index) => ({
        length: 190, // Specify the width of each item
        offset:190 * index, // Calculate the offset based on the index and item width
        index, // Pass the index to getItemLayout
      });
      const getItem = (data, index) => {
        return data[index];
      };
      const getItemCount = (data) => {
        return data.length;
      };
    
      
          return (
           <>

           <View style={styles.productViewContainerHeadNumbersView}>
     
           <Text style={styles.productViewContainerHeadNumbers}>
            {endIndex[ContainerCategory]<filteredData.length?endIndex[ContainerCategory]:filteredData.length} de {filteredData.length}
            </Text> 
           </View>

{VirtualzedShown&&SplashScreenShowEnded?(    <VirtualizedList style={styles.flatList}  ref={(ref) => {FlatLists.current[ContainerCategory] = ref; }}
data={slicedData[ContainerCategory]}
renderItem={({ item, index }) => renderListItem({ item, index, startIndex: startIndex[ContainerCategory], ContainerCategory: ContainerCategory })}
getItem={getItem}
getItemCount={getItemCount}
keyExtractor={(product, index) => index.toString()}
horizontal={true}
scrollEnabled={true}
onEndReachedThreshold={0.1}
getItemLayout={getItemLayout}
onEndReached={loadMoreData.bind(null, ContainerCategory, filteredData.length)}
onScroll={handleListScrollEndDrag(ContainerCategory)}
onScrollEventThrottle={16} 
/>):null


}
      
         </>
          );
        })()}
  
      </View>
    );
  })
}



<SocialMediaContainer/> 
<TouchableOpacity style={styles.footerButtons} onPress={() => {triggerHapticFeedback(); navigation.navigate('loginSeller', {propName:"seller"});}}  >
  <Text style={styles.footerButtonsText}>login paretnaire</Text></TouchableOpacity>

  <TouchableOpacity style={styles.footerButtons} onPress={() => {triggerHapticFeedback(); showAndHideTermAndCondition();}}  >
  <Text style={styles.footerButtonsText}>condition d'utilisation</Text></TouchableOpacity>
  <TouchableOpacity style={styles.footerButtons} onPress={() => {triggerHapticFeedback();}}  >
  <Text style={styles.footerButtonsText}>contact</Text></TouchableOpacity>
 


<View style={styles.Footer}>
</View>


    </ScrollView>





































 <Cart/>


{/*Setting loading view when page is loaded from navigation */}
 {Loading?
 <LoadingView/>
 :null}


    </SafeAreaView>


  );
};




    




const styles = StyleSheet.create({
  //for native
  androidSafeArea: {
  
     paddingTop: Platform.OS === 'android' ? 25 : 0,
     backgroundColor:"#00BFFF",
    flex:1,
   position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
},


root:{

  zIndex:-2,
  backgroundColor:"#00BFFF",

  width:"100%",



},
//only for web
  WebSafeArea: {
  width:"100%", 
     paddingTop: Platform.OS === 'android' ? 25 : 0,
     backgroundColor:"#00BFFF",
    flex:1,

},

headSectionFixedForWeb:{
width:"100%", 
height:"auto",
maxHeight:300,

   backgroundColor:"#00BFFF",
   

},


stickyTop:{
  position:"sticky", top:0, backgroundColor:"#00BFFF"
},


















scrollSpyMenu :{
  zIndex:-1,


maxHeight:46,
padding:2,
width:"100%",

backgroundColor:"white",

borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
backgroundColor:"white",
marginTop:20,



},
buttonsScrollSpy:{
  height:40,
  width:"auto",
  minWidth:100,
  backgroundColor:"white",
  padding:10,



},





buttonsScrollSpyBlack:{

  height:40,
  width:"auto",
  minWidth:100,
  backgroundColor:"black",
  padding:10,

},
textButtonsScrollSpy: {

  fontWeight:"bold",
  textAlign:"center",
},
productViewConatiner:{
  width: Platform.OS === 'web' ? '100vw' : '100%',

  
 marginBottom:15,
  height:350,
borderTopWidth:1,
borderBottomWidth:1,
  borderColor:"rgba(0, 0, 0, 0.4)",
  backgroundColor:"white", 
},

productViewContainerHead:{

paddingVertical:10,

  backgroundColor:"white",
  justifyContent: 'center',
  alignItems: 'center',

},
productViewContainerHeadNumbersView:{
justifyContent:"center",
},
productViewContainerHeadNumbers:{
fontSize:10, fontWeight:"bold", marginLeft:"auto", marginRight:10,marginTop:-38,  color:"grey"

},

productViewConatinerBody:{

},
productView:{
  height:290,
  width:160,
  backgroundColor:"#C8C8C8",

  
  
  overflow:"hidden",


  
  },

 
  wrapperProductViewPressable:{

    height:250,
    width:160,
    overflow:"hidden"},
wrapperProductView:{
  

marginRight:15,
marginLeft:15,

  width:160,
  height:290,
  backgroundColor:"white",
   shadowColor: 'rgba(0, 0, 0, 0.8)',
shadowOffset: { width: 2, height: 4 },
shadowOpacity: 0.7,
shadowRadius: 4,
elevation: 5,},

wrapperProductViewEmpty:{
  
justifyContent:"center", alignItems:"center",
marginLeft:10,

  width:160,
  height:290,
  backgroundColor:"white",
   shadowColor: 'rgba(0, 0, 0, 0.8)',
shadowOffset: { width: 2, height: 4 },
shadowOpacity: 0.7,
shadowRadius: 4,
elevation: 5,},






productImage:{
height:160,
width:160,
},


productScoreView:{
  flex:1,
  flexDirection:"row",

  maxHeight:20,
  minHeight:20,
backgroundColor:"white",
paddingLeft:10,



},
productnameView:{

  backgroundColor:"white",
  height:"auto",

height:25,
paddingTop:3,

},
productDescriptionView:{
  backgroundColor:"white",
  height:47,
  marginBottom:3,

paddingHorizontal:10,
},
productDescritption:{
  color:"#333333",fontSize:14,
},
productName:{
fontWeight:"bold",
marginLeft:10,
color:"#333333",fontSize:15,

},

productPriceButton:{
  height:40,
backgroundColor:"#a7bf60",

width:"100%",


justifyContent: 'center',
alignItems: 'center',



},

outOfStockButton:{
  height:40,
backgroundColor:"#DA291C",

width:"100%",


justifyContent: 'center',
alignItems: 'center',
},

outOfStockButtonText:{color:"white", fontWeight:"bold"},


productPriceText:{
fontWeight:"bold",
marginTop:-3,

},
Footer:{
  minHeight:500,
  backgroundColor:"#00BFFF",
},





  wrapperProductCartView:{
    backgroundColor:"white",
    width:"90%",
    marginLeft:"5%",
    marginRight:"5%",
marginTop:20,

shadowColor: 'rgba(0, 0, 0, 0.7)',
shadowOffset: { width: 3, height: 4 },
shadowOpacity: 0.7,
shadowRadius: 10,
elevation: 5,
  },



sellerNameAndLink:{
 flexDirection:"row",

  alignItems:"space-between",
height:40,
width:"100%",
  backgroundColor:"rgba(0,0,0,0.3)",
marginTop:-270


},
sellerImageProductView:{
  height:60,
  width:60,
  borderRadius:60,
  marginLeft:5,
  marginTop:Platform.OS==="web"?-10:0,
  marginBottom:Platform.OS==="web"?0:-10,
  borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.3)"
},
sellerNameProductViewButton:{
  flexWrap:"wrap",
  alignItems:"flex-start",
  overflow:"hiddden",



},
sellerNameProductView:{
height:"100%", justifyContent:"center",alignItems:"center",  backgroundColor:"transparent",width:95,
},


sellerNameProductViewText:{
  fontSize:12,
  color:"white",
  fontWeight:"bold",
  margin:5,

},

textProductNameCart:{
  fontSize:17,
  fontWeight:"bold",

},
textProductQuantityCart:{
  marginTop:5,
  fontWeight:"bold",
fontSize:15,

},
textProductPriceCart:{
  marginTop:10,
  fontWeight:"bold",
  fontSize:15,

},



splashScreenWrapper:{flex:1,width:"100%", height:Platform.OS==="web"?"100vh":"120%",
 position:Platform.OS==="web"?"fixed":"absolute",
top:0, left:0, zIndex: 99,
backgroundColor:"#00BFFF"},

SuccessView:{
  position:Platform.OS==="web"?"fixed":"absolute", 
   top:0,left:0,height:"auto",
   width:"100%", backgroundColor:"#5cb85c",
    alignItems:"center",paddingBottom:5,
    paddingTop:Platform.OS==="web"?20:30,zIndex:101,
  },
  




logoAndButtonContainerMenuLogin:{
  flex: 1,
  flexWrap: 'wrap',


width:"100%",
justifyContent:"space-between"

},







logoImage:{
height:60, width:60,marginVertical:10,marginLeft:5,

},


logoAndButtonContainer:{
flex:1,
flexDirection:"row",

backgroundColor:"#00BFFF",
height:"auto",
maxHeight:70,

width:"100%",
justifyContent:"space-between"

},


    loginButton :{

     
      justifyContent: 'center',
      alignItems: 'center',
  


  height:70,
  width:70,





  
    },


  notificationButton:{  
    flex:1, flexDirection:"row",
  
    width:70,
    maxWidth:70,
   height:70,
  
   justifyContent:"center",
      alignItems: 'center',
      
     
  
    },

  headText2View:{

    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height:50,
minHeight:50,
    backgroundColor:"#00BFFF",
zIndex:-1,
  
  },
  headTextText1:{

    fontWeight:"bold",
    fontSize:16,
    fontFamily:Platform.OS!=="android"?"Chalkduster":"Chalkduster"
  },


headTextText2:{

  fontWeight:"bold",
  fontSize:12,
  alignSelf:"center",
  fontFamily:Platform.OS!=="android"?"Chalkduster":"Roboto"
},

 wrapperCustom: {

    padding: 6,
  },




 
  flatList:{
  
  },

  //loading and errors
  LoadingView:{
    position:"absolute", top:0,left:0,height:"115%",width:"100%", 
    backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
  },

  footerButtons:{paddingHorizontal:20, paddingVertical:10},

  footerButtonsText:{
fontWeight:"bold", 
  },
  productSale:{

    backgroundColor:"rgba(218, 41, 28, 0.7)",width:80, height:30,marginLeft:10,marginTop:50,justifyContent:"center", alignItems:"center",
  },
  productSaleText:{fontWeight:"bold", color:"white"},
  productPriceTextSaleOldPrice:{color:"red",fontWeight:"bold",marginTop:-5,  textDecorationLine: "line-through",fontSize:10},


  //notification section 

  notificationButtonTextView:{
   marginLeft:-10,marginTop:15,backgroundColor:"rgba(255, 87, 34,0.7)", width:20, height:20,borderRadius:20, justifyContent:"center", alignItems:"center",
  },

  notificationButtonText:{
    fontSize:14, fontWeight:"bold",color:"white"
  },



   //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",},

//filter View
filterView:{

height:"100%", width:"100%", maxWidth:500,backgroundColor:"#00BFFF",

}
,


filterViewHead:{flex:1, flexDirection:"row",backgroundColor:"#00BFFF", width:"100%",
paddingHorizontal:30,
maxHeight:60,height:60, minWidth:"100%", borderBottomWidth:1,borderColor:"#333333",
justifyContent:"space-between"},
FilterViewButtonsView:{borderBottomWidth:8, borderColor:"rgba(0, 0, 0, 0.05)"},
filterViewHeadButtons:{height:40, marginVertical:10, justifyContent:"center", alignItems:"center"},
filterViewHeadButtonsText:{fontWeight:"bold",color:"white"},

buttonsFilterView:{

  height:50,
  width:"auto",
  minWidth:100,
  backgroundColor:"white",
  padding:10,
  justifyContent:"center",
alignItems:"flex-start",paddingLeft:30
},


filterButtonView:{ width:"100%", height:"auto",minHeight:60,maxHeight:60,backgroundColor:"#00BFFF",
paddingVertical:10,flex:1, flexDirection:"row",zIndex:-1,},
filterButton:{flex:1,
   flexDirection:"row", height:40, width:"auto", minWidth:100,paddingLeft:10, paddingRight:5, 
 marginLeft:10, justifyContent:"center", alignItems:"center", backgroundColor:"white", borderRadius:20,borderWidth:1,borderColor:"rgba(0, 0, 0, 0.4)",},
 filterButtonClose:{width:30, height:30,borderRadius:30,justifyContent:"center",
  alignItems:"center",backgroundColor:"#DCDCDC", marginLeft:10,borderWidth:1,borderColor:"rgba(0, 0, 0, 0.2)"},
filterButtonText:{fontWeight:"bold"},

filterViewBodyFlex:{ flex:1, flexDirection:"column", justifyContent:"space-between",backgroundColor:"white"},
filterViewBodyFlexLoading:{flex:1, flexDirection:"column", justifyContent:"center",alignItem:"center", backgroundColor:"white"},
buttonSeeResults:{height:60, width:"100%", justifyContent:"center", alignItems:"center", 
backgroundColor:"#00BFFF", maxWidth:500, borderTopWidth:1,borderColor:"#333333"
},
buttonSeeResultsText:{fontWeight:"bold", color:"#333333", fontSize:16},




//pathview
pathView:{
width:"100%",marginBottom:10,


},




pathViewButtonText:{
color:"grey",fontWeight:"bold",paddingHorizontal:"5%",lineHeight:30,
},


});
export default HomeScreen;