import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { Modal,ActivityIndicator,StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView, Vibration,Platform, TouchableOpacity,  Image
} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Fontisto } from '@expo/vector-icons';
import { Keyboard } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMyContext } from '../MyContext';
import * as ImagePicker from 'expo-image-picker';
import categorisingData from './categorisingData.json'; // Adjust the path to your file

import { Picker } from '@react-native-picker/picker';
import * as Haptics from 'expo-haptics';
import axios from 'axios';
import Typewriter from "../typeWriter";
import TermAndConditions from "../termAndConditions";
import { set } from 'react-native-reanimated';
const CheckBox = ({ value1, value,readTermAndConditions, checked, onSelect }) => (
  <TouchableOpacity onPress={onSelect} style={{ flexDirection: 'row', alignItems: 'center' }}>
    <View style={{ height: 24, width: 24, borderRadius: 4, borderWidth: 3, borderColor: checked ? '#5cb85c' : 'black', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', marginLeft: '5%', marginTop: 20 }}>
      {checked ? <View style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: '#5cb85c' }} /> : null}
    </View>
    <Text style={{ marginLeft: '5%', marginTop: 20 }}>{!readTermAndConditions ? (value1) : (checked ? 'lus et acceptés' : value)
}</Text>
  </TouchableOpacity>
);


const RadioButton = ({ value, selected, onSelect }) => (
  <TouchableOpacity onPress={onSelect} style={{height:"auto", flexDirection: 'row', justifyContent:"center",alignItems: 'center', maxWidth: "80%",}}>
    <View style={{ height: 28, width: 27, borderRadius: 15, borderWidth: 3, borderColor: selected ? '#5cb85c' : 'black', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', marginBottom: -10 }}>
      {selected ? <View style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: '#5cb85c' }} /> : null}
    </View>

    <View style={{ flex: 1, marginLeft: "5%", marginTop:10, }}>
      <Text style={{ fontSize:15,}}>{value}</Text>
    </View>
  </TouchableOpacity>
);



const AddProductSeller= ({ navigation, route }) => {


//to handle radio buttons choice
  const [showTypewriterRadioButtons,setshowTypewriterRadioButtons]=useState(false);
  const [textInfo, settextInfo]=useState("");
//radio butoon
const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioSelectTextFloomy= (value, showInfo) =>{
    triggerHapticFeedback();
    if(selectedValue!==value){
    
      setSelectedValue(value);
    }
        setshowTypewriterRadioButtons(false);
        setTimeout(() => {
 
    
          switch (value){
     
     
          case 'seller pay':
        
            if(showInfo){
              settextInfo("En acceptant les retours dans les 14 jours et en prenant en charge les frais en tant que vendeur, vous rendez les achats plus attirants pour les clients. Ils auront la tranquillité d'esprit de pouvoir retourner des articles sans frais supplémentaires, ce qui peut les encourager à acheter chez vous. Cependant, il est important de noter que cette politique peut entraîner des coûts supplémentaires pour vous en tant que vendeur");
          }
            break;
            case 'buyer pay':
        
            if(showInfo){
settextInfo("Si vous laissez les clients payer les frais de retour tout en acceptant les retours dans les 14 jours, vous pouvez proposer des prix d'achat plus bas pour attirer les acheteurs soucieux d'économiser. Cependant, gardez à l'esprit que certains clients pourraient être rebutés par ces frais supplémentaires, ce qui pourrait les faire hésiter à acheter");
          }
            break;
            case 'not accepted':
        
            if(showInfo){
              settextInfo("Si vous décidez de ne pas accepter les retours du tout, cela peut réduire vos coûts en tant que vendeur, ce qui peut se traduire par des prix plus bas pour les produits. Cependant, gardez à l'esprit que cela peut décourager les clients qui préfèrent avoir la flexibilité de retourner des articles en cas de problème");
          }
            break;

    
            default:
    
    
        }
    
    
        if(showInfo){
        setshowTypewriterRadioButtons(true);
        }
    
        }, 300);
    
    
      }
      const handleRadioSelect = (value) => {
        setSelectedValue(value);
        setshowTypewriterRadioButtons(false);
        settextInfo('');
  
    
        
      };




















//hide and show modal for term and conditions
const[showTermAndConditions, setshowTermAndConditions]=useState(false);


const showAndHideTermAndCondition = () => {
  triggerHapticFeedback();
  setshowTermAndConditions(!showTermAndConditions);
};





   //cehckbox
   const [checked, setChecked] = useState(false);
   const [readTermAndConditions,setreadTermAndConditions]=useState(false);


   //show term and condtions first, than set checked and if clicked again rerstart the hole process
   const handleCheck = () => {

    if(!checked){
    if(readTermAndConditions){
    triggerHapticFeedback();
    setChecked(!checked);
  }
  else {
    setshowTermAndConditions(true);
    setreadTermAndConditions(true);

  }

    }
    else{
      setChecked(!checked);
      setreadTermAndConditions(false);

    }


  };




  const closeTermAndConditions=()=>{
    triggerHapticFeedback();
        setshowTermAndConditions(false);
    
      }
    
      const  acceptAndcloseTermAndConditions=()=>{
        triggerHapticFeedback();
            setshowTermAndConditions(false);
            setChecked(true);
          }
        

    //trigger haptic feedback
    const triggerHapticFeedback=()=>{
      if(Platform.OS!=="web"){
      Haptics.notificationAsync(
        Haptics.NotificationFeedbackType.Success);}
    }
    const {SellerProp} = route.params;
const {contextTrigger, setcontextTrigger}= useMyContext();
//const while loading
const [loading, setLoading] = useState(false);
//functions to check Letters and numbers 

// check only letters and numbers in the input
const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïüœ\s#.,:;*?!-_()/–•—ÉéÀàÇçÈèÔôÛûÜüŒœ‑]*$/.test(str);
};





// check only letters in the input
const onlyLetters = (str) => {
  return /^[A-Za-z'’èéäêöàùâêîôûçëïüœ\s]*$/.test(str);
};

//check only numbers in the comment input
const onlyNumber = (str) => {
    return /^[0-9]+$/.test(str);
  };
//check price 
const onlyNumberPrice = (str) => {
    return /^[0-9,.]+$/.test(str);
  };


//Error holding and displaying vars
const [Error, setError]=useState();
//holdSuccess
const [Success, setSuccess]=useState();

// State variables to hold inputs values
const [productName, setproductName]=useState('');
const [productWeight, setproductWeight]=useState('');
const [productDescription, setproductDescription]=useState('');
const [productComponent, setproductComponent]=useState("pas d'informations supplémentaires.");
const [productNote, setproductNote]=useState("Nous déclinons toute responsabilité pour tout dommage, incident ou préjudice résultant de l'utilisation de notre produit. Les utilisateurs sont priés de suivre les instructions fournies et d'utiliser le produit de manière responsable. Nous recommandons également de consulter un professionnel qualifié pour toute utilisation spécifique ou question concernant notre produit");
const [productQuantity, setproductQuantity]=useState('');
const [productPrice, setproductPrice]=useState('');
// State variables to hold inputs check results if ok true esle false
const [everythingOkproductName, setEverythingOkproductName]=useState(false);
const [everythingOkproductGrandpa, setEverythingOkproductGrandpa]=useState(false);
const [everythingOkproductFather, setEverythingOkproductFather]=useState(false);
const [everythingOkproductSon, setEverythingOkproductSon]=useState(false);
const [everythingOkproductGrandson, setEverythingOkproductGrandson]=useState(false);
const [everythingOkproductWeight, setEverythingOkproductWeight]=useState(false);
const [everythingOkproductDescription, setEverythingOkproductDescription]=useState(false);
const [everythingOkproductComponent, setEverythingOkproductComponent]=useState(false);
const [everythingOkproductNote, setEverythingOkproductNote]=useState(false);
const [everythingOkproductQuantity, setEverythingOkproductQuantity]=useState(false);
const [everythingOkproductPrice, setEverythingOkproductPrice]=useState(false);
const [everythingOkproductImages, setEverythingOkproductImages]=useState(false);
//functions to check each input individually

const showProblem = () => {
  const errorMessages = [
    { condition: !everythingOkproductName, message: "Il y a un problème avec le nom du produit, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductGrandpa || !everythingOkproductFather || !everythingOkproductSon || !everythingOkproductGrandson, message: "Il y a eu un problème avec la catégorie, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductWeight, message: "Il y a un problème avec le poids, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductDescription, message: "Il y a un problème avec la description, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductNote, message: "Il y a un problème avec la note légale, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductComponent, message: "Il y a un problème avec les composants ou les informations supplémentaires, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductPrice, message: "Il y a un problème avec le prix, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductQuantity, message: "Il y a  un problème avec le quantité, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !everythingOkproductImages, message: "Il y a un problème avec les images, veuillez vérifier votre saisie s'il vous plaît" },
    { condition: !checked, message: "Il y a un problème avec les conditions d'utilisation, veuillez accepter les conditions pour pouvoir inserer le produit" },
    { condition: !selectedValue, message: "Il y a un problème avec les options du retour, veuillez choisir une option pour pouvoir inserer le produit" },
  ];

  errorMessages.forEach(({ condition, message }) => {
    if (condition) {
      setError('');
      setError(message);
      // Break the loop if any condition is met
      return;
    }
  });
};





  //handle product name change
  const handleProductNameChange= (product_name) => {
   if (onlyLettersAndNumbers(product_name) && product_name.length>0&& product_name.length<80) {
        setEverythingOkproductName(true);
        setproductName(product_name);
        setError();
    }
    else {

        setEverythingOkproductName(false);
        setproductName(product_name);
        if(product_name.length){
        setError("le nom ne peut contenir que des lettres, min 1, et max 80 \u{1F621}.");
      }
    }
  };

     //handle product weight change
  const handleProductWeightChange= (product_weight) => {
    if (onlyNumber(product_weight)&& product_weight.length>0) {
         setEverythingOkproductWeight(true);
         setproductWeight(product_weight);
         setError();
     } else{
    setEverythingOkproductWeight(false);
    setproductWeight(product_weight);
    if(product_weight.length){
    setError("le poids ne peut contenir que des nombres \u{1F624}");}
}


   };

       //handle product description change
  const handleProductDescriptionChange= (product_Description) => {
    if (onlyLettersAndNumbers(product_Description) && product_Description.length>0&& product_Description.length<2900) {
        setEverythingOkproductDescription(true);
        setproductDescription(product_Description);
        setError();
     }else {
        setEverythingOkproductDescription(false);
        setproductDescription(product_Description);
        if(product_Description.length){
        setError("la déscription ne peut contenir que des lettres, nombres points et vigules et ?!', min 1, et max 2900 \u{1F623}. ");}
     }
   };

       //handle product component change
       const handleProductComponentChange= (product_Component) => {
        if (onlyLettersAndNumbers(product_Component)&& product_Component.length>0&& product_Component.length<600) {
            setEverythingOkproductComponent(true);
            setproductComponent(product_Component);
            setError();
         }else{ setEverythingOkproductComponent(false);
         setproductComponent(product_Component);
         if(product_Component.length){
         setError("les composition ne peut contenir que des lettres, nombres points et vigules et ?!',min 1, et max 600 \u{1F624}. ");}

        }
       };

    //handle product Note change
    const handleProductNoteChange= (product_Note) => {
        if (onlyLettersAndNumbers(product_Note)&& product_Note.length>0&& product_Note.length<600) {
            setEverythingOkproductNote(true);
            setproductNote(product_Note);
            setError();
         }
         else
          {  setEverythingOkproductNote(false);
         setproductNote(product_Note);
         if(product_Note.length){
         setError("la note ne peut contenir que des lettres, nombres points et vigules et ?!', min 1, et max 600 \u{1F625}. ");}

        }

       }
       ;

    //handle product Quantity change
    const handleProductQuantityChange= (product_Quantity) => {
        if (onlyNumber(product_Quantity)&& product_Quantity.length>0) {
            setEverythingOkproductQuantity(true);
            setproductQuantity(product_Quantity);
            setError();
         }else { setEverythingOkproductQuantity(false);
            setproductQuantity(product_Quantity);
            if(product_Quantity.length){
            setError("la quantité ne peut contenir que des nombres entiers, pas de virgules ou points \u{1F626}");}

        }

       };


    //handle product Price change
    const handleProductPriceChange= (product_Price) => {
        if (onlyNumberPrice(product_Price)&& product_Price.length>0) {
            setEverythingOkproductPrice(true);
            setproductPrice(product_Price);
            setError();
         }else {setEverythingOkproductPrice(false);
            setproductPrice(product_Price);
            if(product_Price.length){
            setError("la prix ne peut contenir que des nombres, virgules ou points \u{1F627}");}

        }
       };
       





//the fetch operation to add product if all inputs are ok



const insertProduct = async () => {
  triggerHapticFeedback();
  setLoading(true);

  const formData = new FormData();

  for (let index = 0; index < images.length; index++) {
    const imageUri = images[index];
    if (Platform.OS === 'web') {
      // On web, reading the image file as an ArrayBuffer
      const response = await fetch(imageUri);
      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], { type: 'image/jpeg' });
      formData.append('images[]', blob, `image${index}.jpg`);
    } else {
      // On mobile (Expo), using the image URI directly
      formData.append('images[]', {
        uri: imageUri,
        type: 'image/jpeg',
        name: `image${index}.jpg`,
      });
    }
  }

  // Product details
  formData.append('product_name', productName);
  formData.append('grandpa', selectedPickerValueGrandpa);
  formData.append('father', selectedPickerValueFather);
  formData.append('son', selectedPickerValueSon);
  formData.append('grandson', selectedPickerValueGrandson);

  formData.append('product_weight', productWeight);
  formData.append('product_description', productDescription);
  formData.append('product_component', productComponent);
  formData.append('product_note', productNote);
  formData.append('product_quantity', productQuantity);
  formData.append('product_price', productPrice);
  formData.append('return_policy', selectedValue);

  // Seller details
  formData.append('seller_name', SellerProp[0].name);
  formData.append('seller_sku', SellerProp[0].sku_seller);
  formData.append('seller_tel', SellerProp[0].tel);
  formData.append('seller_email', SellerProp[0].email);
  formData.append('seller_adress', SellerProp[0].seller_adress);
  formData.append('seller_image', SellerProp[0].image);

  try {
    const response = await axios.post(
      'https://tunizoon.com/appgate/addProductSeller.php?action=insertNewProduct',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const responseData = response.data;


      // Handling successful response here
      if (responseData.error) {
        setLoading(false);
        setError('Product not added successfully error: ' + responseData.error);
      } else {
             // Handling successful response here
             setLoading(false);
setcontextTrigger(true);
setSuccess("Le produit vient d'être correctement ajouté, rabbi yjib el 9asm w dima men ghir ma nwassik rodd belek mil qualité wil nthafa. Rahou client far7an yjib 3 akhow em client met8achech yharreb 10. enzel 3la bouton Home bech tarja3 lil home page mte3ek tnajem tchouf les produits mte3ek lkoll \u{1F9D0}");
}

  } catch (error) {
    setLoading(false);
    setcontextTrigger(true);
    setError(error);
    //navigation.navigate('sellerHomePage');
  }
};





    //picker section 

//varibale to hold the object values for picker


const [grandpaObject, setgrandpaObject] = useState(["choisir une catégorie", "aucune"]);

const [fatherObject,setfatherObject]=useState(["choisir une catégorie", "aucune"]);
const [sonObject,setsonObject]=useState(["choisir une catégorie", "aucune"]);
const [grandsonObject,setgrandsonObject]=useState(["choisir une catégorie", "aucune"]);




//variable to hide hold and show the values of picker
const [ selectedPickerValueGrandpa , setselectedPickerValueGrandpa] = useState('choisir une catégorie');

const [selectedPickerValueFather, setselectedPickerValueFather] = useState('choisir une sous-catégorie');
const [selectedPickerValueSon, setselectedPickerValueSon] = useState('choisir un groupe');
const [selectedPickerValueGrandson, setselectedPickerValueGrandson] = useState('choisir un sous-groupe');
const [pickerOneDisplayed, setpickerOneDisplayed]=useState(false);
const [pickerTwoDisplayed, setpickerTwoDisplayed]=useState(false);
const [pickerThreeDisplayed, setpickerThreeDisplayed]=useState(false);
const [pickerFourDisplayed, setpickerFourDisplayed]=useState(false);
//trigger 
const [trigger,settrigger]=useState(false);
//function to show picker
const showPickerOne=()=>{
setpickerOneDisplayed(!pickerOneDisplayed);
}
const showPickerTwo=()=>{
  setpickerTwoDisplayed(!pickerTwoDisplayed);
  }
  const showPickerThree=()=>{
    setpickerThreeDisplayed(!pickerThreeDisplayed);
    }
    const showPickerFour=()=>{
      setpickerFourDisplayed(!pickerFourDisplayed);
      }






//funciton to set and hide picker
const setSelectedPickerValueAndPickerVidsibility=(itemIndex,itemValue, wichOne)=>{
  if(Platform.OS==="web"&&itemIndex===0){
    setError("Il faut choisir une catégorie, la première option est juste là pour vous guider, 3lech hakka brabbi \u{1F602}?");
  }
  if(Platform.OS==="web"&&itemIndex>0){



switch(wichOne){
case "grandpa":

setselectedPickerValueGrandpa(itemValue);
setselectedPickerValueFather("choisir une sous-catégorie");
setselectedPickerValueSon("choisir un groupe");
setselectedPickerValueGrandson("choisir un sous-groupe");

setpickerOneDisplayed(!pickerOneDisplayed);
if(selectedPickerValueGrandpa&&selectedPickerValueGrandpa!=="choisir une sous-catégorie"){setEverythingOkproductGrandpa(true);} else {setEverythingOkproductGrandpa(false);}  

settrigger(!trigger);
break;
case "father":


setselectedPickerValueFather(itemValue);
setselectedPickerValueSon("choisir un groupe");
setselectedPickerValueGrandson("choisir un sous-groupe");
setpickerTwoDisplayed(!pickerTwoDisplayed);
if(selectedPickerValueFather){setEverythingOkproductFather(true);} else {setEverythingOkproductFather(false);}  

settrigger(!trigger);

break;
case "son":

setselectedPickerValueSon(itemValue);
setselectedPickerValueGrandson("choisir un sous-groupe");
setpickerThreeDisplayed(!pickerThreeDisplayed);
if(selectedPickerValueSon){setEverythingOkproductSon(true);} else {setEverythingOkproductSon(false);}  

settrigger(!trigger);
break;
case "grandson":

setselectedPickerValueGrandson(itemValue);
setpickerFourDisplayed(!pickerFourDisplayed);
if(selectedPickerValueGrandson){setEverythingOkproductGrandson(true);} else {setEverythingOkproductGrandson(false);}  

settrigger(!trigger);
break;

}


}
//if native 
else
{




  switch(wichOne){
    case "grandpa":
    
    setselectedPickerValueGrandpa(itemValue);
    setselectedPickerValueFather("choisir une sous-catégorie");
    setselectedPickerValueSon("choisir un groupe");
    setselectedPickerValueGrandson("choisir un sous-groupe");
    
    setpickerOneDisplayed(!pickerOneDisplayed);
    if(selectedPickerValueGrandpa&&selectedPickerValueGrandpa!=="choisir une sous-catégorie"){setEverythingOkproductGrandpa(true);} else {setEverythingOkproductGrandpa(false);}  
    
    settrigger(!trigger);
    break;
    case "father":
    
    
    setselectedPickerValueFather(itemValue);
    setselectedPickerValueSon("choisir un groupe");
    setselectedPickerValueGrandson("choisir un sous-groupe");
    setpickerTwoDisplayed(!pickerTwoDisplayed);
    if(selectedPickerValueFather){setEverythingOkproductFather(true);} else {setEverythingOkproductFather(false);}  
    
    settrigger(!trigger);
    
    break;
    case "son":
    
    setselectedPickerValueSon(itemValue);
    setselectedPickerValueGrandson("choisir un sous-groupe");
    setpickerThreeDisplayed(!pickerThreeDisplayed);
    if(selectedPickerValueSon){setEverythingOkproductSon(true);} else {setEverythingOkproductSon(false);}  
    
    settrigger(!trigger);
    break;
    case "grandson":
    
    setselectedPickerValueGrandson(itemValue);
    setpickerFourDisplayed(!pickerFourDisplayed);
    if(selectedPickerValueGrandson){setEverythingOkproductGrandson(true);} else {setEverythingOkproductGrandson(false);}  
    
    settrigger(!trigger);
    break;
    
    }

}









settrigger(!trigger);
}

//useEffect to set and reset
useEffect(() => {

  let firstStep="first step";

setgrandpaObject(categorisingData[firstStep]);
setfatherObject(categorisingData[selectedPickerValueGrandpa]);
setsonObject(categorisingData[selectedPickerValueFather]);
setgrandsonObject(categorisingData[selectedPickerValueSon]);



}, [trigger]);
//effect to make the inittial check for default values
useEffect(() => {
handleProductComponentChange(productComponent);
handleProductNoteChange(productNote);

}, []);


//images uploading section 

//array to hold uploaded images
    const [images, setImages] = useState([]);
//function to pick images 
const pickImage = async () => {
    const maxNumberOfImages = 6 - images.length; // Maximum number of images allowed to upload
  
    // No permissions request is necessary for launching the image library while using expo
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
      allowsMultipleSelection: true,
      maxNumberOfFiles: maxNumberOfImages, // Set the maximum number of files to be selected
    });
  
    if (!result.canceled) {
      const newImages = result.assets.map((asset) => asset.uri);
  
      // Check if the total number of images is more than 5
      if (images.length + newImages.length > 6) {
        setError("Vous pouvez ajouter seulement 5 images"); // Set the error message
        setEverythingOkproductImages(false); // Set the everythingOkproductImages to false
      } else {
        setError(null); // Reset the error if there was any
        setImages((prevImages) => [...prevImages, ...newImages.slice(0, maxNumberOfImages)]);
  
        // Check if the length of the newImages array is between 1 and 4
        if (newImages.length >= 1 && newImages.length <6) {
          setEverythingOkproductImages(true);
        } else {
          setEverythingOkproductImages(false);
        }
      }
    }
  };
  
  
//removing images one by one
    const removeImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
        if(images.length>1){
            setEverythingOkproductImages(true);

        }
        else{

            setEverythingOkproductImages(false);
        }
      };





  return (
 
<KeyboardAvoidingView     behavior={Platform.OS === 'ios' ? 'padding' : null}
        style={StyleAddProduct.keyboardAvoidingContainer}>




<SafeAreaView style={StyleAddProduct.androidSafeArea}>



<Modal
          animationType={'slide'}
          transparent={true}
          visible={showTermAndConditions}
          onRequestClose={() => {
         
          }}
>

<TermAndConditions  closeTermAndConditions={closeTermAndConditions}  acceptAndcloseTermAndConditions={acceptAndcloseTermAndConditions}/>
</Modal>













{/*the container for the logo and the button to show the login menu*/}
<View style={StyleAddProduct.HeadContainer}>

<Pressable style={StyleAddProduct.BackButton} onPress={() => { {triggerHapticFeedback();navigation.navigate('sellerHomePage'); }}}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>



</View>
<ScrollView vertical={true} showsHorizontalScrollIndicator={false}  style={StyleAddProduct.Container} >
      <View style={StyleAddProduct.HeadTextView}>
  
  <Text style={StyleAddProduct.HeadText}>Ajouter un nouveau produit</Text>
  </View>

     




      <Text style={StyleAddProduct.Label}>Le nom du produit *</Text>
  
  
   <TextInput
 style={productName.length===0 ? StyleAddProduct.inputText: everythingOkproductName? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
 placeholder="Ajouter le nom du produit ici"
onChangeText={handleProductNameChange}
value={productName}
 />

<View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductName?StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productName.length} / 80</Text>
 </View>





<Text style={StyleAddProduct.Label}>La cat&#233;gorie du produit *</Text>



{/**Picker system for categories */}

{Platform.OS!=="web"?(
  <View>
<View>
<View
style={selectedPickerValueGrandpa== "choisir une catégorie"? StyleAddProduct.PickerContainer: selectedPickerValueGrandpa!== "choisir une catégorie" && everythingOkproductGrandpa? StyleAddProduct.PickerContainerSucess:StyleAddProduct.PickerContainerFail}
 >
<Pressable style={StyleAddProduct.SelectCategoryButton} onPress={showPickerOne}>
 <Text>{selectedPickerValueGrandpa}</Text></Pressable> 


 </View>
{pickerOneDisplayed?(
 <Picker
        selectedValue={selectedPickerValueGrandpa}
        onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex,itemValue,"grandpa")}
     
                  style={StyleAddProduct.pickerItem}
      >

            {grandpaObject.map((value, index) => (
            index>0?(
 <Picker.Item key={index} label={value} value={value} />):null
      ))}


    
 </Picker>):null}



 </View>




{selectedPickerValueGrandpa!=="choisir une catégorie"&&fatherObject!==undefined?(
<View>

 <View
style={selectedPickerValueFather=== "choisir une sous-catégorie"? StyleAddProduct.PickerContainer: selectedPickerValueFather!== "choisir une sous-catégorie" && everythingOkproductFather? StyleAddProduct.PickerContainerSucess:StyleAddProduct.PickerContainerFail}
 >
<Pressable style={StyleAddProduct.SelectCategoryButton} onPress={showPickerTwo}>
 <Text>{Object.values(fatherObject).slice(1).includes(selectedPickerValueFather)?selectedPickerValueFather:fatherObject[0]}</Text></Pressable> 


 </View>

 {!pickerTwoDisplayed?(null):(
 <Picker
        selectedValue={selectedPickerValueFather}
        onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex, itemValue,"father")}
      
                  style={StyleAddProduct.pickerItem}
      >
         {fatherObject.map((value, index) => (
               index>0?(
        <Picker.Item key={index} label={value} value={value} />):null
      ))}

 </Picker>)}
 </View>

):null}


{selectedPickerValueGrandpa!=="choisir une catégorie"&&selectedPickerValueFather!=="choisir une sous-catégorie"&&sonObject!==undefined?(
<View>


<View
style={selectedPickerValueSon=== "choisir un groupe"? StyleAddProduct.PickerContainer: selectedPickerValueSon!== "choisir un groupe" && everythingOkproductSon? StyleAddProduct.PickerContainerSucess:StyleAddProduct.PickerContainerFail}
>
<Pressable style={StyleAddProduct.SelectCategoryButton} onPress={showPickerThree}>
<Text>{Object.values(sonObject).slice(1).includes(selectedPickerValueSon)?selectedPickerValueSon:sonObject[0]}</Text></Pressable> 


</View>

{!pickerThreeDisplayed?(null):(
<Picker
       selectedValue={selectedPickerValueSon}
       onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex, itemValue,"son")}
     
                 style={StyleAddProduct.pickerItem}
     >
      {sonObject.map((value, index) => (
        index>0?(
        <Picker.Item key={index} label={value} value={value} />):null
      ))}

</Picker>)}

</View>
):null}


{selectedPickerValueGrandpa!=="choisir une catégorie"&&selectedPickerValueFather!=="choisir une sous-catégorie"&&selectedPickerValueSon!=="choisir un groupe"&&grandsonObject!==undefined?(
<View>


<View
style={selectedPickerValueGrandson=== "choisir un sous-groupe"? StyleAddProduct.PickerContainer: selectedPickerValueGrandson!== "choisir un sous-groupe" && everythingOkproductGrandson? StyleAddProduct.PickerContainerSucess:StyleAddProduct.PickerContainerFail}
>
<Pressable style={StyleAddProduct.SelectCategoryButton} onPress={showPickerFour}>
<Text>{Object.values(grandsonObject).slice(1).includes(selectedPickerValueGrandson)?selectedPickerValueGrandson:grandsonObject[0]}</Text></Pressable> 


</View>

{!pickerFourDisplayed?(null):(
<Picker
       selectedValue={selectedPickerValueGrandson}
       onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex, itemValue,"grandson")}
style={StyleAddProduct.pickerItem}>
      {grandsonObject.map((value, index) => (
        index>0?(
        <Picker.Item key={index} label={value} value={value} />):null
      ))}

</Picker>)}
</View>

):null}


</View>
):null}



{Platform.OS==="web"?(
<View>



<Picker selectedValue={selectedPickerValueGrandpa}
        onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex, itemValue,"grandpa")}
 style={StyleAddProduct.pickerItem}>

            {grandpaObject.map((value, index) => (
 <Picker.Item key={index} label={value} value={value} />
      ))}


    
 </Picker>


 {selectedPickerValueGrandpa!=="choisir une sous-catégorie" &&fatherObject!==undefined?(

 <Picker selectedValue={selectedPickerValueFather}
        onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex, itemValue,"father")}
      
                  style={StyleAddProduct.pickerItem}>
         {fatherObject.map((value, index) => (
        <Picker.Item key={index} label={value} value={value} />
      ))}

 </Picker>

):null}

{selectedPickerValueFather!=="choisir un groupe"&&sonObject!==undefined?(

 <Picker selectedValue={selectedPickerValueSon}
       onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex, itemValue,"son")}
style={StyleAddProduct.pickerItem} >
      {sonObject.map((value, index) => (
        <Picker.Item key={index} label={value} value={value} />
      ))}

</Picker>

):null}
{selectedPickerValueSon!=="choisir un sous-groupe"&&grandsonObject!==undefined?(

<Picker
       selectedValue={selectedPickerValueGrandson}
       onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemIndex, itemValue,"grandson")}
    
                 style={StyleAddProduct.pickerItem}
     >
      {grandsonObject.map((value, index) => (
        <Picker.Item key={index} label={value} value={value} />
      ))}

</Picker>
):null}
  
</View>
)
:null}



















<Text style={StyleAddProduct.Label}>Le poids  pour le calcul des frais de livraisons Aramex 72h (Svp en gramme même pour les liquides) *</Text>
   <TextInput
style={productWeight.length===0 ? StyleAddProduct.inputText: everythingOkproductWeight? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
onChangeText={handleProductWeightChange}
value={productWeight}
 placeholder="Ajouter le poids du produit ici"
 />




<Text style={StyleAddProduct.Label}>La description du produit *</Text>
   <TextInput
 style={productDescription.length===0 ? StyleAddProduct.inputTextBig: everythingOkproductDescription? StyleAddProduct.inputTextBigSucess:StyleAddProduct.inputTextBigFail}
 placeholder="Ajouter la d&#233;scription du produit ici"
onChangeText={handleProductDescriptionChange}
value={productDescription}
multiline={true}
 />

 <View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductDescription?StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productDescription.length} / 2900</Text>
 </View>


<Text style={StyleAddProduct.Label}>Les composants du produit ou les informations supplémentaires.Si vous n'en avez pas, laissez le texte par défaut *</Text>
   <TextInput
 style={productComponent.length===0 ? StyleAddProduct.inputTextBig: everythingOkproductComponent? StyleAddProduct.inputTextBigSucess:StyleAddProduct.inputTextBigFail}
 placeholder={productComponent}
              onChangeText={handleProductComponentChange}
value={productComponent}
multiline={true}
 />
 <View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductComponent? StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productComponent.length} / 600</Text>
 </View>


<Text style={StyleAddProduct.Label}>Note de responsabilité civile pour d'éventuels problèmes. Si vous n'avez pas de note ou de remarque, laissez le texte par défaut *</Text>
   <TextInput
 style={productNote.length===0 ? StyleAddProduct.inputTextBig: everythingOkproductNote? StyleAddProduct.inputTextBigSucess:StyleAddProduct.inputTextBigFail}
 placeholder={productNote}
 onChangeText={handleProductNoteChange}
value={productNote}
multiline={true}
 />


 <View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductNote?  StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productNote.length} / 600</Text>
 </View>

<Text style={StyleAddProduct.Label}>Quantité disponible *</Text>
   <TextInput
 style={productQuantity.length===0 ? StyleAddProduct.inputText: everythingOkproductQuantity? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
 placeholder="Ajouter la quantit&#233; disponible ici"
onChangeText={handleProductQuantityChange}
 value={productQuantity}
 />


<Text style={StyleAddProduct.Label}>Le prix du produit *</Text>
 <TextInput
 style={productPrice.length===0 ? StyleAddProduct.inputText: everythingOkproductPrice? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
 placeholder="Ajouter le prix du produit ici"
onChangeText={handleProductPriceChange}
 value={productPrice}
 />






<Text style={StyleAddProduct.Label}>Les images (5 images max) *</Text>

<Pressable  
style={images.length===0 ? StyleAddProduct.AddImageButton: images.length>0 && images.length<6? StyleAddProduct.AddImageButtonSucess:StyleAddProduct.AddImageButtonFail}

onPress={pickImage}>
 <Text style={StyleAddProduct.AddImageButtonText}>Ajouter des images</Text></Pressable>
<View style={images.length>0 ? StyleAddProduct. ImageContainerDisplayed : StyleAddProduct.ImageContainerHidden}>
        {images.length>0 && images.map((imageUri, index) => (
           <View key={index} style={{ width:Platform.OS==="web"?200:"33%",height: Platform.OS==="web"?200:120, padding:"2%", margin:Platform.OS==="web"?20:10,}} >
          <Image
           
            source={{ uri: imageUri }}
            style={{position: 'absolute',borderRadius:4, top: 0, right: 0 , width: "100%", height:"100%", }}
          />
          <TouchableOpacity 
          style={StyleAddProduct.RemoveImage}
          onPress={() => removeImage(index)}
        >
          <Text><Fontisto name="trash" size={24} color="white" /></Text>
        </TouchableOpacity></View>
        ))}
 </View>




{/*return policie */}

 <Text style={StyleAddProduct.Label}>Votre politique du retour pour ce produit *</Text>

 <View style={StyleAddProduct.RadioView}>
<RadioButton value="Retour accepté dans 14 jours, le vendeur paie les frais du retour" selected={selectedValue === 'seller pay'} 
onSelect={() => {handleRadioSelectTextFloomy('seller pay',false);triggerHapticFeedback();}} />

<TouchableOpacity onPress={()=>{handleRadioSelectTextFloomy('seller pay',true);}} 
style={StyleAddProduct.RadioViewButton}>
 <Text><FontAwesome name="question" size={16} color="black" /></Text>
</TouchableOpacity>
      </View>

      {showTypewriterRadioButtons&&selectedValue==="seller pay"?(

<View style={StyleAddProduct.FloomyRadioView}>

{showTypewriterRadioButtons?( 
<Typewriter text={textInfo}
delay={10} color={"black"}/>
):null}
<Pressable style={StyleAddProduct.ErrorViewButton} onPress={()=>{setshowTypewriterRadioButtons(false)}}><Text style={StyleAddProduct.ErrorViewText}>ok</Text></Pressable>

</View>


):null}



<View style={StyleAddProduct.RadioView}>
<RadioButton value="Retour accepté dans 14 jours, le client paie les frais du retour" selected={selectedValue === 'buyer pay'} 
onSelect={() => {handleRadioSelectTextFloomy('buyer pay',false);triggerHapticFeedback();}} />

<TouchableOpacity onPress={()=>{handleRadioSelectTextFloomy('buyer pay',true);}} 
style={StyleAddProduct.RadioViewButton}>
 <Text><FontAwesome name="question" size={16} color="black" /></Text>
</TouchableOpacity>
      </View>

      {showTypewriterRadioButtons&&selectedValue==="buyer pay"?(

<View style={StyleAddProduct.FloomyRadioView}>

{showTypewriterRadioButtons?( 
<Typewriter text={textInfo}
delay={10} color={"black"}/>
):null}
<Pressable style={StyleAddProduct.ErrorViewButton} onPress={()=>{setshowTypewriterRadioButtons(false)}}><Text style={StyleAddProduct.ErrorViewText}>ok</Text></Pressable>

</View>


):null}



<View style={StyleAddProduct.RadioView}>
<RadioButton value="Les retours ne sont pas acceptés" selected={selectedValue === 'not accepted'} 
onSelect={() => {handleRadioSelectTextFloomy('not accepted',false);triggerHapticFeedback();}} />

<TouchableOpacity onPress={()=>{handleRadioSelectTextFloomy('not accepted',true);}} 
style={StyleAddProduct.RadioViewButton}>
 <Text><FontAwesome name="question" size={16} color="black" /></Text>
</TouchableOpacity>
      </View>

      {showTypewriterRadioButtons&&selectedValue==="not accepted"?(

<View style={StyleAddProduct.FloomyRadioView}>

{showTypewriterRadioButtons?( 
<Typewriter text={textInfo}
delay={10} color={"black"}/>
):null}
<Pressable style={StyleAddProduct.ErrorViewButton} onPress={()=>{setshowTypewriterRadioButtons(false)}}><Text style={StyleAddProduct.ErrorViewText}>ok</Text></Pressable>

</View>


):null}








<Text style={StyleAddProduct.Label}>Les conditions d'utilisation *</Text>

 <CheckBox value1="Lire les conditions d'utilisation" value="J'ai lu et j'accepte les conditions d'utilisation"  checked={checked} readTermAndConditions={readTermAndConditions} onSelect={handleCheck}/>



<View  style={StyleAddProduct.Bottom}></View>


</ScrollView>





</SafeAreaView>

{
  everythingOkproductName &&
  everythingOkproductGrandpa &&
  everythingOkproductFather &&
  everythingOkproductSon &&
  everythingOkproductGrandson &&
  everythingOkproductWeight &&
  everythingOkproductDescription &&
  everythingOkproductNote &&
  everythingOkproductComponent &&
  everythingOkproductPrice &&
  everythingOkproductQuantity &&
  everythingOkproductImages &&checked&&selectedValue? (
    <TouchableOpacity onPress={insertProduct} style={StyleAddProduct.insertButtonSuccess}>
      <Text style={StyleAddProduct.insertButtonSuccessText}>Ajouter le produit</Text>
    </TouchableOpacity>
  ) :(

    <TouchableOpacity  onPress={showProblem} style={StyleAddProduct.insertButtonFail}>
      <Text style={StyleAddProduct.insertButtonSuccessText}>Remplissez d'abord tous les champs</Text>
    </TouchableOpacity>

  )
}

{/*error and loading handling*/}
{Success?(
 <View style={StyleAddProduct.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();navigation.navigate('sellerHomePage');}}style={StyleAddProduct.ErrorViewButton}><Text  style={StyleAddProduct.ErrorViewText}>Home</Text></TouchableOpacity>

   </View>):null
    }





   {Error?(    <View style={StyleAddProduct.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
 
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleAddProduct.ErrorViewButton}><Text  style={StyleAddProduct.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}

    {loading?(<View style={StyleAddProduct.LoadingView}>
      <ActivityIndicator size="large" color="black" style={{ alignSelf: 'center' }} />
  
  </View>):null}
</KeyboardAvoidingView>
  );
};
const StyleAddProduct = StyleSheet.create({
  androidSafeArea: { height: "100%",paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#00BFFF" },
  Container: { height: "100%", backgroundColor: "#00BFFF", paddingTop: 30 },
  Bottom:{height:300, backgroundColor:"#00BFFF"},
  HeadContainer:{backgroundColor:"#00BFFF", height:70},
  HeadTextView: { backgroundColor: "#00BFFF", justifyContent: "center", alignItems: "center" },
  HeadText: { fontSize: 20, fontWeight: "bold", textAlign: "center" },
  Label: { marginLeft: "5%", marginBottom: 15, marginTop: 15, fontSize: 16, fontWeight: "bold" },




  inputText: { backgroundColor: "white", height: 50, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10 },
  inputTextFail: { backgroundColor: "white", height: 50, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "red", borderRadius: 4, paddingLeft: 10 },
  inputTextSucess: { backgroundColor: "white", height: 50, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4, paddingLeft: 10 },

 inputTextBig: { backgroundColor: "white", height: 220, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10  },
  inputTextBigFail: { backgroundColor: "white", height: 220, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "red", borderRadius: 4, paddingLeft: 10 },
  inputTextBigSucess: { backgroundColor: "white", height: 220, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4, paddingLeft: 10 },


  PickerContainer:{backgroundColor:"white", width:"90%", marginHorizontal:"5%", marginBottom:20, borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, },

  PickerContainerSucess:{backgroundColor:"white", width:"90%", marginHorizontal:"5%", marginBottom:20, borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4, },
  PickerContainerFail:{backgroundColor:"white", width:"90%", marginHorizontal:"5%", marginBottom:20, borderWidth: 2, borderColor: "red", borderRadius: 4, },




 AddImageButton: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4,justifyContent:"center", alignItems:"center" },
  SelectCategoryButton:{backgroundColor: "white", height: 50, width: "100%",
    justifyContent: 'center',paddingLeft:10
    },
    AddImageButtonFail: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
    borderWidth: 2, borderColor: "red", borderRadius: 4,justifyContent:"center", alignItems:"center" },
    
    AddImageButtonSucess: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
    borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4,justifyContent:"center", alignItems:"center" },
     
    AddImageButtonText:{fontWeight:"bold"},
    ImageContainerDisplayed:{  width: "90%", marginLeft: "5%", marginRight: "5%", marginVertical:10, Flex:1, flexDirection:"row", flexWrap:"wrap", backgroundColor: "white",     
     borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4,padding:10},

     ImageContainerHidden:{display:"none"},
     RemoveImage:{position: 'absolute', top: 0, right: 0 
     ,backgroundColor:"transparent", height:40, width:40, justifyContent:"center", alignItems:"center", borderRadius:40},
  BackButton:{height:50, width:50, marginVertical:10, justifyContent:"center", alignItems:"center"},
  insertButtoninitial:{
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  insertButtonSuccess:{   width: '100%',
  backgroundColor: '#5cb85c',
  height: 70,
  justifyContent: 'center',
  alignItems: 'center',
  position:Platform.OS==="web"?'fixed': 'absolute',
  bottom: 0,
  left: 0,},
  insertButtonSuccessText:{
    fontWeight:"bold", fontSize:18,color:"white"
  },


  insertButtonFail:{  
    width: '100%',
    backgroundColor: 'grey',
    minHeight:70,
    height: "auto",
    paddingHorizontal:20,
    paddingVertical:15,
    justifyContent: 'center',
    alignItems: 'center',
    position:Platform.OS==="web"?'fixed': 'absolute',
    bottom: 0,
    left: 0,
    },
  insertButtonFailText:{fontWeight:"bold", fontSize:14,color:"white"
},
  keyboardAvoidingContainer: {
    flex: 1,
  },

 //error and loading view

   //error and loading view
   LoadingView:{
    position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
    backgroundColor:"#00BFFF", justifyContent:"center", alignItems:"center"
  },
  ErrorView:{flex:1, flexDirection:"column",
  position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",
   backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
   width:"98%",marginHorizontal:"1%",marginVertical:10,borderRadius:4, borderWidth:1, borderColor:"rgba(0,0,0,0.4)"
  },
  ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
  ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
   marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},
   SuccessView:{flex:1, flexDirection:"column",
   position:Platform.OS==="web"?"fixed":"absolute", top:0,left:0,height:"auto",
    backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
    width:"98%",marginHorizontal:"1%",marginVertical:10,borderRadius:4, borderWidth:1, borderColor:"rgba(0,0,0,0.4)"
  },




lengthView:{marginRight:"5%",marginLeft:"auto"},
lengthTextSucess:{fontSize:13, fontWeight:"bold", color:"#5cb85c"},
lengthTextFail:{fontSize:13, fontWeight:"bold", color:"red"},


pickerItem:{minHeight:50, backgroundColor: "white",  width: "90%", marginTop:10,height:Platform.OS==="web"?50:"auto",fontSize:16,
marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10 },

   //radio buttons
   RadioView:{width:"100%", height:"auto",paddingLeft:"5%",paddingBottom:10,
   flex:1, flexDirection:"row", overflow:"hidden", alignItems:"center", },
   RadioViewButton:{backgroundColor:"#bccc8f",
   width:28,height:28,borderRadius:28,
   justifyContent:"center",
  alignItems:"center",marginBottom:-10,
  
  },
  FloomyRadioView:{

backgroundColor:"white",width:"90%", marginHorizontal:"5%",paddingBottom:10,
borderColor:"rgba(0, 0, 0, 0.4)", borderRadius:4,borderWidth:1,
  }
,
  FloomyRadioViewTop:{
position:Platform.OS==="web"?"fixed":"absolute",top:Platform.OS==="web"?0:50, left:0,
    backgroundColor:"white",width:"100%",paddingBottom:10,borderBottomWidth:1,

      }  



});

export default  AddProductSeller;




