import React ,{ useEffect, useState,useRef, useContext} from 'react';
import {Modal,ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView,Animated ,Platform,TouchableOpacity,Image, Linking } from 'react-native';
import AutocompleteCity from './AutocompleteCity';
import { useNavigation } from '@react-navigation/native';
import { useMyContext } from './MyContext';
import { Feather } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import NetInfo from '@react-native-community/netinfo';
import MenuLoginUser from './menuLoginForUser';
import Checkbox from 'expo-checkbox';
import * as Haptics from 'expo-haptics';
import axios from 'axios';
import Typewriter from "./typeWriter";
import { AntDesign } from '@expo/vector-icons';

const PayementGate = ({ navigation, route}) => {









// Function that construct the stars for rating 
function StarRating({ score }) {

  if(score){
  const filledStars = Array(parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`filled-${index}`} name="star" size={14} color="#fcb103" />
  ));
  const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`empty-${index}`} name="star" size={14} color="grey" />
  ));
  const stars = [...filledStars, ...emptyStars];
  return <Text>{stars}</Text>;
}
}





  const handlePhoneLinkPress = (phoneNumber) => {

    Linking.openURL(`tel:${phoneNumber}`);
  };
//contain product details
const { PayementData } = route.params;
//hold the mandat number
const [TextMandatNumber, setTextMandatNumber]=useState('');
const [EveryThingOKTextMandatNumber, setEveryThingOKTextMandatNumber]=useState(false);
//user object 
const{User, setUser}= useMyContext({});
const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();
  //var to trigger a fetch for notification when back to homepage
  const {contextTrigger, setcontextTrigger}=useMyContext();

// Function to calculate the total delivery price for order

function calculateDeliveryCost(totalWeight) {
  let totalDeliverySeller;
  if (totalWeight < 2001) {
    totalDeliverySeller = 8;
  } else {
    const totalWeightProductSeller = totalWeight / 1000;
    const totalWeightProductSellerRounded = Math.ceil(totalWeightProductSeller) - 2;
    totalDeliverySeller = 8 + (totalWeightProductSellerRounded * 0.5);

  }
  return  Number(totalDeliverySeller.toFixed(2));
}


//calculate mandat costs
  const calculateMandatCost=(price)=>{
    let cost =0;
    if(price<=250){
  
  cost=5;
  
    }
    else if(price>250&&price<=1000){
      cost=10;
    }
  
    else if(price>1000&&price<=2000){
      cost=15;
    }
    else if(price>2000&&price<=3000){
      cost=10;
    }
  
  
      return cost;
    }


///check only numbers
    const onlyNumber = (str) => {
      return /^[0-9]+$/.test(str);
    };

//handle mandat input text change
const handleTextMandatChange=(text)=>{
  setTextMandatNumber(text);
if(onlyNumber(text)){
setEveryThingOKTextMandatNumber(true);
setError('');
}
else {
  if(text.length>0){
 
setError("le numéro secret ne doit contenir que des chiffres !");
}
setEveryThingOKTextMandatNumber(false);

}


}




//handle insert Mandat
const insertMandat=()=>{
  const user_name=PayementData.user_name;
  const user_id=PayementData.user_id;
const order_id=PayementData.orderId;
const total=(PayementData.TotalToPay).toFixed(2);


const number=TextMandatNumber;
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded

  };


  // Construct the form data
  const formData = new FormData();
  formData.append('user_id', user_id);
  formData.append('user_name', user_name);
  formData.append('order_id', order_id);
  formData.append('total_to_pay', total);
  formData.append('number', number);

  axios.post('https://tunizoon.com/appgate/userOrderActions.php?action=InsertM', formData, {
    headers: headers, 
  })
  

  .then((response) => {
    if (response.data.success) {
    setSuccess(response.data.message);


    } else {
      setError('An error occurred:', response.data.error);
      // Handling the error case
    }
  })
  .catch((error) => {
    setError('An error occurred:', error);
    // Handling the fetch error
  });

}



//navigate
const navigateBack=()=>{

  navigation.navigate('OrdersUserAll', {propName:User});

}

      //const while loading
const [loading, setLoading] = useState(false);
//Error holding and displaying vars
const [Error, setError]=useState('');
//hold success
const [Success, setSuccess]=useState('');
    //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}


//text for payement methods
const [textD17, settextD17]=useState(
  `Vous avez choisi le paiement par l'application D17 ya ${User[0].name} Voici notre code 3D. Veuillez le scanner et effectuer le virement du montant ${(PayementData.TotalToPay).toFixed(2)} TND.  Après réception du paiement, vous recevrez une notification et votre commande sera expédiée immédiatement.`
  );

  const [textVirement, settextVirement]=useState(`Vous avez choisi le paiement par virement bancaire  ya ${User[0].name}. Voici le numéro de notre compte à La Poste. Veuillez virer le montant ${(PayementData.TotalToPay).toFixed(2)} TND sur ce compte \n\nCompte courant Chez la Poste 17503000000323940235 \nNom de la companie: GUASMIS NETZ SARL.\n\nDès réception du paiement, vous recevrez une notification et votre commande sera expédiée immédiatement.`);

  const [textMandatMin, settextMandatMin]=useState(`Vous avez choisi le paiement par mandat minute ya ${User[0].name}. Voici les coordonnées de notre agent \n\n- Nom de l'agent: Mohamed Amine Gasmi  \n- Téléphone de l'agent:22141799 \n\nEnvoyez le mandat avec le montant ${(PayementData.TotalToPay).toFixed(2)} TND et payer les Frais du mandat ${calculateMandatCost(PayementData.TotalToPay).toFixed(2)} TND et entrez le code ci-dessous. Notre agent retirera l'argent, Après la réception du paiement, vous recevrez une notification et votre commande sera expédiée immédiatement.
`);


const [Order,setOrder]=useState();
  //function to fetch orderData from tunizoon.com
  const myFetchDataFunction= () => {
setLoading(true);
    const user_id=User[0].user_id;
    const key="10876767594ewjhefsds98799237498";
    const order_id =PayementData.orderId;
    // fetch data when component is mounted
    fetch('https://tunizoon.com/appgate/userOrders.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:`order_id=${order_id}&Key=${key}&user_id=${user_id}`
    })
      .then((response) => response.json())
      .then((json) => {
     
        setOrder(json.Order); // set product data state

     if(json.Order){
      setTimeout(() => {
        setLoading(false);
      }, 300);
  
    }
      })
     
      .catch((error) => {
        console.error(error);
        
      });
      
    };



  
     
     
 

  useEffect(() => {
  
    myFetchDataFunction();
   
  }, [contextTrigger]);





  const calculateWeightWithUnit = (weight) => {
    let unit="  g      ";
    if (weight >= 1000) {
      weight /= 1000;
      unit=" Kg   ";
  
    }
    return `${Number(weight.toFixed(2))}${unit}`;
  };









  return (

    <>
  <SafeAreaView style={styles.androidSafeArea}>
      <View style={styles.headContainer}>
      <View  style={styles.logoAndButtonContainer}>

<Pressable style={styles.BackButton} onPress={() =>{triggerHapticFeedback();navigateBack();} }>
<Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
</Pressable>

</View>
</View>


<ScrollView  veritcal={true} showsVerticalScrollIndicator={false} style={styles.root} scrollEventThrottle={20} 
 keyboardShouldPersistTaps='handled'>
{/*payement method bank trnasfert */}



<View style={styles.payementInstructionsContainer}>
  



{PayementData.sellersData.map((sellerObj, index) => {
        const sellerKey = Object.keys(sellerObj)[0]; // Extract the seller key
        const sellerData = sellerObj[sellerKey]; // Extract the seller data


const TotalProducts=parseFloat(sellerData.totalSeller)-10;

const TotalDelivery=calculateDeliveryCost(TotalProducts);
const TotalForThisSeller=(TotalProducts+TotalDelivery).toFixed(2);



        const TextSellerCashOnDelivery=`Vous avez choisi le paiement à la livraison ya ${User[0].name}.\nVoici les coordonnées du vendeur :\n\n\n# Nom du vendeur : ${sellerData.productSellerName}.\n# Numéro de téléphone du vendeur : ${sellerData.productSellerPhoneNumber}.\n\n\nVeuillez appeler le vendeur pour demander une confirmation de la commande. Dès qu'il confirme, vous recevrez une notification, et votre commande sera expédiée immédiatement. Lors de la livraison, vous paierez un montant total de ${TotalForThisSeller} TND, comprenant ${TotalDelivery.toFixed(2)} TND pour la livraison Aramex 72h et ${TotalProducts.toFixed(2)} TND pour les produits.`;



        const TextSellerRib=`Vous avez choisi le paiement par virement bancaire ya ${User[0].name}. Veuillez effectuer un virement d'un montant total de ${TotalForThisSeller} TND, comprenant ${TotalDelivery.toFixed(2)} TND pour la livraison Aramex 72h et ${TotalProducts.toFixed(2)} TND pour les produits, sur le compte du vendeur suivant :\n\n\n# Nom du Vendeur : ${sellerData.productSellerName}\n# Numéro de téléphone du vendeur : ${sellerData.productSellerPhoneNumber}\n# Compte Bancaire : ${sellerData.productSellerBankAccount}\n# Banque : ${sellerData.productSellerBankName}\n\n\nDès réception du paiement, vous recevrez une notification, et votre commande sera expédiée immédiatement.`;




        const TextSellerMandatMin=`Vous avez choisi le paiement par mandat minute ya ${User[0].name}. Voici les coordonnées du vendeur :\n\n\n# Nom du vendeur : ${sellerData.productSellerName}.\n# Numéro de téléphone du vendeur : ${sellerData.productSellerPhoneNumber}.\n\n\nEnvoyez le mandat d'un montant total de ${TotalForThisSeller} TND, comprenant ${TotalDelivery.toFixed(2)} TND pour la livraison Aramex 72h et ${TotalProducts.toFixed(2)} TND pour les produits. De plus, veuillez payer les frais du mandat d'un montant de ${calculateMandatCost(parseFloat(TotalForThisSeller)).toFixed(2)} TND et saisissez le code ci-dessous, ou partagez le code avec le vendeur par SMS ou appel. Le vendeur retirera les fonds, et une fois le paiement reçu, vous recevrez une notification, et votre commande sera expédiée immédiatement`;


        return (
          <View style={styles.OrderPartsView} key={index}>
 {/*head of order parts*/}
<View style={styles.OrderPartsHeaderView}>
 <Text style={styles.OrderPartsHeaderText}>commande Nr-{PayementData.orderId}</Text>
 <Text style={styles.OrderPartsHeaderText}>Vendeur {index+1}/{PayementData.sellersData.length}</Text>
</View>



 {/*view to show the prducts*/}
<View>

<View style={styles.Separator}></View>
<Text style={styles.OrderdetailsectionText}>- Vendeur:</Text>


<View style={{height:"auto",
 paddingHorizontal:10,flex:1,
  flexDirection:"row",justifyContent:"space-between", alignItems:"center",paddingVertical:30,
}}>
  <Image  source={{ uri: `https://tunizoon.com/${sellerData.productSellerImage}` }} style={styles.sellerImage}/>
  <View style={{ flex:1, flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
<Text style={styles.OrderPartsHeaderTextShopName}>{sellerData.productSellerName} shop</Text>
<StarRating  score={sellerData.productSellerScore} />
</View>
<TouchableOpacity  onPress={()=>{handlePhoneLinkPress(sellerData.productSellerPhoneNumber)}} style={styles.PhoneButton}>
  <Text><Entypo name="phone" size={30} color="white" /></Text></TouchableOpacity>
  </View>
  <View style={styles.Separator}></View>
  <Text style={styles.OrderdetailsectionText}>- Produits:</Text>

  <ScrollView  horizontal={true} showsVerticalScrollIndicator={false}>


  {Order&&Order.map((Order, OrderIndex) => {


if(Order.sku_seller===sellerData.productSellerSku){
return(

<View key={OrderIndex}>
<View style={styles.productView}>
<Image  source={{ uri: `https://tunizoon.com/${Order.product_image}` }} style={styles.productImage}/>
<View style={styles.productViewText}>
<Text>{Order.product_name.slice(0, 10)+ '...'}</Text>
<Text>Quantité: {Order.Qty}</Text>
</View>
<View style={styles.productViewPrice}>
<Text style={styles.productViewPriceText}>{Order.price} TND</Text>
</View>
</View>

</View>

);
}else{return null}

})}

  </ScrollView>
  <View style={styles.Separator}></View>
  <Text style={styles.OrderdetailsectionText}>- Details du prix:</Text>



  <View style={{paddingVertical:10, flex:1, flexDirection:"row", flexWrap:"wrap",width:"70%", marginLeft:"20%", marginRight:"10%"}}>

  <View style={styles.OrderPriceDetailsView}>
  <Text style={styles.OrderPartsHeaderText}>Total produits:</Text>
  </View>

  <View style={styles.OrderPriceDetailsView}>
  <Text style={styles.priceTableNumbers}>{TotalProducts.toFixed(2)} TND</Text>
  </View>

  <View style={styles.OrderPriceDetailsView}>
  <Text style={styles.OrderPartsHeaderText}>Total livraison:</Text>
  </View>

  <View style={styles.OrderPriceDetailsView}>
  <Text style={styles.priceTableNumbers}>{TotalDelivery.toFixed(2)} TND</Text>
  </View>

  <View style={styles.OrderPriceDetailsView}>
  <Text style={styles.OrderPartsHeaderText}>Total a payer:</Text>
  </View>

  <View style={styles.OrderPriceDetailsView}>
  <Text style={styles.priceTableNumbersBold}>{TotalForThisSeller} TND</Text>
  </View>


  </View>






<Text>

  
  </Text>
</View>
{/*payement method on delivery*/}
{PayementData&&PayementData.PayementMethod==="Paiment à la livraison"?

(
<View>
  
 <View style={styles.FloomyContainer}>
  <Typewriter text={TextSellerCashOnDelivery} delay={5} color={"black"}/>
 </View>
  </View>




):null


}

{/*payement method virement bancaire */}
{PayementData&&PayementData.PayementMethod==="virement bancaire"?

(
<View>
  
 <View style={styles.FloomyContainer}>
  <Typewriter text={TextSellerRib} delay={5} color={"black"}/>
 </View>
  </View>




):null


}



{/*payement method d17 */}

{PayementData&&PayementData.PayementMethod==="d17"?

(
<View>
<View style={styles.FloomyContainer}>
  <Typewriter text={textD17} delay={10} color={"black"}/>
 </View>


<View style={styles.shadowContainer}>
 <Image source={{uri:'https://tunizoon.com/codelogo.jpg'}} style={styles.D17Logo}></Image></View>
 <Text></Text>
</View>




):null


}

{/*payement method mandat minute*/}


{PayementData&&PayementData.PayementMethod==="mandat minute"?

(
<View style={styles.payementInstructionsContainer}>
<View style={styles.FloomyContainer}>
  <Typewriter text={TextSellerMandatMin} delay={10} color={"black"}/>
 </View>
  


 <TextInput
   

   style={[
    {
      borderColor:textMandatMin.length<1?"rgba(0, 0, 0, 0.4)":(EveryThingOKTextMandatNumber? "#5cb85c" : "red"),
    },
    styles.inputText,
  ]}

              placeholder="le numéro secret du mandat"
              onChangeText={handleTextMandatChange}
              value={TextMandatNumber}
   />
  <TouchableOpacity  onPress={insertMandat} style={[ {backgroundColor:EveryThingOKTextMandatNumber? "#5cb85c" : "grey"},styles.SendMandatButton]}>
    <Text style={{color:"white",fontWeight:"bold"}}>Ajouter le numéro secret </Text></TouchableOpacity >
  </View>




):null


}
</View>
        );
      })}

</View>

</ScrollView>

















    </SafeAreaView>










      {/*error and loading handling*/}
      {Error?(    <View style={styles.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}


    {Success?(
 <View style={styles.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }

    {loading?(<View style={styles.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}
    </>
    
  );
};


const styles=StyleSheet.create({
  androidSafeArea: {
paddingTop: Platform.OS === 'android' ? 25 : 0,
    backgroundColor:"#00BFFF",

    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

},

root:{flex:1,zIndex:-2,backgroundColor:"#00BFFF",
},

headContainer:{height:70,
},
logoAndButtonContainer:{ flex:1, flexDirection:"row",justifyContent:"space-between",backgroundColor:"#00BFFF",height:80,marginBottom:20
},
 BackButton:{height:50, width:50, backgroundColor:"#00BFFF", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
},


//payement instructions
payementInstructionsContainer:{height:"auto", width:Platform.OS==="wed"?"100vw":"100%"

,backgroundColor:"white",paddingVertical:20,
},

OrderPartsView:{
  backgroundColor:"white", marginBottom:20,borderRadius:4,overflow:"hidden",maxWidth:900,margin:"auto",

},

OrderPartsHeaderView:{
  width:"100%", alignItems:"center", justifyContent:"space-between",flex:1, 
  flexDirection:"row",padding:10,
},

Separator:{borderBottomWidth:8,  borderColor:"rgba(0, 0, 0, 0.02)"},
OrderPriceDetailsView:{
  width:"50%", paddingHorizontal:10,
},
priceTableNumbers:{

  alignSelf:"flex-end"
},
priceTableNumbersBold:{
fontWeight:"bold",
  alignSelf:"flex-end"
},
OrderPartsHeaderText:{
  fontSize:14, fontWeight:"bold", color:"grey"
},
OrderPartsHeaderTextShopName:{
  fontSize:16, fontWeight:"bold", color:"grey"
},

FloomyContainer:{backgroundColor:"white",borderTopWidth:8, borderColor:"rgba(0,0,0,0.02)",marginBottom:30},

D17Logo:{
  width: 300,
  height: 300,
  alignSelf: 'center',


},


shadowContainer: {
  marginTop:30,
  padding:10,
  width: 310,
  height: 310,
  alignSelf:"center",
  shadowColor: 'rgba(0, 0, 0, 0.3)',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.8,
  shadowRadius: 4,
  elevation: 4, // For Android
},



inputText: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderRadius: 4, paddingLeft: 10,marginTop:30, },


SendMandatButton:{height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
justifyContent:"center", alignItems:"center", marginTop:20,borderRadius:4
},





















 //error and loading view
 LoadingView:{
  position:Platform.OS==="web"?"fixed":"absolute", top:0,left:0,height:Platform.OS==="web"?"100vh":"100%",width:"100%", zIndex:99,
  backgroundColor:"#00BFFF", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",
width:"98%",marginHorizontal:"1%",marginVertical:10,borderRadius:4, borderWidth:1, borderColor:"rgba(0,0,0,0.4)", backgroundColor:"#DE2C2C", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
SuccessView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",
width:"98%",marginHorizontal:"1%",marginVertical:10,borderRadius:4, borderWidth:1, borderColor:"rgba(0,0,0,0.4)", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},
//products

productView:{flex:1, flexDirection :"column",height:"auto", width:102,borderWidth:1,margin:10,borderColor:"rgba(0, 0, 0, 0.2)", borderRadius:4,},
productViewText:{padding:5, },
productViewPrice:{borderBottomRightRadius:4, borderBottomLeftRadius:4,marginBottom:0,marginTop:"auto",
  justifyContent:"center", alignItems:"center",
   backgroundColor:"rgba(0, 0, 0, 0.4)",width:"100%", paddingVertical:5},
productViewPriceText:{fontWeight:"bold", color:"white"},
productImage:{height:100, width:100,borderTopRightRadius:4, borderTopLeftRadius:4},




sellerImage:{height:49, width:49,borderRadius:49,borderWidth:1},
PhoneButton:{height:50, width:50, borderRadius:50, backgroundColor:"green",justifyContent:"center", alignItems:"center" },
OrderdetailsectionText:{fontWeight:"bold",color:"green", marginLeft:10,marginVertical:10,}
})
export default PayementGate;

 

