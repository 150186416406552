import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView,Platform,Animated,TouchableOpacity,Image} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons'; 
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import { useMyContext } from '../MyContext';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import Typewriter from "../typeWriter";


const MenuLoginForSeller= ({ Customnavigation, route ,toggle }) => {
      //hold success
      const [Success, setSuccess]=useState('');

      //unset error 
const unsetError = () => {
  setError();
};
       //Error holding and displaying vars
       const [Error, setError]=useState('');
 //variables to hold the banking values
 const [Rib, setRib]=useState('');
 const [BankInstitution, setBankInstitution]=useState('');
 const [everythingOKRib, seteverythingOKRibRib]=useState(false);
 const[updated,setupdated]=useState(false);
 const [everythingOKRibBankInstitution, seteverythingOKRibBankInstitution]=useState(false);
  //check only letters and numbers in the input
  const onlyLettersAndNumbers = (str) => {
    return /^[A-Za-z0-9\s]*$/.test(str);
  };
  
//handle change bank inputs
const handleRibChange=(rib)=>{
  if (onlyLettersAndNumbers(rib)  && rib.length>0&& rib.length<30) {
    seteverythingOKRibRib(true);
    setRib(rib);
}
else{
  seteverythingOKRibRib(false);
  setRib(rib);
  if(rib.length>0){
    setError("Le numéro du compte bancaire ne peut contenir que des lettres et des chiffres");
  }
}

}


//updating banking data
const updateBankinData = async () => {
  setLoading(true);
  triggerHapticFeedback();

  const sku_seller = Seller[0].sku_seller;
  const Rib_seller = Rib;
  const bank_institution = BankInstitution;

  const formData = new FormData();
  formData.append('sku_seller', sku_seller);
  formData.append('rib', Rib_seller);
  formData.append('bank_name', bank_institution);

  try {

    const response = await axios.post(
      'https://tunizoon.com/appgate/sellerUpdateInfo.php?action=addBankinData',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (!response.data.success) {

      setLoading(false);
      setError(response.data.error);
    }else{

  setSuccess("Vos données bancaires ont été ajoutées avec succès, tawa les clients ynajmou ysobboulek direct w y5allsou en ligne zeda sa77it \u{1F607}");

  setupdated(true);

    
    }


  } catch (error) {
    // Handle network errors or other exceptions
    console.error(error);
  }

  setLoading(false);
};








//handle bank name change
const handleBankInstitutionChange=(bankName)=>{

  if (onlyLettersAndNumbers(bankName)  && bankName.length>0&& bankName.length<100) {
seteverythingOKRibBankInstitution(true);
setBankInstitution(bankName);
  }
  else{
    seteverythingOKRibBankInstitution(false);
    setBankInstitution(bankName);


    if(bankName.length>0){
      setError("Le nom de l'institution du compte bancaire ne peut contenir que des lettres et des chiffres");
  }
  } 


}










//context trigger used to update
const {contextTrigger, setcontextTrigger}= useMyContext();
//if the seller is new
const [TextSoldeAndFoto,setTextSoldeAndFoto]=useState("Vous êtes nouveau ici, donc je vais vous expliquer comment cela fonctionne. Le solde est actuellement à 0,00 TND car vous n'avez pas encore vendu de produit. Chaque fois que vous vendez un produit et que l'acheteur paie, nous créditons automatiquement votre compte, ce qui augmente votre solde. Vous pourrez retirer l'argent à tout moment en appuyant sur le bouton de retrait, qui apparaîtra dès que votre solde ne sera plus à 0,00 TND. Pour changer la photo de votre profil, appuyez sur le bouton Changer ma photo. Pour voir vos commandes, appuyez sur Mes commandes. Et quand vous souhaitez vous déconnecter, appuyez sur Logout\u{1F607} w mar7be bik.");
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
// seller Object
const{Seller, setSeller}= useMyContext([]);

const [image, setImage] = useState(null);

//const while loading
const [loading, setLoading] = useState(false);
const{sellerIsLoggedIn, setsellerIsLoggedIn}=useMyContext();
//loggin the seller out
const handleLogout=()=>{

triggerHapticFeedback();
toggle();
   setSeller([]);
   setsellerIsLoggedIn(false);
   Customnavigation.navigate('home');
  }
  









//pick image 
    const pickImage = async () => {

       triggerHapticFeedback();
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
    
    
    
        if (!result.canceled) {
          setImage(result.assets[0].uri);
        }
      };
    
     const DeleteImage=()=>{
     
triggerHapticFeedback();
      setImage(null);
    
     }
    
    // formData.append('seller_id', seller_id); 
    
     //backend to do
    
              
    //upload image
  const handleUploadImage = async () => {
    setLoading(true);
    triggerHapticFeedback();

    const seller_id = Seller[0].sku_seller;
    const seller_image = Seller[0].image;
    let imageUri = image;

    const formData = new FormData();
  
    formData.append('seller_id', seller_id); 
    formData.append('seller_image', seller_image);
    try {
      if (Platform.OS === 'web') {
        // On web, read the image file as an ArrayBuffer
        const response = await fetch(imageUri);
        const buffer = await response.arrayBuffer();
        imageUri = new Blob([buffer], { type: 'image/jpeg' });
        formData.append('image', imageUri, 'image.jpg');
      } else {
        // On mobile (Expo), use the image URI directly
        formData.append('image', {
          uri: imageUri,
          type: 'image/jpeg',
          name: 'image.jpg',
        });
      }

      const response = await axios.post(
        'https://tunizoon.com/appgate/sellerPhotoUploadApp.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const responseText = response.data;

      if (response.status === 200) {
        // Handle the successful response from the PHP API
        alert('Image uploaded successfully!');


        setSeller(responseText.seller);
    
      } else {
        // Handle any errors or unsuccessful response
        alert('Image upload failed.');

      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error(error);
      alert('Image upload failed.');
    }
    setLoading(false);
  };

    
    
  
    return (
      <KeyboardAvoidingView>
      <SafeAreaView style={styles.androidSafeArea}>
 




   {/*menu login */}
 

    
   
   <ScrollView vertical={true} showsHorizontalScrollIndicator={false} style={styles.menuLogin}  >
    
   <View  style={styles.logoAndButtonContainer}>

{Seller.length>0? (  <View style={styles.userImageAndName}>

  {image && image.length>0? (<Image source={{ uri: image }} style={{ width: 70, height: 70, borderRadius:70, marginBottom:10}} />):
  (<Image style={styles.userImage} source={{ uri: `https://tunizoon.com/${Seller[0].image}` }}
  />)}


<Text style={styles.userName}>{Seller[0].name}</Text></View>):(<Pressable style={styles.BackButton} onPress={toggle}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>)}


    <Pressable  onPress={toggle}  style={styles.loginButton}><Text>
      <Feather name="menu" size={40} color="black" />
      </Text></Pressable>

</View>

{Seller.length>0?
(

<View>

<View style={styles.menuLoginButtonsContainer}>

<Pressable style={styles.menuLoginButtonsScrollView} onPress={pickImage}>
        <Text style={styles.menuLoginButtonsText}>changer ma photo</Text></Pressable>
        <Pressable style={styles.menuLoginButtonsScrollView} onPress={() => {
triggerHapticFeedback();
toggle();
          Customnavigation.navigate('sellerOrdersAll', {propName:Seller});}} >
  <Text  style={styles.menuLoginButtonsText}>mes commandes</Text>
  </Pressable>
<Pressable style={styles.menuLoginButtonsScrollView} onPress={handleLogout} >
  <Text  style={styles.menuLoginButtonsText}>logout</Text>
  </Pressable>

      
    </View>










    {/**bank account update view */}
    <View>


    </View>
   {image && image.length>0? (
    <View style={styles.photoUpdateViewMEnuLogin}>
   <Pressable style={styles.menuLoginButtonsScrollViewred} onPress={DeleteImage}>
        <Text style={styles.menuLoginButtonsText}> <Ionicons name="md-close" size={30} color="white" /> </Text></Pressable>
      <Image source={{ uri: image }} style={{ width: 300, height: 300, alignSelf:"center"}} />
 
  <Pressable style={styles.menuLoginButtonsScrollViewgreen} onPress={handleUploadImage }>
      <Text style={styles.menuLoginButtonsText}><AntDesign name="upload" size={30} color="white" /> </Text></Pressable>    
        </View>
        ):(null)} 


        {/**verifivationView */}
{Seller[0].seller_personal_security_check!=="done"?(
<View>

<Typewriter text={"La vérification du compte du vendeur n'a pas encore été effectuée. Pour pouvoir vendre sans aucune restriction, veuillez procéder à la vérification. Ab3ath un message au numéro de service de la vérification (+491724913762) sur WhatsApp, taw l'agent yfahmek chnouwa lezem w marhbe bik \u{1F60A}"}
delay={10} color={"black"}/>

    </View>):null
}
{/**bank account adding vieew */}
{!Seller[0].seller_bank_account &&!updated?(
<View>

<Typewriter text={"T7eb tbi3 w te5dem bel behi, zid coordonnées bancaire mte3ek bech client ynajem y5alles zeda bil carte w virement min compte el compte, juste ektebhom louta w enzel 3la Ajouter un compte bancaire w rabbi yjib el 9asm \u{1F60B}"}
delay={10} color={"black"}/>




<Text style={styles.Label}>Le nom de l'institution bancaire</Text>
   <TextInput
   


              style={styles.inputText}
              placeholder="La poste, BNA, BT, Tijari..."
              onChangeText={handleBankInstitutionChange}
              value={BankInstitution}
   />


<Text style={styles.Label}>Le numéro du compte RIB ou IBAN</Text>
   <TextInput
   


              style={styles.inputText}
              placeholder="Votre RIB"
              onChangeText={handleRibChange}
              value={Rib}
   />



{everythingOKRib&&everythingOKRibBankInstitution? (<Pressable  style={styles.insertDataButtonSuccess} onPress={updateBankinData}>
   <Text style={styles.insertDataButtonTextSuccess}>AJouter mon compte bancaire</Text>
  </Pressable>
  ):(<Pressable  style={styles.insertDataButtonFail}>
    <Text style={styles.insertDataButtonTextFail}>AJouter mon compte bancaire</Text>
   </Pressable>)}





    </View>):null
}
    </View>
  
    
    
    )
:(
    <View style={styles.menuLoginButtonsContainer}>

<Pressable style={styles.menuLoginButtons}   onPress={() => {
   
 triggerHapticFeedback();
    Customnavigation.navigate('loginSeller', { propsName: "login" });
    }}
  >
  <Text  style={styles.menuLoginButtonsText}>login</Text>
  </Pressable>

  <Pressable style={styles.menuLoginButtons} onPress={() => {
   
  triggerHapticFeedback();
    Customnavigation.navigate('sellerRegister', {propsName:"register"})}} >
  <Text  style={styles.menuLoginButtonsText}>cr&#233;er un compte</Text>
  </Pressable>

    </View>)}

<View style={{height:400, width:"100%"}}></View>
    </ScrollView>












  





    {loading?(<View style={styles.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}


          {/*error and loding handling*/}

          {Success?(
 <View style={styles.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }
{Error? (
  <View style={styles.ErrorView}>
 <Typewriter text={Error} 
delay={10} />
    <TouchableOpacity onPress={unsetError} style={styles.ErrorViewButton}>
      <Text style={styles.ErrorViewText}>OK</Text>
    </TouchableOpacity>

  </View>
) : null}

      </SafeAreaView>
      </KeyboardAvoidingView>
    );
  };
  const styles = StyleSheet.create({
    androidSafeArea: { paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "rgba(0,0,0,0.5)" },
  //menu login

  logoAndButtonContainer:{
    flex:1,
    flexDirection:"row",
    
    backgroundColor:"#00BFFF",
    height:"auto",
    paddingVertical:20,
    width:"100%",
    justifyContent:"space-between"
  
    },


    loginButton :{
 
        justifyContent: 'center',
        alignItems: 'center',
      
      backgroundColor:"#00BFFF",
      maxWidth:50,
      height:50,
      
      
      marginRight:20,
      marginBottom:10,
      marginTop:10,
      
      
      
      
      
      
      
      },



      menuLogin:{
        height:Platform.OS==="web"?"100vh":"100%",
        minHeight:Platform.OS==="web"?"100vh":"100%",
        width:Platform.OS==="web"?"100vw":"100%",
        maxWidth:Platform.OS==="web"?450:"100%",

        backgroundColor:"white",
        marginRight:0,marginLeft:"auto"
      },



  menuLoginButtonsContainer:{
    flex:1,flexDirection:"row",  justifyContent:"space-between", alignItems:"center" ,backgroundColor: "white" 
    
      },
      menuLoginButtonsContainerScrollView:{},
    
      photoUpdateViewMEnuLogin:{
        marginTop:0,width:300, height:"auto",alignSelf:"center",
         borderRadius:10, overflow:"hidden",paddingTop:10,marginTop:20
      },
    
      menuLoginButtonsScrollView:{
        backgroundColor:"#0059ba", height:40, alignItems:"center",
        width:"auto", borderRadius:4, justifyContent:"center",marginVertical:10,paddingHorizontal:10, marginHorizontal:3
      },
      menuLoginButtonsScrollViewred:{
        backgroundColor:"rgba(0, 0, 0, 0.5)", height:60, alignItems:"center",
        width:60,  justifyContent:"center",zIndex:2,marginBottom:-70,borderRadius:60,marginLeft:230,
     
      },
      menuLoginButtonsScrollViewgreen:{
        backgroundColor:"#5cb85c", height:50, alignItems:"center",
        width:300, justifyContent:"center",zIndex:2,
     
      },
    
    
    
      menuLoginButtons:{
        backgroundColor:"#0059ba", height:40, alignItems:"center",
        margin:10, width:150, borderRadius:4, justifyContent:"center"
     
      },
      menuLoginButtonsText:{
    
    color:"white", fontWeight:"bold" 
      },
      userImageAndName:{
     flexDirection:"column",paddingHorizontal:20
    
    
      },
      userImage:{
        height:70, width:70,marginBottom:10, borderRadius:70
      },
      userName:{
    fontWeight:"bold",
    fontSize:18, alignSelf:"center"
      },



      BackButton:{

        height:50, width:50, backgroundColor:"#00BFFF", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
      
       
      },

      guthabenView:{  paddingVertical:20,},
      guthabenViewText:{fontWeight:"bold", fontSize:16, color:"grey", alignSelf:"center"},
      inputText:{
  
        backgroundColor:"white", height:50, width:"90%", marginLeft:"5%", marginRight:"5%", borderWidth:2,borderColor:"rgba(0, 0, 0, 0.4)", paddingLeft:10
        
            },
            Label:{
      
              marginLeft:"5%", marginBottom:15, marginTop:15, fontSize:16, fontWeight:"bold"
          },


           //error and loading view
 LoadingView:{
  position:Platform.OS==="web"?"fixed":"absolute", top:0,left:0,height:Platform.OS==="web"?"100vh":"100%",width:"100%", zIndex:99,
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",width:"100%", backgroundColor:"#DE2C2C", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
SuccessView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",width:"100%", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},


 //button to update banking data
 insertDataButtonSuccess:{width:"90%", marginHorizontal:"5%", height:50, borderRadius:4, justifyContent:"center", alignItems:"center",marginTop:20, backgroundColor:"green"},
insertDataButtonTextSuccess:{fontWeight:"bold", color:"white"},
insertDataButtonFail:{opacity:0.5,width:"90%", marginHorizontal:"5%", height:50, borderRadius:4, justifyContent:"center", alignItems:"center",marginTop:20, backgroundColor:"grey"},
insertDataButtonTextFail:{fontWeight:"bold", color:"white"},

  });
  
export default  MenuLoginForSeller;
