import React from 'react';

import { NavigationContainer } from '@react-navigation/native';
import StackNavigator from './stack';
import HomeScreen from './HomeScreen';
import { MyContextProvider } from './MyContext';
import { registerRootComponent } from 'expo';

const App = () => {
  return (

    <MyContextProvider>
      <NavigationContainer>
        <StackNavigator />
      </NavigationContainer>
    </MyContextProvider>

  );
};


export default App;
