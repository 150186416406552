import React, { useState, useEffect,useRef} from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity,Pressable,Image, Button, ScrollView, SafeAreaView} from 'react-native';
import { AntDesign } from '@expo/vector-icons'; 
import Ionicons from '@expo/vector-icons/Ionicons';
import { Feather } from '@expo/vector-icons'; 
import { useMyContext } from './MyContext';



const AutocompleteCity= ({ onCitySelected}) => {
  const{User, setUser}= useMyContext({});


 //check only letters and numbers in the input
 const onlyLettersAndSomeChars = (str) => {
  return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s\-.,?!]*$/.test(str);
};



  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [userInput, setUserInput] = useState('');


  const [suggestion, setSuggestion] = useState([]);

const CityInputRef = useRef();
useEffect(() => {

  if(User&&User.length>0){
setUserInput(User[0].city);

  }

}, [User]);



const suggestionsArray=[

"Ariana",
"Ariana, Ettadhamen-Mnihla",
"Ariana, Kalâat el-Andalous",
"Ariana, Raoued",
"Ariana, Sidi Thabet",
"Ariana, La Soukra",


"Beja",
"Beja, El Maagoula",
"Beja, Goubellat",
"Beja, Medjez el-Bab",
"Beja, Teboursouk",
"Beja, Zahret Mediou",



"Ben Arous",
"Ben Arous, Bou Mhel el-Bassatine",
"Ben Arous, El Mourouj",
"Ben Arous, Ezzahra",
"Ben Arous, Hammam Chatt",
"Ben Arous, Hammam Lif",
"Ben Arous, Khalidia",
"Ben Arous, Megrine",
"Ben Arous, Mohamedia-Fouchana",
"Ben Arous, Mornag",
"Ben Arous, Rades",


"Bizerte",
"Bizerte, Aousja",
"Bizerte, El Alia",
"Bizerte, Ghar El Melh",
"Bizerte, Mateur",
"Bizerte, Menzel Bourguiba",
"Bizerte, Menzel Jemil",
"Bizerte, Menzel Abderrahmane",
"Bizerte, Metline",
"Bizerte, Raf Raf",
"Bizerte, Ras Jebel",
"Bizerte, Sejenane",
"Bizerte, Tinja",

"Gabès",
"Gabès, Oasis de Gabes",
"Gabès, Chenini Nahal",
"Gabès, El Hamma",
"Gabès, Gabès",
"Gabès, Ghannouch",
"Gabès, Mareth",
"Gabès, Matmata",
"Gabès, Metouia",
"Gabès, Nouvelle Matmata",
"Gabès, Oudhref",
"Gabès, Zarat",
 
 "Gafsa",
"Gafsa, El Guettar",
"Gafsa, El Ksar",
"Gafsa, Mdhila",
"Gafsa, Mtilaoui",
"Gafsa, Moularès",
"Gafsa, Redeyef",
"Gafsa, Sened",
 
 "Jendouba",
"Jendouba, Aïn Draham",
"Jendouba, Beni M'Tir",
"Jendouba, Bou Salem",
"Jendouba, Fernana",
"Jendouba, Ghardimaou",
"Jendouba, Jendouba",
"Jendouba, Oued Melliz",
"Jendouba, Tabarka",
 
 "Kairouan",
 "Kairouan, Aïn Djeloula",
"Kairouan, Alaâ",
"Kairouan, Bou Hajla",
"Kairouan, Chebika",
"Kairouan, Echrarda",
"Kairouan, Oueslatia",
"Kairouan, Haffouz",
"Kairouan, Hajeb El Ayoun",
"Kairouan, Kairouan",
"Kairouan, Menzel Mehiri",
"Kairouan, Nasrallah",
"Kairouan, Sbikha",



 "Kasserine",

"Kasserine, Fériana",
"Kasserine, Foussana",
"Kasserine, Haïdra",
"Kasserine, Jedelienne",
"Kasserine, Magel Bel Abbés",
"Kasserine, Sbeïtla",
"Kasserine, Sbiba",
"Kasserine, Thala",
"Kasserine, Thelepte",

 "Kébili",
"Kébili, Douz",
"Kébili, El Golâa",
"Kébili, Jemna",
"Kébili, Souk Lahad",


  "Kef",
"Kef, Dahmani",
"Kef, Jérissa",
"Kef, El Ksour",
"Kef, Kalaat Senan",
"Kef, Kalâat Khasba",
"Kef, Le Kef",
"Kef, Menzel Salem",
"Kef, Nebeur",
"Kef, Sakiet Sidi Youssef",
"Kef, Sers",
"Kef, Tajerouine",
"Kef, Touiref",
 

"Mahdia",

"Mahdia, Bou Merdes",
"Mahdia, Chebba",
"Mahdia, Chorbane",
"Mahdia, El Bradâa",
"Mahdia, El Jem",
"Mahdia, Essouassi",
"Mahdia, Hebira",
"Mahdia, Hiboun",
"Mahdia, Kerker",
"Mahdia, Ksour Essef",
"Mahdia, Mahdia",
"Mahdia, Melloulche",
"Mahdia, Ouled Chamekh",
"Mahdia, Rejiche",
"Mahdia, Sidi Alouane",
 

  "Manouba",
"Manouba, Borj El Amri",
"Manouba, Den Den",
"Manouba, Douar Hicher",
"Manouba, Djedeida",
"Manouba, El Batan",
"Manouba, Oued Ellil",
"Manouba, La Manouba",
"Manouba, Mornaguia",
"Manouba, Tebourba",


 "Medenine",
"Medenine, Ghorfas de Médenine",
"Medenine, Ajim",
"Medenine, Ben Gardane",
"Medenine, Beni Khedache",
"Medenine, Houmt Souk",
"Medenine, Midoun",
"Medenine, Zarzis",

  "Monastir",
"Monastir, Amiret El Fhoul",
"Monastir, Amiret El Hojjaj",
"Monastir, Amiret Touazra",
"Monastir, Bekalta",
"Monastir, Bembla",
"Monastir, Beni Hassen",
"Monastir, Bennane",
"Monastir, Bouhjar",
"Monastir, Cherahil",
"Monastir, El Masdour",
"Monastir, Gherada",
"Monastir, Jemmal",
"Monastir, Khniss",
"Monastir, Ksar Hellal",
"Monastir, Ksibet el-Médiouni",
"Monastir, Lamta",
"Monastir, Menzel Ennour",
"Monastir, Menzel Farsi",
"Monastir, Menzel Hayet",
"Monastir, Menzel Kamel",
"Monastir, Moknine",
"Monastir, Monastir",
"Monastir, Ouerdanine",
"Monastir, Sahline Moôtmar",
"Monastir, Sayada",
"Monastir, Sidi Ameur",
"Monastir, Sidi Bennour",
"Monastir, Téboulba",
"Monastir, Touza",
"Monastir, Zaramdine",
"Monastir, Zaouiet Kontoch",


    "Nabeul",

"Nabeul, Rue de Nabeul",
"Nabeul, Azmour",
"Nabeul, Béni Khalled",
"Nabeul, Béni Khiar",
"Nabeul, Bou Argoub",
"Nabeul, Dar Allouch",
"Nabeul, Dar Chaabane",
"Nabeul, El Haouaria",
"Nabeul, El Maâmoura",
"Nabeul, El Mida",
"Nabeul, Hammam Ghezéze",
"Nabeul, Hammamet",
"Nabeul, Kerkouane",
"Nabeul, Korba",
"Nabeul, Korbous",
"Nabeul, Menzel Bouzelfa",
"Nabeul, Menzel Horr",
"Nabeul, Menzel Temime",
"Nabeul, Soliman",
"Nabeul, Somâa",
"Nabeul, Takelsa",
"Nabeul, Tazerka",
"Nabeul, Zaouiet Djedidi",
 


 "Sfax",
"Sfax, Agareb",
"Sfax, Bir Ali Ben Khélifa",
"Sfax, Bir Salah",
"Sfax, Chihia",
"Sfax, El Aïn",
"Sfax, Graïba",
"Sfax, Gremda",
"Sfax, Jbeniana",
"Sfax, Kerkennah",
"Sfax, El Hencha",
"Sfax, Mahrés",
"Sfax, Menzel Chaker",
"Sfax, Sakiet Eddaïer",
"Sfax, Sakiet Ezzit",
"Sfax, Skhira",
"Sfax, Thyna",
 

 "Sidi Bouzid",
"Sidi Bouzid, Bir El Hafey",
"Sidi Bouzid, Cebbala Ouled Asker",
"Sidi Bouzid, Jilma",
"Sidi Bouzid, Menzel Bouzaiane",
"Sidi Bouzid, Meknassy",
"Sidi Bouzid, Mezzouna",
"Sidi Bouzid, Ouled Haffouz",
"Sidi Bouzid, Regueb",
"Sidi Bouzid, Sidi Ali Ben Aoun",
"Sidi Bouzid, Sidi Bouzid",
 

 "Siliana",
"Siliana, Bargou",
"Siliana, Bou Arada",
"Siliana, El Aroussa",
"Siliana, El Krib",
"Siliana, Gaâfour",
"Siliana, Kesra",
"Siliana, Makthar",
"Siliana, Rouhia",
"Siliana, Sidi Bou Rouis",
"Siliana, Siliana",
 

 "Sousse",
"Sousse, Akouda",
"Sousse, Bouficha",
"Sousse, Enfida",
"Sousse, Ezzouhour",
"Sousse, Hammam Sousse",
"Sousse, Hergla",
"Sousse, Kalâa Kebira",
"Sousse, Kalâa Seghira",
"Sousse, Kondar",
"Sousse, Ksibet Thrayet",
"Sousse, Messaadine",
"Sousse, M'saken",
"Sousse, Sidi Bou Ali",
"Sousse, Sidi El Hani",
"Sousse, Zaouiet Sousse",
 

 "Tataouine",
"Tataouine, Bir Lahmar",
"Tataouine, Dehiba",
"Tataouine, Ghomrassen",
"Tataouine, Remada",

 

 "Tozeur",
"Tozeur, Degache",
"Tozeur, El Hamma du Jérid",
"Tozeur, Nefta",
"Tozeur, Tamerza",

 


 "Tunis",
"Tunis, Carthage",
"Tunis, La Goulette",
"Tunis, La Marsa",
"Tunis, Le Bardo",
"Tunis, Le Kram",
"Tunis, Sidi Bou Saïd",
"Tunis, Sidi Hassine",

 
 "Zaghouan",
"Zaghouan, El Fahs",
"Zaghouan, Djebel Oust",
"Zaghouan, Bir Mcherga",
"Zaghouan, Nadhour",
"Zaghouan, Zaghouan",
"Zaghouan, Zriba"];

 
    // set user city if user is connected, and the suggession array

useEffect(() => {

setSuggestion(suggestionsArray);
if(User && Object.entries(User).length !== 0){
setUserInput(User[0].city);
 }
  }, []);
  
















//handle input change search field 
const handleInputChange = (text) => {
  if (onlyLettersAndSomeChars(text)){
  
   CityInputRef.current.setNativeProps({
    
      style: {
        borderColor:"#5cb85c"
      }
    });
   
    onCitySelected(text); 
  }
  else{
    CityInputRef.current.setNativeProps({
    
      style: {
        borderColor:"red"
      }
    });

  
    onCitySelected(0); 

  }
  const filtered = suggestionsArray.filter((item) =>
  item.toUpperCase().includes(text.toUpperCase())
).slice(0, 5);
  
    setFilteredSuggestions(filtered);
    setUserInput(text);
  };
    
    //set the sugesstions values 
    const selectSuggestion = (text) => {
      setUserInput(text);
      onCitySelected(text); 
      setFilteredSuggestions([]);
    };


  return (
    <View style={styles.containerSearchCity}>
            {/*
  the head container
 */}
 
    <View style={styles.headContainer}>

  
{/*
the container for input and button to search
*/}
 <View style={styles.headContainerInputAndSearch}>
  
 <TextInput ref={CityInputRef} style={styles.textSearchInputCity}
        onChangeText={handleInputChange}
        value={userInput}
        placeholder={"Votre Ville, municipalité"}
      />


</View>

    </View>


    {filteredSuggestions.length > 0 && (
        <View style={styles.sugestionsBoxCity}>
          {filteredSuggestions.map((suggestion, index) => (
            <TouchableOpacity
            style={styles.sugesstionButtonsCity}
              key={index}
              onPress={() => selectSuggestion(suggestion)}
            >
              <Text>{suggestion}</Text>
            </TouchableOpacity>
          ))}
        </View>
      )}
   


    </View>
   





    
  
 

  );
};

const styles = StyleSheet.create({
    containerSearchCity:{width:"100%",
position:"relative",zIndex:1,

},
    textSearchInputCity:{

        backgroundColor:"white", height:50, width:"90%", marginLeft:"5%",marginRight:"5%",
      borderWidth:2,borderColor:"rgba(0, 0, 0, 0.4)", borderRadius:4,paddingLeft:10

    },
    sugestionsBoxCity:{width:"100%", position:"absolute", top:50,},
    sugesstionButtonsCity:{width:"90%",height:50,marginLeft:"5%",marginRight:"5%",backgroundColor:"white",   
      borderWidth:2,borderColor:"rgba(0, 0, 0, 0.4)", borderRadius:4, paddingLeft:10,paddingLeft:10, justifyContent:"center"
},
});

export default AutocompleteCity;
