import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { Image,ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView, Vibration,Platform, TouchableOpacity} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { Fontisto } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { Keyboard } from 'react-native';
import { LoadingView} from '../myComponent';
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMyContext } from '../MyContext';
import * as Haptics from 'expo-haptics';
import Typewriter from "../typeWriter";

import axios from 'axios';
import { stylesComponent } from '../myComponent';
const AdminOrders = ({ navigation, route }) => {
//context trigger
const[contextTrigger,setcontextTrigger]=useState(false);
//show more orders
const[showMore,setshowMore]=useState(5);
const [showDetailOrderId,setshowDetailOrderId]=useState('');

  //trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}


  //loading and errors
  const [ Loading, setLoading]=useState(false);
  const [ Error, setError]=useState('');
const[Success,setSuccess]=useState('');




//admin is logged in
const{Admin, setAdmin}= useMyContext({});
const{AdminIsLoggedIn, setAdminIsLoggedIn}=useMyContext();
//orders data
    const{Orders, setOrders}=useMyContext();
    const{adminOrderIdData, setadminOrderIdData}=useMyContext();

    const{adminOrderIdDataKopie, setadminOrderIdDataKopie}=useMyContext();
//sorting by

//sorted by
const [SortedBy, setSortedBy]=useState('');

const SortByDateAsd = (sortedByWhat) => {
  setLoading(true);
  triggerHapticFeedback();

  setTimeout(() => {
    const sortedData = [...adminOrderIdDataKopie].sort((a, b) => {
      const dateA = new Date(a.datum); 
      const dateB = new Date(b.datum); 

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });

    // Update the state or variable holding the sorted data
    setadminOrderIdData(sortedData);
    setSortedBy(sortedByWhat);
    setLoading(false);
  }, 100); // 2000 milliseconds (2 seconds)
};



const SortByDateDsd=(sortedByWhat)=>{
  setLoading(true);
  triggerHapticFeedback();
  setLoading(true);
  triggerHapticFeedback();

  setTimeout(() => {
    const sortedData = [...adminOrderIdDataKopie].sort((a, b) => {
      const dateA = new Date(a.datum); 
      const dateB = new Date(b.datum); 

      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });

    // Update the state or variable holding the sorted data
    setadminOrderIdData(sortedData);
    setLoading(false);
    setSortedBy(sortedByWhat);
  }, 100); // 2000 milliseconds (2 seconds)

};

//findConfirmed
const findConfirmed = (sortedByWhat) => {
  setLoading(true);
  triggerHapticFeedback();

 

  setTimeout(() => {
    const filteredData = adminOrderIdDataKopie.filter(Order => Order.Status === "confirmed");

    // Update the state or variable holding the filtered data
    setadminOrderIdData(filteredData);
    setLoading(false);
    setSortedBy(sortedByWhat);
  }, 100); // 2000 milliseconds (2 seconds)
};

//findNotConfirmed
const findNotConfirmed=(sortedByWhat)=>{
  setLoading(true);
  triggerHapticFeedback();

  

  setTimeout(() => {
    const filteredData = adminOrderIdDataKopie.filter(Order => Order.Status === "not confirmed");

    // Update the state or variable holding the filtered data
    setadminOrderIdData(filteredData);
    setLoading(false);
    setSortedBy(sortedByWhat);
  }, 100); // 2000 milliseconds (2 seconds)

}
//findWithRetour
const findWithRetour=(sortedByWhat)=>{
  triggerHapticFeedback();
  setLoading(true);

setTimeout(() => {
  const filteredData = adminOrderIdDataKopie.filter(Order => Order.sendbackask=== "1");

  // Update the state or variable holding the filtered data
  setadminOrderIdData(filteredData);
 setLoading(false);
 setSortedBy(sortedByWhat);
}, 100); // 2000 milliseconds (2 seconds)
}

//for the number search
const SearchByNumberRef = useRef(null);



const[NumberInput, setNumberInput]=useState();

 //check only numbers in the search input
 const onlyNumber = (str) => {
  return /^[0-9]+$/.test(str);
};
const[NumberInputOk, setNumberInputOk]=useState(true);
      //handle number change if not empty

      const handleNumberChange= (Number) => {
    


        if (onlyNumber(Number)) {
          setNumberInput(Number);
        
          SearchByNumberRef.current.setNativeProps({
        
            style: {
              borderColor:"#5cb85c",borderWidth:2,
            }
          });
          setNumberInputOk(true);
          setError("");
        }
        else
        {
   
          setNumberInput(Number);
          SearchByNumberRef.current.setNativeProps({
        
            style: {
              borderColor:"red",borderWidth:2
            }
          });
          setNumberInputOk(false);
          if(Number.length>0){
          setError("le champs ne peut accepter que des nombres \u{1F922}");}
        }

        if(Number.length===0){
          setNumberInput(Number);
          SearchByNumberRef.current.setNativeProps({
        
            style: {
              borderColor:"transparent",borderWidth:2
            }
          });
          setNumberInputOk(true);
          setError("");
        }

      

      };
//find with number 
const FindByNumber=()=>{
  if(NumberInput!==""&&onlyNumber(NumberInput)){
 setLoading(true);
  triggerHapticFeedback();
  setTimeout(() => {
  const filteredData = adminOrderIdDataKopie.filter(Order => Order.order_id=== NumberInput);

  // Update the state or variable holding the filtered data
  setadminOrderIdData(filteredData);
  setLoading(false);
 }, 200); // 2000 milliseconds (2 seconds)
}
else {
  setError("Le nombre ne peut pas être vide et doit contenir uniquement des chiffres \u{1F92C} ");
}
}




//insert payed

const insertPayed=(order_id)=>{

  setLoading(true);
    triggerHapticFeedback();

    const Order_id = order_id ;

  
    axios.post('https://tunizoon.com/appgate/adminOrders.php?action=insertPayed', {
      Order_id,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(response => {
      const data = response.data;


      if (data.hasOwnProperty('error')) {
        
       setError(data.error);
      }

      if (data.hasOwnProperty('message')) {

        
        setcontextTrigger(!contextTrigger);
        setTimeout(() => {
          setSuccess(data.message);
        }, 500);

      }
 
 
      setLoading(false);

    })
    .catch(error => {
      setLoading(false);
      setError(error.message);
    });


}



//function to refetch
const  myRefetchDataFunction = () => {


  const admin_mail=Admin[0].email;
  const Admin_password=Admin[0].passwort;


  axios.post('https://tunizoon.com/appgate/AdminHomePage.php?action=FetchAll', `Admin_mail=${admin_mail}&Admin_password=${Admin_password}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
     
          .then((response) => {
            const json = response.data;
            setOrders(json.orders);
            setadminOrderIdData(json.ordersIdData);
            setadminOrderIdDataKopie(json.ordersIdData);
  
       
          })
          .catch((error) => {
            console.error(error);
          });
        };

        //use effect to call fetch
        useEffect(() => {
          myRefetchDataFunction();


      }, [contextTrigger]);



//show detail order
const showdetail=(order_id)=>{

  if(showDetailOrderId!==order_id){
  setshowDetailOrderId(order_id);

}
else{
  setshowDetailOrderId(''); 
}
}






const calculateWeight = (weight) => {
  weight = parseFloat(weight); // Convert to a floating-point number

  let unit = "g"; // Default unit is grams

  if (weight >= 1000) {
    weight /= 1000; // Convert to kilograms
    unit = "kg"; // Update the unit to kilograms
  }

  const formattedWeight = Number(weight.toFixed(2));
  return `${formattedWeight} ${unit}`;
};












    return (

<SafeAreaView style={styles.androidSafeArea}>






<ScrollView style={styles.Container}>
  {/*the container for the logo and the button to go to the admin Homepage*/}
  <View  style={styles.logoAndButtonContainer}>
  <Pressable style={styles.BackButton} onPress={() => {navigation.navigate('adminHomePage');triggerHapticFeedback();}}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
</View>


{/**sorting by */}


<View>
<View style={styles.sortByMenu}>
<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.sortByScrollView}>


<View ref={SearchByNumberRef}  style={ styles.searchByNumberView}>

<TextInput
   
 style={styles.inputTextSearchByNumber}
              placeholder="Chercher par numéro"
              onChangeText={handleNumberChange}
              value={NumberInput}
   />
{NumberInputOk?(
   <Pressable onPress={FindByNumber} style={styles.searchNumberButton}><Text style={styles.searchNumberButtonText} > <AntDesign name="search1" size={18} color="black" /></Text></Pressable>):(
     <Pressable style={styles.searchNumberButton}><Text style={styles.searchNumberButtonText}> <AntDesign name="search1" size={18} color="red" /></Text></Pressable>
     )}
  </View>

  <Pressable  onPress={()=>{SortByDateDsd("dateDsd")}}   
  
  style={() => [  {backgroundColor:SortedBy==="dateDsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
 
  <Text style={styles.TextButtonSortBy}>plus r&#233;cente  <Fontisto name="date" size={18} color="black" /></Text>
  </Pressable>
  <Pressable  onPress={()=>{SortByDateAsd("dateAsd")}}   
 style={() => [  {backgroundColor:SortedBy==="dateAsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>plus anciennes  <Fontisto name="date" size={18} color="black" /></Text>
  </Pressable>
  
  <Pressable   onPress={()=>{findConfirmed("confirmed")}}  
   style={() => [  {backgroundColor:SortedBy==="confirmed"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>commande payée  <AntDesign name="checkcircleo" size={18} color="black" /></Text>
  </Pressable>

  <Pressable    onPress={()=>{findNotConfirmed("notConfirmed")}}  
   style={() => [  {backgroundColor:SortedBy==="notConfirmed"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>commande pas payée  <AntDesign name="exclamationcircleo" size={18} color="black" /></Text>
  </Pressable>
  <Pressable  onPress={()=>{findWithRetour("withRetour")}}  
   style={() => [  {backgroundColor:SortedBy==="withRetour"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>commande avec retour  <Octicons name="package-dependencies" size={18} color="black" /></Text>
  </Pressable>
</ScrollView>


</View>
</View>







{adminOrderIdData.length>0&&adminOrderIdData.slice(0, showMore).map((item,index)=>(



<View key={index} style={styles.OrderView}>
<View style={styles.OrderViewInfoHead}>

<Text style={styles.textBoldBlack}> Nr- {item.order_id}</Text>
<Text style={styles.textBoldBlack}> <Fontisto name="date" size={16} color="black" /> {item.datum}</Text>
<Text style={styles.textBoldBlack}> <Feather name="watch" size={16} color="black" /> {item.uhrzeit}</Text>
</View>


<View style={styles.OrderViewInfo}>

<ScrollView  style={styles.ScrollViewImage} horizontal={true} showsHorizontalScrollIndicator={false} >
{Orders.length>0&&Orders.map((order,key)=>(
order.order_id===item.order_id?(

<Image key={key} source={{ uri: `https://tunizoon.com/${order.product_image}` }} style={styles.OrdersImages} />






):null

))}

</ScrollView>

<TouchableOpacity onPress={()=>{showdetail(item.order_id);}} style={styles.voirDetails}>
  <Text style={styles.voirDetailsText}>{showDetailOrderId===item.order_id?"voir moin détails":"voir plus de détails"}</Text></TouchableOpacity>


{showDetailOrderId===item.order_id?(


<View style={styles.ubersichtView}>

<Text style={styles.textBoldGreen}>@ infos commande</Text>

<Text><Text style={styles.textBoldBlack}>- Statut:</Text> {item.Status==="not confirmed"?"pas payée":"payée"}</Text>
<Text><Text style={styles.textBoldBlack}>- Methode de paiment:</Text> {item.payement_method}</Text>
<Text><Text style={styles.textBoldBlack}>- Poids:</Text> {calculateWeight(item.total_order_weight)}</Text>
<Text><Text style={styles.textBoldBlack}>- Produits:</Text> {(parseFloat(item.order_price)).toFixed(2)} Tnd</Text>
<Text><Text style={styles.textBoldBlack}>- Livraison:</Text> {(parseFloat(item.delivery_price)).toFixed(2)} Tnd</Text>
<Text><Text style={styles.textBoldBlack}>- Total a payer:</Text> <Text style={styles.textBoldRed}>{((parseFloat(item.order_price))+(parseFloat(item.delivery_price))).toFixed(2)} Tnd</Text></Text>




{item.sku_sellers && item.sku_sellers.map((seller, num) => (
<View  key={num}>

<Text style={styles.textBoldGreen}># Vendeur <Text style={{fontWeight:"normal"}}>{num+1}/{item.sku_sellers.length}</Text></Text>


  <Text><Text style={styles.textBoldBlack}>- Nom: </Text> {seller[0]?.name}</Text>
  <Text><Text style={styles.textBoldBlack}>- Code unique: </Text> {seller[0]?.sku_seller}</Text>
  <Text><Text style={styles.textBoldBlack}>- Tel: </Text>{seller[0]?.tel}</Text>
  <Text><Text style={styles.textBoldBlack}>- Email: </Text>{seller[0]?.email}</Text>

</View>

))}


<Text style={styles.textBoldGreen}># Client: </Text>
<Text><Text style={styles.textBoldBlack}>- Nom:</Text> {item.user_name}</Text>
  <Text><Text style={styles.textBoldBlack}>- Code unique:</Text> {item.user_id}</Text>
  <Text><Text style={styles.textBoldBlack}>- Tel: </Text>{item.user_tel}</Text>
  <Text><Text style={styles.textBoldBlack}>- Email: </Text>{item.user_email}</Text>

  </View>):null}



</View>





{item.Status==="not confirmed"&&showDetailOrderId===item.order_id?(
<TouchableOpacity onPress={()=>{insertPayed(item.order_id)}}style={styles.MarkpayedButton}>
  <Text style={styles.textBoldWhite}>  Marquer payé le {new Date().toLocaleDateString()}
</Text>
</TouchableOpacity>):null

}



</View>

))

}



{adminOrderIdData.length>=showMore? (    <TouchableOpacity style={styles.showMoreButton}
        onPress={()=>{triggerHapticFeedback(); setshowMore((prevshowMore)=>prevshowMore+5);}}
        ><Text style={styles.showMoreButtonText}>voir plus</Text></TouchableOpacity>):
        null
        }
   
    </ScrollView>

{/*error and loading handling*/}
{Success?(    <View style={styles.SuccessView}>
  <Typewriter text={Success} color={"white"} 
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}
{Error?(    <View style={styles.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}


{Loading?(<LoadingView/>):null}

</SafeAreaView>

    )


};

const styles = StyleSheet.create({
    androidSafeArea: { height: "100%",paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#fcb103"},
    logoAndButtonContainer:{
        flex:1,
        flexDirection:"row",
        
        backgroundColor:"#fcb103",
        height:70,
        maxHeight:70,
        paddingVertical:10,
        width:"100%",
        justifyContent:"space-between"
        
        },

      BackButton:{

        height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center",
      
       
      },
    Container:{height:"100%", width:"100%",backgroundColor:"white"},
            //sortby menu


sortByMenu :{
  flex:1, flexDirection:"row",
  zIndex:-1,
height:50,
width:"100%",

backgroundColor:"red",



backgroundColor:"#fffbe6",
overflow:"hidden",marginBottom:10

},


ButtonSortByFirst:{
  height:50, width:"auto", paddingHorizontal:10,
   justifyContent:"center", alignItems:"center", 
  backgroundColor:"#fffbe6",   shadowOffset: { width: 1, height: 0 },
  shadowOpacity: 0.6,
  shadowRadius: 2,
  elevation: 0,
  
  
    },
    ButtonSortBy:{
      height:36, marginTop:7, marginBottom:7,  borderRadius:30, width:"auto", paddingHorizontal:10, 
      justifyContent:"center", alignItems:"center",  shadowColor: 'rgba(0, 0, 0, 0.8)',marginHorizontal:5,
      shadowOffset: { width: 2, height: 2 },
      shadowOpacity: 0.7,
      shadowRadius: 3,
      
    },
  
    TextButtonSortBy:{
      fontWeight:"bold", fontSize:13
    },
 //searchby number

 searchByNumberView:{
  backgroundColor:"white",flex:1, flexDirection:"row",height:36, marginTop:7, marginBottom:7,  borderRadius:30, width:"auto",paddingHorizontal:15,
  justifyContent:"center", alignItems:"center",  shadowColor: 'rgba(0, 0, 0, 0.8)',marginHorizontal:5,
  shadowOffset: { width: 2, height: 2 },
  shadowOpacity: 0.7,
  shadowRadius: 3,
  
  
      },
      inputTextSearchByNumber:{
        height:40,width:200, 
      },
      searchNumberButton:{
        height:36,width:30, backgroundColor:"white", justifyContent:"center",alignItems:"center"
      },
    //seller Vieew
    OrderView:{
    height:"auto", width:"94%", marginVertical:20,
    marginHorizontal:"3%", borderWidth:1, borderRadius:4,  
    overflow:"hidden",
},
OrdersImages:{
    height:50, width:50,borderRadius:4,marginLeft:10,

},
ScrollViewImage:{backgroundColor:"white",height:50,maxHeight:50},
OrderViewInfoHead:{width:"100%",flex:1,maxHeight:50, paddingHorizontal:10,paddingVertical:10,
flexDirection:"row",backgroundColor:"white", justifyContent:"space-between"},
OrderViewInfo:{
        padding:20,
    },
    ubersichtView:{backgroundColor:"white", marginTop:30,flex:1,flexDirection:"column"},
    textBoldGreen:{fontWeight:"bold",color:"green",marginBottom:5, marginTop:20,fontSize:16,},
    textBoldBlack:{fontWeight:"bold",color:"grey", fontSize:14, },
    textBoldWhite:{fontWeight:"bold",color:"white", fontSize:14, },
    textBoldRed:{fontWeight:"bold",color:"red", fontSize:14, },

    MarkpayedButton:{
width:"90%", marginHorizontal:"5%", height:40, 
 justifyContent:"center", alignItems:"center", backgroundColor:"#5cb85c",marginVertical:10, borderRadius:4,


    },
     //error and success view

SuccessView:{flex:1, flexDirection:"column",
     position:"absolute", top:0,left:0,height:"auto",width:"100%", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
   },
ErrorView:{flex:1, flexDirection:"column",
  position:"absolute", top:0,left:0,height:"auto",width:"100%", backgroundColor:"#DE2C2C", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,marginTop:10},
 showMoreButton:{height:40, width:100, backgroundColor:"#fcb103", justifyContent:"center", alignItems:"center", marginRight:"1%", marginLeft:"auto", borderRadius:10,},
 showMoreButtonText:{fontWeight:"bold", },
 voirDetails:{heigh:40, width:"auto", justifyContent:"center", alignItems:"center", marginLeft:"auto", marginRight:20},
 voirDetailsText:{fontWeight:"bold", color:"grey"}


});

export default AdminOrders;