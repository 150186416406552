import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { Image,ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView, Vibration,Platform, TouchableOpacity} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Keyboard } from 'react-native';
import { LoadingView} from '../myComponent';
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMyContext } from '../MyContext';
import * as Haptics from 'expo-haptics';
import Typewriter from "../typeWriter";
import axios from 'axios';
import { stylesComponent } from '../myComponent';
const AdminUsers = ({ navigation, route }) => {

    const{Users, setUsers}=useMyContext();

    return (

<SafeAreaView style={styles.androidSafeArea}>






<ScrollView style={styles.Container}>
  {/*the container for the logo and the button to go to the admin Homepage*/}
  <View  style={styles.logoAndButtonContainer}>
  <Pressable style={styles.BackButton} onPress={() => {navigation.navigate('adminHomePage') }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
</View>
{Users.length>0&&Users.map((item,index)=>(



<View key={index} style={styles.UsersView}>
<Image source={{ uri: `https://3oula.tn/${item.user_image}` }} style={styles.UserImage} />



<View style={styles.UsersViewInfo}>
<Text style={{fontWeight:"bold"}}> Nom: <Text style={{fontWeight:"normal"}}>{item.name}</Text></Text>
<Text style={{fontWeight:"bold"}}> tel: <Text style={{fontWeight:"normal"}}>{item.user_tel_number}</Text></Text>
<Text style={{fontWeight:"bold"}}> Email: <Text style={{fontWeight:"normal"}}>{item.email}</Text></Text>
<Text style={{fontWeight:"bold"}}> Ville: <Text style={{fontWeight:"normal"}}>{item.city}</Text></Text>
</View>
<TouchableOpacity style={styles.deleteButton}><AntDesign name="delete" size={24} color="white" /></TouchableOpacity>
</View>

))

}
    

    
    
    </ScrollView>



</SafeAreaView>

    )


};

const styles = StyleSheet.create({
    androidSafeArea: { height: "100%",paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#fcb103"},

    Container:{height:"100%", width:"100%",backgroundColor:"white"},
    logoAndButtonContainer:{
        flex:1,
        flexDirection:"row",
        
        backgroundColor:"#fcb103",
        height:70,
        maxHeight:70,
        paddingVertical:10,
        width:"100%",
        justifyContent:"space-between"
        
        },

        BackButton:{

            height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center",
          
           
          },
    //seller Vieew
UsersView:{
    height:120, width:"94%", marginVertical:20,
    marginHorizontal:"3%", borderWidth:1, borderRadius:4, flex:1, 
    flexDirection:"row",overflow:"hidden",
},
UserImage:{
    height:120, width:120,borderTopLeftRadius:4, borderBottomLeftRadius:4
},
    UsersViewInfo:{
        padding:20,
    },
  
    deleteButton:{
        height:"100%", width:40, 
        backgroundColor:"#DA291C",justifyContent:"center",alignItems:"center",borderTopRightRadius:4,borderBottomRightRadius:4,
        marginRight:0, marginLeft:"auto"
    }


});

export default AdminUsers;