import React, { useState, useEffect, useRef, useContext } from 'react';
import {Modal,ActivityIndicator , ScrollView, StyleSheet, View, Text, TextInput, Pressable, Image, Dimensions, Button, TouchableOpacity, Platform, Animated, KeyboardAvoidingView, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { Ionicons } from '@expo/vector-icons';
import * as Haptics from 'expo-haptics';

import MenuLoginUser from './menuLoginForUser';
import Cart from './cart';
import StarRatinginput from './starRatingInput';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMyContext } from './MyContext';
import { AntDesign } from '@expo/vector-icons';
import { LoadingView} from './myComponent';
import { Feather } from '@expo/vector-icons';
import Typewriter from "./typeWriter";

const OrderUser = ({ navigation, route }) => {

//user object 
const{User, setUser}= useMyContext({});
const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();
  //var to trigger a fetch for notification when back to homepage
  const {contextTrigger, setcontextTrigger}=useMyContext();
//if user not set throw the user out
  useEffect(() => {
    if(!UserIsLoggedIn){
        navigation.navigate('home');}
  }, [UserIsLoggedIn]);


  //var to control modal menu
  const [showMenu, setshowMenu] = useState(false);

//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};
  //const while loading
  const [loading, setLoading] = useState(false);
    //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
//contain product details
const {Orderdata} = route.params;
const [Order, setOrder]=useState();
const[Error, setError]=useState('');
const [Success, setSuccess]=useState('');

//menu login things
const ScrollViewRef = useRef(null);
const [showMenuRef, setshowMenuRef] = useState(false);

const [showScrollViewRef, setShowScrollViewRef] = useState(false);

const[paymentData, setpaymentData]=useState({});
  //function to fetch data from tunizoon.com
  const myFetchDataFunction= () => {

    const user_id=User[0].user_id;
    const key="10876767594ewjhefsds98799237498";
    const order_id = Orderdata.order_id;
    // fetch data when component is mounted
    fetch('https://tunizoon.com/appgate/userOrders.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:`order_id=${order_id}&Key=${key}&user_id=${user_id}`
    })
      .then((response) => response.json())
      .then((json) => {
     
        setOrder(json.Order); // set product data state
        if(json.OrderPayementData.Status==="not confirmed"){
setSuccess(`La commande n'est pas encore payée oun confirmée  ya ${User[0].name}, vous avez choisi le paiment par ${json.OrderPayementData.PayementMethod}. ahbet louta famma bouton enzel 3lih taw yhezek lil page mte3 paiment wil confirmation \u{1F607}.`);
setpaymentData(json.OrderPayementData);



        }
     
      })
     
      .catch((error) => {
        console.error(error);
        
      });
      
    };



  
     
     
 

  useEffect(() => {
  
    myFetchDataFunction();
   
  }, [contextTrigger]);

//check only letters and numbers in the input
const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9.,?!]*$/.test(str);
};


const [Input, setInput]=useState('');
const InputRef= useRef();











const [everyThingOkInput, seteveryThingOkInput]=useState(false);

//Input change
const handleInputChange= (input) => {
  setInput(input);
  if (onlyLettersAndNumbers(input)) {
 
    seteveryThingOkInput(true);
  }
  else
  {
   
    seteveryThingOkInput(false);

    if(input.length>0){
setError('le 3ad numéro mte3 el colis, ken 7ourouf a ar9am walla arja3 8odwa...\u{1F922}');

    }
  }
};



//insert sendbacknumber 
//the fetch function to insert comments
const AddSendBackNumber = (order_id, product_sku, product_name,product_image, price, Qty, email_seller, seller_name) => {
const input=Input;
let user_name=User[0].name;
  setLoading(true);
triggerHapticFeedback();
    fetch('https://tunizoon.com/appgate/userOrderActions.php?action=AddSendBackNumber', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
     body:`seller_name=${seller_name}&user_name=${user_name}&sendbacknumber=${input}&order_id=${order_id}&product_sku=${product_sku}&product_name=${product_name}&product_image=${product_image}&product_price=${price}&product_qty=${Qty}&email_seller=${email_seller}`,

    })
    .then((response) => response.json())
    .then((json) => {

      if (json.hasOwnProperty("error")) {
    setLoading(false);
   setError(json.error);
   
    } else {
      setLoading(false);
      myFetchDataFunction();
      setSuccess(`c bon t9ayed numéro mte3 el colis "${Input}", taw kif yousel vendeur ya3mel confirmation w touslek notification w email, Goul Floomy 5ayeb\u{1F602} `);
      setcontextTrigger(true);
     
    }

    })
   
    .catch((error) => {
      setLoading(false);

      console.error(error);
    });
  

  };
  



















//kill typewriter when navigateing
const killTypeWriter=()=>{


  setError();
  setSuccess();
  
}









// date formatting function
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}
// Function that construct the stars for rating 
function StarRating({ score }) {
  const filledStars = Array(parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`filled-${index}`} name="star" size={14} color="#fcb103" />
  ));
  const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`empty-${index}`} name="star" size={14} color="grey" />
  ));
  const stars = [...filledStars, ...emptyStars];
  return <Text>{stars}</Text>;
}



//confirm rececption
const ConfirmReception=(order_id, product_sku, product_name,product_image, price,Qty, email_seller, seller_name)=>{
let user_name=User[0].name;
  setLoading(true);
triggerHapticFeedback();
  fetch('https://tunizoon.com/appgate/userOrderActions.php?action=confirm_reception', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `seller_name=${seller_name}&user_name=${user_name}&order_id=${order_id}&product_sku=${product_sku}&product_name=${product_name}&product_image=${product_image}&product_price=${price}&product_qty=${Qty}&email_seller=${email_seller}`,

  })
  
    .then((response) => response.json())
    .then((json) => {

      if (json.hasOwnProperty("error")) {
    setLoading(false);
    setError(json.error); // Output the error
    } else {
      setLoading(false);
      myFetchDataFunction();
      setSuccess(`mriguel tawa weslek el colis mte3 ${product_name}, ken t7eb traja3 walla tbaddel arja3 lil page hethi w otlob retour, Floomy masla7et el 7ourafa2, ih n3aaaam`);
      setcontextTrigger(true);
    }

    })
   
    .catch((error) => {
      setLoading(false);
      console.error(error);
    });

}



//Sendback asking for 
const sendbackAsk=(order_id, product_sku, product_name,product_image, price,Qty, email_seller, seller_name)=>{
  setLoading(true);
  let user_name=User[0].name;
triggerHapticFeedback();
  fetch('https://tunizoon.com/appgate/userOrderActions.php?action=sendbackask', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `seller_name=${seller_name}&user_name=${user_name}&order_id=${order_id}&product_sku=${product_sku}&product_name=${product_name}&product_image=${product_image}&product_price=${price}&product_qty=${Qty}&email_seller=${email_seller}`,

  })
  
    .then((response) => response.json())
    .then((json) => {

      if (json.hasOwnProperty("error")) {
        setError(json.error); // Output the error
    } else {
      setLoading(false);
      myFetchDataFunction();
      setSuccess(`c bon tawa 3aditlek demande retour lil produit ${product_name}, juste nestannew el vendeur ya3mel confirmation. ba3d el confirmation youslek mail w tnajem tarja3 lil page hethi t7ot numéro el colis mte3 retour. thanna 3and Floomy ma ythi3lek chay \u{1F600}` );
      setcontextTrigger(true);
    }

    })
   
    .catch((error) => {
      console.error(error);
    });

}













  const BottomViewRef= useRef(null);








  if (Order === undefined) {
    return <SafeAreaView style={StyleOrder.androidSafeArea}>
<LoadingView/>
  
    </SafeAreaView>; // or any other loading indicator
  }

  return (





<>

<SafeAreaView   style={StyleOrder.androidSafeArea}>




{/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
            console.log('Modal has been closed.');
          }}
>

<MenuLoginUser  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>









<ScrollView stickyHeaderIndices={[0]}
ref={ScrollViewRef} vertical={true} showsVerticalScrollIndicator={false} 
style={StyleOrder.root} nestedScrollEnabled={true} 
>


<View style={StyleOrder.logoAndButtonContainerViewBox}>
{/*the container for the logo and the button to show the login menu*/}
<View  style={StyleOrder.logoAndButtonContainer}>

<Pressable style={StyleOrder.BackButton} onPress={() =>{triggerHapticFeedback();navigation.navigate('OrdersUserAll', {propName:User});} }>
<Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
</Pressable>

 <Pressable   onPress={toggleMenu}   style={StyleOrder.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>
</View>
</View>
















   {/*ORDER CONTAINER */}

<View style={StyleOrder.BigContainerOrderButton}>

<View style={StyleOrder.OrderContainer}>


<View style={StyleOrder.OrderViewHead}>
  <Text style={StyleOrder.OrderViewHeadText}>{Orderdata.order_id}</Text>
  <Text style={StyleOrder.OrderViewHeadText}><AntDesign name="calendar" size={14} color="black" />  {formatDate(Orderdata.datum)}</Text> 
  <Text style={StyleOrder.OrderViewHeadText}><Feather name="watch" size={14} color="black" />  {Orderdata.uhrzeit}</Text>
  </View>

  <View>

  {Orderdata.sku_sellers&& Orderdata.sku_sellers.map((skuSellerArray, index) => (
    <View key={`${Orderdata.order_id}_${index}`}>
{skuSellerArray.map((item, subIndex) => (
  <View key={`${Orderdata.order_id}_${index}_${subIndex}`}>
        <View style={StyleOrder.SellerInfoContainer}>
<Image style={StyleOrder.logoImageSellerOrder} source={{ uri: `https://tunizoon.com/${item.image}` }} ></Image>
  <Text style={StyleOrder.nameTextSellerOrder} >{item.name} shop</Text>

  <StarRating score={item.seller_score} />
  </View>

{/*each product in the order with the action AND STATUS */}

{Object.entries(Order).length> 0 && Object.entries(Order).map(([key, Order], Productindex) => (

<View key={Productindex}>

{Order.sku_seller===item.sku_seller ? (
<View>
  <View style={StyleOrder.wrapperProductView}>
<View   style={StyleOrder.ProductView}>
  <Image style={StyleOrder.productImage} source={{uri: `https://tunizoon.com/${Order.product_image}`}}/>
  
     <View style={StyleOrder.productInfo} >
<Text style={StyleOrder.productInfoTextName}>{Order.product_name.slice(0,30)+ '...'}</Text>

     <Text>Prix: {Order.price}</Text>

  <Text>Quantité {Order.Qty}</Text>


   </View>

</View>

</View>




{/*actions View */}


<View style={StyleOrder.actionAndStatusView}>




<View style={StyleOrder.informationView}>
  <Text style={StyleOrder.informationViewText}>information et statut</Text>





  {/*check if retour requested */}
  { Order.sendbackask!=="1"? (



    
<View style={StyleOrder.actionAndStatusButtonsContainer}>
  {/*check if confirmed*/}


{Order.Status==="confirmed"? (
<>

<View  style={StyleOrder.actionAndStatusButtonsViews}>
<Pressable style={StyleOrder.StatusButtonsButtonsGreen}>
<Text>
<AntDesign name="check" size={25} color="white" />
</Text>
</Pressable>
</View>



</>
)
:
(
  <>
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={25} color="white"/>
</Text>
</Pressable>
</View>
</>
)}

  {/*check if sent*/}

  {Order.Status==="confirmed" && Order.sent!==null? (
<>
<View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGreen}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>
<Pressable style={StyleOrder.StatusButtonsButtonsGreen}>
<Text>
<AntDesign name="check" size={30} color="white" />
</Text>
</Pressable>
</View>
</>
)
:
(
  <>
  <View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGrey}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={30} color="white"/>
</Text>
</Pressable>
</View>
</>
)}

  {/*check if delivered*/}

  {Order.Status==="confirmed" && Order.sent!==null && Order.delivered_user!==null? (
<>
<View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGreen}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>
<Pressable style={StyleOrder.StatusButtonsButtonsGreen}>
<Text>
<AntDesign name="check" size={30} color="white" />
</Text>
</Pressable>
</View>
</>
)
:
(
  <>
  <View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGrey}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={30} color="white"/>
</Text>
</Pressable>
</View>
</>
)}

  {/*check if sendback is rquested*/}


  {Order.Status==="confirmed" && Order.sent!==null && Order.delivered_user!==null && Order.sendbackask===null? (
    

    <><View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGrey}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={30} color="white"/>
</Text>
</Pressable>
</View></>
  )
:
(
<><View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGrey}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={30} color="white"/>
</Text>
</Pressable>
</View></>

)



}





</View>

):(


<View style={StyleOrder.actionAndStatusButtonsContainer}>
  {/*check if not approoved*/}


{Order.sendbackask==="1"?(
  <>
<View  style={StyleOrder.actionAndStatusButtonsViews}>
<Pressable style={StyleOrder.StatusButtonsButtonsGreen}>
<Text>
<AntDesign name="check" size={30} color="white" />
</Text>
</Pressable>
</View>
</>
)
:
(
null
)}


{Order.sendbackask==="1"&& Order.sendbackapproved==="1"?(
  <>
<View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGreen}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>
<Pressable style={StyleOrder.StatusButtonsButtonsGreen}>
<Text>
<AntDesign name="check" size={30} color="white" />
</Text>
</Pressable>
</View>

</>
)
:
(
  <>
  <View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGrey}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={30} color="white"/>
</Text>
</Pressable>

</View>
</>
)}
{Order.sendbackask==="1"&& Order.sendbackapproved==="1" && Order.sendbacknumber!==null?(

<>
<View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGreen}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>
<Pressable style={StyleOrder.StatusButtonsButtonsGreen}>
<Text>
<AntDesign name="check" size={30} color="white" />
</Text>
</Pressable>
</View>

</>
)
:
(
  <>
  <View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGrey}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={30} color="white"/>
</Text>
</Pressable>

</View>
</>
)}

{Order.sendbackask==="1"&& Order.sendbackapproved==="1" && Order.sendbacknumber!==null && Order.confirm_retour_reception_seller==="1"?(

<>
<View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGreen}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>
<Pressable style={StyleOrder.StatusButtonsButtonsGreen}>
<Text>
<AntDesign name="check" size={30} color="white" />
</Text>
</Pressable>
</View>

</>
)
:
(
  <>
  <View  style={StyleOrder.actionAndStatusButtonsStickViews}>
<Pressable style={StyleOrder.StatusButtonSticksGrey}>
<Text></Text>
</Pressable>
</View >
<View  style={StyleOrder.actionAndStatusButtonsViews}>

<Pressable style={StyleOrder.StatusButtonsButtonsGrey}>
<Text>
<Feather name="x" size={30} color="white"/>
</Text>
</Pressable>

</View>
</>
)}


</View>




)

}





{/***only for sub text */}


  {/*check if retour requested */}
  { Order.sendbackask!=="1"? (

<View style={StyleOrder.textStatusContainer}>
  {/*check if confirmed*/}


{Order.Status==="confirmed"? (
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>confirmé</Text>
</View>

)
:
(
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>confirmé</Text>
</View>
)}

  {/*check if sent*/}

  {Order.Status==="confirmed" && Order.sent!==null? (
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>envoyé</Text></View>
)
:
(
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>envoyé</Text>
</View>

)}

  {/*check if delivered*/}

  {Order.Status==="confirmed" && Order.sent!==null && Order.delivered_user!==null? (
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>livré</Text></View>
)
:
(
  <View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>livré</Text>
</View>
)}

  {/*check if sendback is rquested*/}


  {Order.Status==="confirmed" && Order.sent!==null && Order.delivered_user!==null && Order.sendbackask===null? (
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour</Text>
</View>
  )
:
(<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour</Text>
</View>
)

}

</View>

):(


<View style={StyleOrder.textStatusContainer}>
  {/*check if not approoved*/}


{Order.sendbackask==="1"?(
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour{'\n'}demandé</Text></View>
)
:
(
null
)}


{Order.sendbackask==="1"&& Order.sendbackapproved==="1"?(
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour{'\n'}accepté</Text></View>
)
:
(
  <View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour{'\n'} accepté</Text>
</View>
)}
{Order.sendbackask==="1"&& Order.sendbackapproved==="1" && Order.sendbacknumber!==null?(
<View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour{'\n'}envoyé</Text></View>
)
:
(
  <View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour{'\n'}envoyé</Text>
</View>
)}

{Order.sendbackask==="1"&& Order.sendbackapproved==="1" && Order.sendbacknumber!==null && Order.confirm_retour_reception_seller==="1"?(
<View  style={StyleOrder.textStatusContainerChild}>

<Text style={StyleOrder.StatusButtonsText}>retour{'\n'}reçu</Text></View>
)
:
(
  <View  style={StyleOrder.textStatusContainerChild}>
<Text style={StyleOrder.StatusButtonsText}>retour{'\n'}reçu</Text>
</View>
)}
</View>




)

}




{/**form actions container to change the status, like asking for sendback... confrom recepetion */}



{/*check hier if sent and than when the user click the button insert to order that the order was delivered successfully */}




{
//if confirmed and not sent

Order.Status==="confirmed" && Order.sent === null && Order.delivered_user === null?

(
<View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>confirmé pas encore envoyé</Text></View>
)
  



//if conformed and sent
:Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === null?
  (

    <View style={StyleOrder.actionsView}>
      <Text style={StyleOrder.actionsViewText}>envoyé le {Order.datum}, numéro du colis {Order.sent}.</Text>
       <Pressable onPress={() => ConfirmReception(Order.order_id, Order.sku_product, Order.product_name, Order.product_image, Order.price,Order.Qty,Order.email_seller,item.name)} style={StyleOrder.actionsViewButton}>
        <Text style={StyleOrder.actionsViewButtonText}>Appuyez ici pour confirmer la réception</Text></Pressable>
      </View>
  )




//if confirmed sent and recieved, send back allowed seller pay fees and not requested
:Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === "1" && Order.sendbackask!=="1" && Order.return_policy==="seller pay"?(
  
  <View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>Livrée le {Order.delivered_user_date}, demande de retour possible et le vendeur paie pour le retour. Vous serez remboursé de la totalité de {Order.order_price}  TND.</Text>
  <Pressable onPress={() => sendbackAsk(Order.order_id, Order.sku_product, Order.product_name, Order.product_image, Order.price,Order.Qty,Order.email_seller,item.name)} style={StyleOrder.actionsViewButton}>
  <Text style={StyleOrder.actionsViewButtonText}>Appuyez ici pour demander un retour</Text></Pressable>
</View>
  
  )
  //if confirmed sent and recieved, send back allowed user pay fees and not requested
:Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === "1" && Order.sendbackask!=="1" && Order.return_policy==="buyer pay"?(
  
  <View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>Livrée le {Order.delivered_user_date}, demande de retour possible et vous payez les frais de retour. Vous serez remboursé de la totalité de {Order.order_price} TND.</Text>
  <Pressable onPress={() => sendbackAsk(Order.order_id, Order.sku_product, Order.product_name, Order.product_image, Order.price,Order.Qty,Order.email_seller,item.name)} style={StyleOrder.actionsViewButton}>
  <Text style={StyleOrder.actionsViewButtonText}>Appuyez ici pour demander un retour{Order.sendbackask}</Text></Pressable>
</View>
  
  )
  //if confirmed sent and recieved, send back allowed seller pay fees and not requested
:Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === "1" && Order.sendbackask!=="1"&& Order.return_policy==="not accepted" ?(
  
  <View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>Livrée le {Order.delivered_user_date}, une demande de retour n'est pas possible car le vendeur n'accepte pas les retours 
 pour ce produit comme indiqué lors de l'achat. Vous pouvez quand même l'appeler et essayer, mais il n'est pas obligé d'accepter le retour. Voici son numéro de téléphone : {Order.tel_seller}.</Text>

</View>
  
  )




//if confirmed sent and recieved, send back requested

:Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === "1" && Order.sendbackask==="1" && Order.sendbackapproved!=="1"?(
  
    <View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>Retour demandé le {Order.sendbackask_date}.</Text></View>
)
   
//if confirmed sent and recieved, send back requested and approoved enter the sending number

:Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === "1" && Order.sendbackask==="1" && Order.sendbackapproved==="1" && Order.sendbacknumber===null?(
  
  <View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>Retour accepté le {Order.sendbackapproved_date} envoyez le produit au vendeur, et entrez le numéro du colis ici</Text>
  
  <TextInput
   
   ref={InputRef}



   style={[
    {
      borderColor:Input.length<1 ?"rgba(0, 0, 0, 0.4)": everyThingOkInput? "#5cb85c" : "red",
    },
    ,StyleOrder.inputText
  ]}

   placeholder="Entrez le numéro du colis"
   onChangeText={handleInputChange}
   value={Input}
   />
   <TextInput/>
   {everyThingOkInput? (


   <Pressable onPress={() => AddSendBackNumber(Order.order_id, Order.sku_product, Order.product_name, Order.product_image, Order.price,Order.Qty,Order.email_seller,item.name)}  style={StyleOrder.ButtonSuccess}>
   <Text style={StyleOrder.ButtonText}>Ajouter un numéro de colis </Text>
  </Pressable>
  ) : (   <Pressable  style={StyleOrder.ButtonFail}>
      <Text style={StyleOrder.ButtonText}>Ajouter un numéro de colis</Text>
    </Pressable>)}
  </View>
):

Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === "1" && Order.sendbackask==="1" && Order.sendbackapproved==="1" && Order.sendbacknumber!==null
&& Order.confirm_retour_reception_seller!=="1"?(

  <View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>Retour envoyé le  {Order.sendbacknumber_date}</Text></View>

):


Order.Status==="confirmed" && Order.sent !== null && Order.delivered_user === "1" && Order.sendbackask==="1" && Order.sendbackapproved==="1" && Order.sendbacknumber!==null &&
Order.confirm_retour_reception_seller==="1"?(

  <View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>
    Retour reçu le {Order.confirm_retour_reception_seller_date}, rempboursement de {Order.order_price} Tnd en cours</Text></View>


):

(
  
<View style={StyleOrder.actionsView}><Text style={StyleOrder.actionsViewText}>Commande pas encore confirmé</Text>


</View>

  )}


</View >










</View>






{/*end of the container for actions and info */}
</View>



):
(null)}



  </View>
))}









  </View>
      ))}

      
    </View>
  ))}


{Orderdata.Status==="not confirmed"?(
  <TouchableOpacity style={{height:50, width:"90%",maxWidth:800, marginHorizontal:Platform.OS==="web"?"auto":"5%", justifyContent:"center", alignItems:"center", borderRadius:10, backgroundColor:"#00BFFF"}}
  onPress={()=>{navigation.navigate('PayementGate', {PayementData:paymentData});killTypeWriter();}}>
    <Text style={{fontWeight:"bold", color:"black"}}>aller au paiment</Text></TouchableOpacity>
):null}

</View>







</View>
</View>
<View ref={BottomViewRef} style={StyleOrder.bottomView}></View>

</ScrollView>



</SafeAreaView>

{/*error and loading handling*/}
{Error?(    <View style={StyleOrder.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleOrder.ErrorViewButton}><Text  style={StyleOrder.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}


    {Success?(
 <View style={StyleOrder.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={StyleOrder.ErrorViewButton}><Text  style={StyleOrder.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }


{loading?(<View style={StyleOrder.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}</>
  );
};

const StyleOrder= StyleSheet.create({

  androidSafeArea: {
  


    paddingTop: Platform.OS === 'android' ? 25 : 0,
    backgroundColor:"#00BFFF",
height:Platform.OS==="web"?"100vh":"100%",
    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,



},

root:{
flex:1,
   zIndex:-2,
   backgroundColor:"#00BFFF",
 width:"100%",
   height:"100%"
   
 },
 BigContainerOrderButton:{
  backgroundColor:"white", paddingBottom:150
  
   },

  OrderContainer:{
flex:1, flexDirection:"column",
backgroundColor:"white", width:"98%", marginLeft:"1%", marginRight:"1%",marginVertical:20, borderRadius:4,
paddingVertical:20,marginVertical:50
  },
  OrderViewHead:{flex:1, flexDirection:"row",justifyContent:"space-between" , paddingHorizontal:20},


  OrderViewHeadText:{
    fontSize:14, fontWeight:"bold"
  },

  wrapperProductView:{
backgroundColor:"white",
    borderRadius:4,

  },
  ProductView:{
    flex:1, flexDirection:"row",
    backgroundColor:"white",

borderRadius:4,
    width:"90%",
    marginLeft:"5%",
    marginRight:"5%",
marginVertical:20,
borderRadius:4,
overflow:"hidden"

  },

  actionAndStatusView:{
  
backgroundColor:"white",
borderRadius:4,
    width:"90%",
 marginHorizontal:"5%",  
 marginVertical:15, 

  },




  productImage:{

    height:100, width:100, borderRadius:4,
  },
  productInfo:{
flex:1, flexDirection:"column", justifyContent:"space-between", paddingVertical:15, paddingHorizontal:10

  }, 
  productInfoTextName:{

    fontWeight:"bold"
  },



 

  actionAndStatusButtonsContainer:{

    flex:1, flexDirection:"row",height:60,   alignItem:"center", justifyContent:"space-between"

  },


  actionAndStatusButtonsStickViews:{

    height:60,width:"14%", marginHorizontal:"auto",
   justifyContent:"center", alignItems:"center",
    
      },



      actionAndStatusButtonsViews:{

        height:60, width:"14%",marginHorizontal:"auto",
         justifyContent:"center", alignItems:"center"
        
         
          },
        




  informationView:{
flex:1,flexDirection:"column",
 padding:5, borderRadius:4,    
  },
  informationViewText:{

    fontWeight:"bold", marginBottom:5, marginLeft:10
  },




  StatusButtonsButtonsGreen:{

height:40, width:40, borderRadius:"100%", backgroundColor:"#5cb85c", justifyContent:"center", alignItems:"center",

  },

  StatusButtonsButtonsGrey:{
    height:40, width:40, borderRadius:"100%", backgroundColor:"grey", justifyContent:"center", alignItems:"center", 

  },

StatusButtonsText:{

fontWeight:"bold", fontSize:10, alignSelf:"center", 
},

  StatusButtonSticksGreen:{
height:4, width:"100%",  backgroundColor:"#5cb85c",  marginLeft:3,marginRight:3
 },
  StatusButtonSticksGrey:{
height:4, width:"100%", backgroundColor:"grey", marginLeft:3,marginRight:3

},


SellerInfoContainer:{
  flex:1, flexDirection:"column", justifyContent:"center", alignItems:"center", paddingVertical:10
},
logoImageSellerOrder:{
  height: 50, width:50 ,borderRadius:10
},
nameTextSellerOrder:{
  paddingVertical:10, fontWeight:"bold"
},


actionsView:{
 justifyContent:"center", alignItems:"center",backgroundColor:"rgba(0,0,0,0.04)"
},
actionsViewText:{ fontWeight:"bold",   fontSize:14, marginVertical:10, marginHorizontal:10, color:"#333333"
},
actionsViewButton:{height:40, justifyContent:"center", alignItems:"center", borderWidth:1,
borderRadius:4,marginTop:10, borderColor:"rgba(0,0,0,0.3)",backgroundColor:"#5cb85c"
},
actionsViewButtonText:{
  fontWeight:"bold",
color:"white"
},


  //menu login
  menuLogin:{
    height:"100%",
    backgroundColor:"white",
    display:"none",


  },

  menuLoginButtonsContainer:{
    flex:1, flexDirection:"row",  alignItems:"center", justifyContent: "space-between",marginTop:30
    
      },
      menuLoginButtons:{
    
        backgroundColor:"#0059ba", height:40, alignItems:"center",margin:10, width:150, borderRadius:4, justifyContent:"center"
     
      },
      menuLoginButtonsText:{
    
    color:"white", fontWeight:"bold" 
      },
      userImageAndName:{
     flexDirection:"column",paddingHorizontal:20
    
    
      },
      userImage:{
        height:70, width:70,marginBottom:10, borderRadius:70
      },
      userName:{
    fontWeight:"bold",
    fontSize:18, alignSelf:"center"
      },
      BackButton:{
    
        height:50, width:50, backgroundColor:"#00BFFF", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
      
      
      },


      logoAndButtonContainerViewBox:{
        height:80,       backgroundColor:"#00BFFF",
      },
      logoAndButtonContainer:{
  
flex:1, flexDirection:"row",justifyContent:"space-between",
        backgroundColor:"#00BFFF",
       height:80,
         marginBottom:20
    
        },


        
        ImageUserAndButtonContainer:{
          flex:1,
          flexDirection:"row",
          
          backgroundColor:"#00BFFF",
          height:"auto",
          
          width:"100%",
          justifyContent:"space-between",paddingVertical:20
          
          
          },
        loginButton :{

     
          justifyContent: 'center',
          alignItems: 'center',
      
      backgroundColor:"#00BFFF",
    
      height:50,
      width:50,
      marginTop:10,
      marginBottom:10,
      marginRight:20,
    
    
      
      
        },


        inputText:{
  
          backgroundColor:"white", height:50, width:"90%", marginLeft:"5%", marginRight:"5%", borderWidth:2, borderRadius:4,paddingLeft:10
          
              },
          
              ButtonSuccess:{
  
                width:"90%", marginLeft:"5%", marginRight:"5%", backgroundColor:"#5cb85c", height:50, justifyContent:"center", alignItems:"center", borderRadius:4,
                marginTop:10
            },
            ButtonFail:{
  
              width:"90%", marginLeft:"5%", marginRight:"5%", backgroundColor:"grey", height:50, justifyContent:"center", alignItems:"center", borderRadius:4,
              marginTop:10
          },      
ButtonText:{fontWeight:"bold" ,color:"white"},
 //error and loading view
 LoadingView:{
  position:Platform.OS==="web"?"fixed":"absolute", top:0,left:0,height:Platform.OS==="web"?"100vh":"100%",width:"100%", zIndex:99,
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",
 backgroundColor:"#DE2C2C", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,width:"98%",marginHorizontal:"1%",marginVertical:10,borderRadius:4, borderWidth:1, borderColor:"rgba(0,0,0,0.4)"
},
SuccessView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",
 backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,width:"98%",marginHorizontal:"1%",marginVertical:10,borderRadius:4, borderWidth:1, borderColor:"rgba(0,0,0,0.4)"
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},

 textStatusContainer:{ flex:1, flexDirection:"row",justifyContent:"space-between",marginTop:20},


 textStatusContainerChild:{width:"auto",height:40,alignItems:"center",justifyContent:"center" },


});

export default OrderUser;
