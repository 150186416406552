import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView, Vibration,Platform, TouchableOpacity} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Keyboard } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMyContext } from './MyContext';
import * as Haptics from 'expo-haptics';
import Typewriter from "./typeWriter";
const LoginUser = ({ navigation, route }) => {

  const {contextTrigger, setcontextTrigger}=useMyContext();


    //const while loading
    const [loading, setLoading] = useState(false);
    //hold errors
      const[Error, setError]=useState('');


    //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}



//user and seller Objects
const{User, setUser}= useMyContext({});
//if seller is logged in
const{Seller, setSeller}= useMyContext([]);
const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();
const emailInputRef=useRef();
const PasswordInputRef=useRef();
const [Email, setEmail]=useState('');
const [password, setPassword]=useState('');
const [everyThingOkEmail, seteveryThingOkEmail]=useState(0);
const [everyThingOkPassword, seteveryThingOkPassword]=useState(0);
//fetch user and login


const handleLogin=()=>{
setLoading(true);
triggerHapticFeedback();
 
setError('');
  fetch('https://tunizoon.com/appgate/userLoginApp.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `email=${Email}&password=${password}`,

  })
  
    .then((response) => response.json())
    
    .then((json) => {
  
      if (json.hasOwnProperty("error")) {
        setLoading(false);
        setError(json.error); // Output the error
      
    } else {
 
   
      setUser({ ...json.user });//set the user object to use it later
      setUserIsLoggedIn(true);


      if (json.user && json.user[0] && json.user[0].user_verification === "1") {
        // The property exists and has the value "1"
        navigation.navigate('home');
      } else {
      
        navigation.navigate('confirmUserMail', { UserMail: Email });
      }
      









setcontextTrigger(true);
        setLoading(false);
        setSeller();
    }


   
    })
   
    .catch((error) => {
      setLoading(false);
      console.error(error);
    });
    






};


//unset error 
const unsetError=()=>{
  setError(null);
}








//check only letters and numbers in the comment input
const onlyLettersAndNumbers = (str) => {
    return /^[A-Za-z0-9.,?!: ]*$/.test(str);
  };
    
//chek MailFormat
  const onlyMailFormat = (str) => {
    const mailFormatRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    
    return mailFormatRegex.test(str);
  };

  //Email change
    const handleEmailChange= (mail) => {
  
      if (onlyMailFormat(mail)) {
        setEmail(mail);
        seteveryThingOkEmail(1);
        emailInputRef.current.setNativeProps({
      
          style: {
            borderColor:"#5cb85c"
          }
        });
      }
      else
      {
        seteveryThingOkEmail(0);
        setEmail(mail);
        emailInputRef.current.setNativeProps({
      
          style: {
            borderColor:"red"
          }
        });

      }
    };
 //Password change
 const handlePasswordChange= (password) => {

    if (onlyLettersAndNumbers(password)) {
      setPassword(password);
      seteveryThingOkPassword(1);
      PasswordInputRef.current.setNativeProps({
    
        style: {
          borderColor:"#5cb85c"
        }
      });
    }
    else
    {
        seteveryThingOkPassword(0);
      setPassword(password);
      PasswordInputRef.current.setNativeProps({
    
        style: {
          borderColor:"red"
        }
      });

    }
  };



  











  return (
    <KeyboardAvoidingView>

<SafeAreaView style={StyleLoginUser.androidSafeArea}>

   {/*the container for the backbutton*/}
<View  style={StyleLoginUser.BackButtonContainer}>

<Pressable style={StyleLoginUser.BackButton} onPress={() => { triggerHapticFeedback();
  navigation.navigate('home'); }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable></View>


    <ScrollView vertical={true} showsHorizontalScrollIndicator={false}  style={StyleLoginUser.Container}  keyboardShouldPersistTaps='handled'>
    <View style={StyleLoginUser.HeadTextView}>

<Text style={StyleLoginUser.HeadText}>se connecter a mon compte{'\n'}client</Text>
</View>

<View style={{width:Platform.OS==="web"?"100vw":"100%",maxWidth:Platform.OS==="web"?450:"100%", alignSelf:"center" }}>

 <Text style={StyleLoginUser.Label}>Votre adresse Email *</Text>
 <TextInput
 ref={emailInputRef}
            style={StyleLoginUser.inputText}
            placeholder="email"
            onChangeText={handleEmailChange}
            value={Email}
 />




 <Text style={StyleLoginUser.Label}>Votre mot de passe *</Text>
 <TextInput
 
 ref={PasswordInputRef}
 style={StyleLoginUser.inputText}
 secureTextEntry={true}
 placeholder="Enter password"
 onChangeText={handlePasswordChange}
 value={password}
 />


 {everyThingOkPassword === 1 && everyThingOkEmail === 1? (
 <Pressable style={StyleLoginUser.loginButtonSuccess} onPress={handleLogin}>
 <Text style={StyleLoginUser.loginButtonText}>se connecter</Text>
</Pressable>
) : (   <Pressable style={StyleLoginUser.loginButtonFail}>
    <Text style={StyleLoginUser.loginButtonText}>se connecter</Text>
  </Pressable>)}

  <Pressable style={StyleLoginUser.goToRegisterPage} onPress={() => navigation.navigate('registerUser', {propsName:"register"})} >
  <Text style={StyleLoginUser.goToRegisterPageText} >Vous n'avez pas de compte?</Text>
  </Pressable>

</View>
 
    </ScrollView>
     {/*error and loding handling*/}
{Error?(    <View style={StyleLoginUser.ErrorView}>


  <Typewriter text={Error} 
delay={10} />


      
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleLoginUser.ErrorViewButton}><Text  style={StyleLoginUser.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}




    {loading?(<View style={StyleLoginUser.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}

    </SafeAreaView>
    </KeyboardAvoidingView>
  );
};
const StyleLoginUser = StyleSheet.create({
  androidSafeArea: { height: "100%",paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#00BFFF"





},
  Container: {   height: Platform.OS === 'web' ? '100vh' : '100%', backgroundColor: "#00BFFF", paddingTop: 30 },



  inputText: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10 },
  HeadTextView: { backgroundColor: "#00BFFF", justifyContent: "center", alignItems: "center" },
  HeadText: { fontSize: 20, fontWeight: "bold", textAlign: 'center' },
  Label: { marginLeft: "5%", marginBottom: 15, marginTop: 15, fontSize: 16 },
  loginButtonFail: { width: "90%", marginLeft: "5%", marginRight: "5%", backgroundColor: "grey", height: 50, justifyContent: "center", alignItems: "center", borderRadius: 4, marginTop: 10 },
  loginButtonSuccess: { width: "90%", marginLeft: "5%", marginRight: "5%", backgroundColor: "#5cb85c", height: 50, justifyContent: "center", alignItems: "center", borderRadius: 4, marginTop: 10 },
  loginButtonText: { fontSize: 18, fontWeight: "bold", color: "white" },
  goToRegisterPage: { width: "100%", justifyContent: "center", alignItems: "center", padding: 10 },
  goToRegisterPageText: { fontSize: 18, textDecorationLine: 'underline', textDecorationStyle: 'solid' },

  BackButtonContainer: { height: 70, backgroundColor: "#00BFFF"},
  BackButton: { height: 30, marginVertical: 20, marginLeft: 20 },
 //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},



});

export default  LoginUser;
