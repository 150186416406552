import React, { useEffect, useState,useRef, useCallback} from 'react';
import {Modal, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform,Animated, Dimensions, TouchableOpacity } from 'react-native';
import * as Haptics from 'expo-haptics';
import { useMyContext } from './MyContext';
import { Audio} from 'expo-av';
 import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 
import Typewriter from "./typeWriter";
import { Feather } from '@expo/vector-icons'; 
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const Cart  = () => {







  //Error holding and displaying vars
  const [Error, setError]=useState('');
const[targetViewFloomyDetail, settargetViewFloomyDetail]=useState('');
  
  //trigger haptic feedback
  const triggerHapticFeedback=()=>{
    if(Platform.OS!=="web"){
    Haptics.notificationAsync(
      Haptics.NotificationFeedbackType.Success);}
  }
 
  const navigation = useNavigation();



        const screenHeight = Dimensions.get('window').height;
      const [Sellers, setSellers]=useState();
        const { cart, setCart } = useMyContext();
        const {countProductCart, setCount} = useMyContext();
      const {sellersSku, setsellersSku} = useMyContext([]);

      const SellerDetailInCartRef=useRef([]);
  //const using useEffect and State to show and hide the scrollView for cart
  const menuRef = useRef(null);
  const headPageRef = useRef(null);
  const headText2ViewRef = useRef(null);
  const headText2Ref = useRef(null);
  const headText1ViewRef=useRef(null);

  const{totalPrice, settotalPrice} = useMyContext();

  const{totalDeliveryForAll, setTotalDeliveryForAll} = useMyContext();
 

  //const using useEffect and State to show and hide the scrollView for cart
  const [showMenuRef, setshowMenuRef] = useState(false);
 
   const [showScrollViewRef, setShowScrollViewRef] = useState(false);
 
 const [showSellerInfoCartViewRef, setshowSellerInfoCartViewRef]=useState(false);
 
 
   const ScrollViewRef = useRef(null);
   const ScrollViewRefCart = useRef(null);

//when aDD to button is pressed feedback
const [pressedButton, setPressedButton] = useState(null);

  const [showModal, setShowModal] = useState(false);







// Function that construct the stars for rating 
function StarRating({ score }) {
  const filledStars = Array(parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`filled-${index}`} name="star" size={14} color="#fcb103" />
  ));
  const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`empty-${index}`} name="star" size={14} color="grey" />
  ));
  const stars = [...filledStars, ...emptyStars];
  return <Text>{stars}</Text>;
}



const handlePressIn = (productSku) => {
  setPressedButton(productSku);


};

const handlePressOut = () => {
  setPressedButton(null);
};




//animation to show View opaicty 0 to 1 Cart
const [animationCart] = useState(new Animated.Value(0));
const [showElement, setShowElement] = useState(false);
const startAnimation = () => {
  const endValue =1;

  Animated.timing(animationCart, {
    toValue: endValue,
    duration: 200,
    useNativeDriver: true,
  }).start(() => {
    setShowElement(!showElement);
  });
};
















//function to show and hide the scrollView of the cart w
const toggleScrollViewCart = () => {
  triggerHapticFeedback();
  setTotalDeliveryWhenNeeded();
setShowModal(!showModal);
settargetViewFloomyDetail('');

};


//use Effect listening to events
//useEffect(() => {

//}, [countProductCart, sellersSku,cart,showScrollViewRef, totalPrice]);







//function to add products in the cart
//const updatedCart = cart.filter((item) => item[productSku] === undefined);
//setCart(updatedCart);
//setCount(countProductCart - 1);


// Function to calculate and return the modified value of the weight in cart foreach seller
const calculateWeight = (weight) => {
  if (weight >= 1000) {
    weight /= 1000;

  }
  return Number(weight.toFixed(3));
};


const calculateWeightWithUnit = (weight) => {
  let unit=" g";
  if (weight >= 1000) {
    weight /= 1000;
    unit=" Kg";

  }
  return `${Number(weight.toFixed(2))}${unit}`;
};


// Function to calculate the total delivery price for each seller

function calculateDeliveryCost(totalWeightProductSeller) {
  let totalDeliverySeller;
  if (totalWeightProductSeller < 2001) {
    totalDeliverySeller = 8;
  } else {
    const totalWeightProductSeller1 = totalWeightProductSeller / 1000;
    const totalWeightProductSellerRounded = Math.ceil(totalWeightProductSeller1) - 2;
    totalDeliverySeller = 8 + (totalWeightProductSellerRounded * 0.5);

  }
  return  Number(totalDeliverySeller.toFixed(2));
}






const calculateDeliveryCostForAllSeller=()=>{
  let totalWeight = 0;
  let totalDeliveryPriceForAll = 0;
  if (sellersSku.length) {
    sellersSku.forEach((seller) => {
      const sellerProductsWeightInCart = parseFloat(seller[Object.keys(seller)[0]].productWeightProSeller) || 0;
      totalWeight += sellerProductsWeightInCart;
      totalDeliveryPriceForAll+=calculateDeliveryCost(sellerProductsWeightInCart); 
    });
  }
  return totalDeliveryPriceForAll;

};






//set the total delivery price only when nedded
const setTotalDeliveryWhenNeeded=()=>{

  let totalDeliveryCost=calculateDeliveryCostForAllSeller();

setTotalDeliveryForAll(totalDeliveryCost);

}

// Function to calculate the total weight for all sellers


const calculateTotalWeight = () => {
  let totalWeight = 0;
  if (sellersSku.length) {
    sellersSku.forEach((seller) => {
      const sellerProductsWeightInCart = parseFloat(seller[Object.keys(seller)[0]].productWeightProSeller) || 0;
      totalWeight += sellerProductsWeightInCart;
    });
  }
  return {
    calculatedWeight: calculateWeight(totalWeight),
    notCalculatedWeight: totalWeight
  };
};
const totalWeight = calculateTotalWeight();
// Function to calculate the total delivery price for all sellers
const calculateTotalDeliveryPrice = () => {
  let totalWeight = 0;
  if (sellersSku.length) {
    sellersSku.forEach((seller) => {
      const sellerProductsWeightInCart = parseFloat(seller[Object.keys(seller)[0]].productWeightProSeller) || 0;
      totalWeight += sellerProductsWeightInCart;
    });
  }
  return calculateDeliveryCost(totalWeight);
};




// Function to calculate the total weight for all sellers
// Function to calculate the total weight for all sellers


//unset Cart
const unsetCart = () => {
  triggerHapticFeedback();
  setCart([]);
  setsellersSku([]);

  setCount(0);

  settotalPrice(0);
  toggleScrollViewCart();
};

//remove items from cart
const removeItemFromCart = (productSku, productWeight, productPriceToRemove, sellerSku, productQuantity) => {

  triggerHapticFeedback();

    if (Object.keys(cart).length === 1) {
toggleScrollViewCart();}
  setsellersSku(prevSellersSku => {
    const existingSellerIndex = prevSellersSku.findIndex(item => item[sellerSku] !== undefined);
    if (existingSellerIndex >= 0) {
      const existingSellerItem = prevSellersSku[existingSellerIndex][sellerSku];
      existingSellerItem.productQuantityProSeller -= productQuantity;
      existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) - (parseFloat(productWeight) * productQuantity);
      existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) - (parseFloat(productPriceToRemove) * productQuantity);

      if (existingSellerItem.productQuantityProSeller === 0) {
        prevSellersSku.splice(existingSellerIndex, 1);
      }
      
    }

    return [...prevSellersSku];
   
  });

 

  setCart(prevCart => {
    // Find the item with the matching productSku
    const item = prevCart.find((obj) => obj[Object.keys(obj)[0]].productSku === productSku);
  
    // If no item was found, return the previous cart as-is
    if (!item) {
      return prevCart;
    }
    setCount(prevCount=>prevCount- item[Object.keys(item)[0]].productQuantity);

    //changing the total price in the cart
    const price=item[Object.keys(item)[0]].productQuantity*item[Object.keys(item)[0]].productPrice;
    settotalPrice(prevtotalPrice => {
      const totalPrice = prevtotalPrice - price;
      return Number(totalPrice.toFixed(2));
    }
      );

    // Remove the item from the cart
    const newCart = prevCart.filter((obj) => obj !== item);
    // Return the new cart
    return newCart;
    // Update the countProductCart state
  
  });
  setTotalDeliveryWhenNeeded();
};



//incrementing product quantity in cart
const incrementQuantityProduct = (productSku,productWeight, productPriceToAdd, sellerSku,productQuantity,productStock) => {

settargetViewFloomyDetail(false);
  triggerHapticFeedback();

if(productStock-productQuantity>0){
  setsellersSku(prevSellersSku => {
    const existingSellerIndex = prevSellersSku.findIndex(item => item[sellerSku] !== undefined);
    if (existingSellerIndex >= 0) {
      const existingSellerItem = prevSellersSku[existingSellerIndex][sellerSku];
      existingSellerItem.productQuantityProSeller += 1;
      existingSellerItem.productWeightProSeller=parseFloat(existingSellerItem.productWeightProSeller)+parseFloat(productWeight);
    
      existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(productPriceToAdd);

    }
    return [...prevSellersSku];
  });
  setCart((prevCart) => {
    // Find the item with the matching productSku
    const item = prevCart.find((obj) => obj[Object.keys(obj)[0]].productSku === productSku);

    // If no item was found, return the previous cart as-is
    if (!item) {
      return prevCart;
    }
    setCount(prevCount=>prevCount+1);

    // Increment the product quantity
    item[Object.keys(item)[0]].productQuantity += 1;
    //changing the total price in the cart
    const price=item[Object.keys(item)[0]].productPrice*1;
    settotalPrice(prevtotalPrice => {
      const totalPrice = prevtotalPrice + price;
      return Number(totalPrice.toFixed(2));
    }
      );

    // Return the updated cart
    return [...prevCart];
  });
  setTotalDeliveryWhenNeeded();
  settargetViewFloomyDetail(true);

}
else{
setError('La quantité ne peut pas être augmentée car tout le stock disponible de ce produit est déjà dans votre panier..');
}



};











//Decrement of prducts in the cart

const  decrementQuantityProduct=(productSku, productWeight, productPriceToRemove, sellerSku)=>{
  settargetViewFloomyDetail(false);
  triggerHapticFeedback();

    if(countProductCart==1){toggleScrollViewCart();}
  setsellersSku(prevSellersSku => {
    const existingSellerIndex = prevSellersSku.findIndex(item => item[sellerSku] !== undefined);
    if (existingSellerIndex >= 0) {
      const existingSellerItem = prevSellersSku[existingSellerIndex][sellerSku];
      existingSellerItem.productQuantityProSeller -= 1;
      existingSellerItem.productWeightProSeller=parseFloat(existingSellerItem.productWeightProSeller)-parseFloat(productWeight);

      existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) - parseFloat(productPriceToRemove);

      if (existingSellerItem.productQuantityProSeller === 0) {
        prevSellersSku.splice(existingSellerIndex, 1);
      }
    }

    return [...prevSellersSku];
  });
  setCart((prevCart) => {
    // Find the item with the matching productSku
    const item = prevCart.find((obj) => obj[Object.keys(obj)[0]].productSku === productSku);

    // If no item was found, return the previous cart as-is
    if (!item) {
      return prevCart;
    }
    setCount(prevCount=>prevCount-1);

    // Increment the product quantity
    item[Object.keys(item)[0]].productQuantity -= 1;
    //changing the total price in the cart
    const price=parseFloat(item[Object.keys(item)[0]].productPrice);
    settotalPrice(prevtotalPrice => {
      const totalPrice = prevtotalPrice - price;
      return Number(totalPrice.toFixed(2));
    }
      );
  // If the new quantity is zero, remove the item from the cart
  if (item[Object.keys(item)[0]].productQuantity === 0) {
    const newCart = prevCart.filter((obj) => obj !== item);
    return newCart;
  }
  else
  {
// Return the updated cart
return [...prevCart];

  }
    

  });
  setTotalDeliveryWhenNeeded();
  settargetViewFloomyDetail(true);
};



//show detail of seller in cart, wieght price...
const showDetailSellerCartTextFloomy=(targetView)=>{
  if(targetViewFloomyDetail!==targetView){

  settargetViewFloomyDetail(targetView);
}

else{

  settargetViewFloomyDetail('');

}

    }
    





                 return(




<>


    {/**warenkorb */} 
{countProductCart > 0 ? (  
    
<>
      {/**the cart button should be shown when cart is not empty and not shown, on press show cart and the button to complete personal data */} 
 {showModal?null: 
    (
<Pressable onPress={toggleScrollViewCart}  
style={({pressed}) => [{height: Platform.OS === 'android' ? (pressed ? 60: 60) : (pressed ? 60 : 60), },styles.cartButtonStyle,
    ]}>
<Text style={styles.ProductCountText}><FontAwesome5 name="shopping-bag" size={24} color="white" />  {countProductCart}  </Text>
 </Pressable>
    )
 }


        
 {/*the cart inside a modal */}
<Modal
          animationType={'slide'}
          transparent={true}
          visible={showModal}
          onRequestClose={() => {
            console.log('Modal has been closed.');
          }}
  >
     
 <View  style={styles.cartItemContainerBigView}>


     
 <View>

<Pressable style={styles.BackButton}    onPress={toggleScrollViewCart} >
 <Text><Feather name="chevrons-down" size={40} color="black" /></Text>
 </Pressable>

</View>
 

 {/*the scrollview where the products are displayed */}
<ScrollView   style={styles.cartItemContainerScrollView}   veritcal={true} showsVerticalScrollIndicator={false} >
  
       {/*error and loding handling*/}
       {Error?(   <View style={styles.ErrorView}>
      <Typewriter text={Error} 
    delay={10} />
          <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>
    
        </View>):null}
<View style={styles.bigContainerCart}>

   {/*the sbutton to delete all */}
<Pressable  onPress={unsetCart} style={styles.emptyCart}><Text style={styles.emptyCartText}>Vider mon panier</Text>
</Pressable>
      {/*loop to sort the products inside the cart by sellers */}
      {sellersSku && sellersSku.length > 0 && sellersSku.map((sellerSku,index) => {
          const sellerSkuToCompare= sellerSku[Object.keys(sellerSku)[0]].productSellerSku;
          const sellerProductQuantityInCart= sellerSku[Object.keys(sellerSku)[0]].productQuantityProSeller;
          const sellerProductsWeightInCart= sellerSku[Object.keys(sellerSku)[0]].productWeightProSeller*1;
          const sellerTotalPriceCart= (sellerSku[Object.keys(sellerSku)[0]].totalSeller*1).toFixed(2);

  return (

<View style={styles.sellerViewCart} key={index}>
  
 {sellerProductQuantityInCart > 0?
 (<><Image style={styles.logoImageSellerCart} source={{ uri: `https://tunizoon.com/${sellerSku[Object.keys(sellerSku)[0]].productSellerImage}` }} ></Image>
  <Text style={styles.nameTextSellerCart} >{sellerSku[Object.keys(sellerSku)[0]].productSellerName} shop</Text>
  <StarRating score={sellerSku[Object.keys(sellerSku)[0]].productSellerScore} />
    </>
 )
 :
 (null)}


       


{/*loop to show the products in the cart */}
{cart&& cart.map((item) => {
      
const itemId = Object.keys(item)[0];
const {productImage,
       productName,
       productScore, 
       productQuantity, 
       productSku , 
       productSellerName,
       productSellerSku,
       productSellerEmail,
       productSellerImage,
       productPrice,
       productStock,
       productWeight} = item[itemId];
//the price if needed
      const productPriceText = (productQuantity * productPrice).toFixed(2);

//check if the product belong to the seller
  if (productSellerSku === sellerSkuToCompare) {
          return (
       //product view to return
<View style={styles.wrapperProductCartView} key={itemId}>

 <View style={styles.productCartView} >

 <Image  style={styles.productCartViewImage} source={{ uri: `https://tunizoon.com/${productImage}` }} />
      
 <View style={styles.productCartViewInfo}>
        

 <View style={styles.productCartViewInfoText}>
 <Text style={styles.textProductNameCart}>{productName.slice(0, 14)+ '...'}</Text>

 <Text style={styles.textProductQuantityCart}>Quantit&#233;:  {productQuantity}</Text>

<Text style={styles.textProductPriceCart}>Prix: {productPrice} Tnd</Text>
     </View>  

{/*action for the product delete increment decrement ... */}
 <View style={styles.productCartViewInfoActions}>
        
 <Pressable  style={({pressed}) => [{opacity: pressed ? 0.5 : 1 }, styles.plusMinusButton,]} 
  onPress={() => decrementQuantityProduct(productSku,productWeight,productPrice,  productSellerSku)}>
    <Text style={styles.plusMinusText}>-</Text>
</Pressable >

<Text style={styles.quantityPlusMinusCart}>{productQuantity}</Text>

<Pressable style={({pressed}) => [{opacity: pressed ? 0.5 : 1 },styles.plusMinusButton,]}  
onPress={() => incrementQuantityProduct(productSku,productWeight, productPrice, productSellerSku,productQuantity,productStock)}>
<Text style={styles.plusMinusText}>+</Text>
</Pressable>
        
 </View>
        

</View>

 <Pressable  style={({pressed}) => [{opacity: pressed ? 0.5 : 1 },styles.productCartViewRemoveButton,]}  
             onPress={() => removeItemFromCart(productSku,productWeight, productPrice, productSellerSku,productQuantity)}>
 <Text><AntDesign name="delete" size={18} color="white" /></Text>
</Pressable>
 </View>

 </View>
  );
 }
        return null;
 })}
<Pressable style={styles.ButtonToShowDetailSellerTextInCart}onPress={() => showDetailSellerCartTextFloomy(sellerSkuToCompare)}>
  <Text style={styles.ShowDetailSellerTextInCart}>plus de details</Text>
  </Pressable>


 <View  style={styles.detailSellerPriceAndDeliveryCart}>

{targetViewFloomyDetail===sellerSkuToCompare?( 
<Typewriter 
text={"Salut, vous souhaitez en savoir plus ? Pas de problème, vous paierez pour  "+sellerSku[Object.keys(sellerSku)[0]].productSellerName+" shop comme suit:" +
"\n# Total poids shop: " + calculateWeightWithUnit(sellerProductsWeightInCart) +
  
"\n# livraison avec Aramex: " + calculateDeliveryCost(sellerProductsWeightInCart).toFixed(2) +" TND"+
  
"\n# Total produits: " + parseFloat(sellerTotalPriceCart).toFixed(2) +" TND"+
"\n# Total à payer: " + (parseFloat(sellerTotalPriceCart)+calculateDeliveryCost(sellerProductsWeightInCart)).toFixed(2) +" TND"


  
  } 
delay={10} noHaptics={true} />):null
      }




</View>
</View>
 );

})}

<View style={styles.totalPriceView}>
  <View style={styles.detailChildViewText}>
    <Text style={styles.totalPriceViewTextNormal}>Prix total des produits:</Text>
  </View>
  <View style={styles.detailChildViewNumbers}>
    <Text style={styles.totalPriceViewTextNumbersNormal}>{totalPrice.toFixed(2)} Tnd</Text>
  </View>

  <View style={styles.detailChildViewText}>
    <Text style={styles.totalPriceViewTextNormal}>Poids total a livrer:</Text>
  </View>
  <View style={styles.detailChildViewNumbers}>
    <Text style={styles.totalPriceViewTextNumbersNormal}>
      {totalWeight.calculatedWeight.toFixed(2)} {totalWeight.notCalculatedWeight < 1000 ? "  g" : "  Kg"}
    </Text>
  </View>

  <View style={styles.detailChildViewText}>
    <Text style={styles.totalPriceViewTextNormal}>Livraison avec Aramex 72h:</Text>
  </View>
  <View style={styles.detailChildViewNumbers}>
    <Text style={styles.totalPriceViewTextNumbersNormal}>{totalDeliveryForAll.toFixed(2)} Tnd</Text>
  </View>

<View style={styles.detailChildViewText}>
        <Text style={styles.totalPriceViewTextBold}>Montant total à payer:</Text>
        </View>
        <View style={styles.detailChildViewNumbers}>
        <Text style={styles.totalPriceViewTextNumbersBold}>   {(totalPrice+totalDeliveryForAll).toFixed(2)} Tnd</Text>
        </View>
        </View>




      
<View style={styles.paddingBtootmCart}></View>


   
</View>
</ScrollView>






</View>
<Pressable

onPress={() => {triggerHapticFeedback(); setShowModal(false); navigation.navigate('Checkout', {propName:"hello"});}} 

style={({pressed}) => [
{
 height: Platform.OS === 'android' ? (pressed ? 62: 60) : (pressed ? 62 : 60),

},
styles.cartButtonCheckOutStyle,
]}>
   <Text style={styles.checkoutButtonText}>
    confirmer et passer a la commande
   </Text>
 </Pressable>




 </Modal>
</>
    
    ) 
    : 
    (null)}



 

</>

);
  
           

}



const styles = StyleSheet.create({
 androidSafeArea: {
        
      
          paddingTop: Platform.OS === 'android' ? 25 : 90,
          backgroundColor:"#00BFFF",
      
          flex: 1,
      
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
      
      
      
      },

//modal 


      
      cartItemContainerBigView:{
        flex:1,flexDirection:"column",
        width:"100%",
        height:Platform.OS==="web"?"100vw":"100%",
 
 marginTop:Platform.OS==="web"?0:50,
    backgroundColor:"#00BFFF",

borderWidth:1,
borderRadius:10,

borderColor:"rgba(0, 0, 0, 0.4)",


      },
    
      cartItemContainerScrollView:{
       
  
        width:"100%",
        backgroundColor:"#00BFFF",
        zIndex:4,
  
      },


      
      bigContainerCart:{
      paddingTop:20,
        backgroundColor:"white", 
        width:"98%",
        marginLeft:"1%",
        marginRight:"1%",
        borderWidth:1,
        borderColor:"black",
        borderRadius:4,
      
      
      },
      totalPriceView:{
        backgroundColor:"white",
flex:1, flexDirection:"row", flexWrap:"wrap",

      width:"90%",
      maxWidth:600,
      marginLeft:"auto",
      marginRight:"5%"
      
      },



      totalPriceViewTextBold:{
      fontWeight:"bold",
      color:"black",
    
      },

totalPriceViewTextNumbersBold:{
  fontWeight:"bold",textAlign:"right",  color:"black",
  
  
  },

  totalPriceViewTextNormal:{

    color:"#333333"
   
    },

totalPriceViewTextNumbersNormal:{
textAlign:"right"


},
 
      
      
      paddingBtootmCart: {
      backgroundColor:"white", 
      height:250
      
      
      },
      
      
      logoImageSellerCart:{
      height:50,width:50, borderRadius:10,
      
      
      },
      nameTextSellerCart:{
        marginTop:5,
        marginBottom:5,
      fontWeight:"bold",
      },
      sellerViewCart:{
      backgroundColor:"white",
      justifyContent:"center",
      alignItems:"center",
      marginBottom:10
      
      },
      
      
      cartButtonStyle:{
        
 
        height: Platform.OS === 'ios' ? 90 : 60,

        flex:1,
        flexDirection:"row",
      
        backgroundColor:"#00BFFF",
        width:"100%",
      
          alignItems: 'center',
          position: Platform.OS === "web" ?"fixed":"absolute",
      bottom:0,
      
       left:0,
          zIndex:5,
          borderTopWidth:1,
          borderColor:"rgba(0, 0, 0, 0.4)",
        },

        cartButtonCheckOutStyle :{

        
 
          height: Platform.OS === 'ios' ? 90 : 60,
          flex:1,
          flexDirection:"row",
        
          backgroundColor:"#00BFFF",
          width:"100%",
        
            alignItems: 'center',
            position: Platform.OS === "web" ?"fixed":"absolute",
        bottom:0,
        
         left:0,
            zIndex:5,
            borderTopWidth:1,
            borderColor:"rgba(0, 0, 0, 0.4)",




        },





        ProductCountText:{
          textAlign: "center",
          flex: 1,
          color:"white",
          fontWeight:"bold",
      
          fontSize:20,
      
      
        },

        checkoutButtonText:{

                textAlign: "center",
                flex: 1,
                color:"black",
                fontWeight:"bold",
            
                fontSize:16,
        },
       
      
        emptyCart:{
          height:40, 
          width:140, 
          borderRadius:4,
         backgroundColor:"#DA291C",
         justifyContent:"center",
         alignItems:"center",
        
         shadowColor: 'rgba(0, 0, 0, 0.6)',
         shadowOffset: { width: 2, height: 2 },
         shadowOpacity: 0.7,
         shadowRadius: 4,
         elevation: 5,
         marginRight:"5%",
         marginLeft:"auto",
         marginBottom:20
        },
        emptyCartText:{
      
          color:"white",
          fontWeight:"bold"
        },
      
        wrapperProductCartView:{
          backgroundColor:"white",
          width:"90%",
          marginLeft:"5%",
          marginRight:"5%",
      marginTop:20,
      borderRadius:4,
      shadowColor: 'rgba(0, 0, 0, 0.3)',
      shadowOffset: { width: 1, height: 2 },
      shadowOpacity: 0.3,
      shadowRadius: 10,
      elevation: 5,
        },
      
      
        productCartView:{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
      height:140,
      width:"100%",
      borderRadius:4,
      backgroundColor:"white",
      alignContent:"space-between",
      overflow:"hidden"
      
      
        },
      
      productCartViewImage:{
      height:140,
      width:140,
      

      },
   

      productCartViewInfo:{
       
      flex:1,
      flexDirection:"column",
      height:140,

      
      
        },

      
      
        productCartViewInfoText:{
          flex:1,flexDirection:"column",
      paddingHorizontal:10,
      paddingTop:10,
      backgroundColor:"white",
      height:100,
      justifyContent:"center"
        },
      
        productCartViewInfoActions:{
      flex:1,
      flexDirection:"row",
      justifyContent: 'space-between',
      backgroundColor:"transparent",
      maxHeight:40,
      width:90,
      marginRight:10,
      marginLeft:"auto"
       },
        quantityPlusMinusCart :{
      fontWeight:"bold",
      marginTop:10,
        },
        
        plusMinusText:{
      fontWeight:"bold",
      color:"white",
       },
        plusMinusButton:{
          flex:1,
          justifyContent:"center",
          alignItems:"center",
          borderRadius:4,
      height:36,
      margin:2,
      maxWidth:30,
      backgroundColor:"grey"
      
      
        },
      
      productCartViewRemoveButton:{
      justifyContent:"center",
      padding:7,
        backgroundColor:"grey",
        height:140, 
        width:40,
      },
      
      ButtonToShowDetailSellerTextInCart:{
      width:"100%",
      marginTop: 10, backgroundColor:"transparent", height:40, width:120, borderRadius:4,justifyContent:"center", alignItems:"center",
      
      
      },
      ShowDetailSellerTextInCart:{
      
      },
      detailSellerPriceAndDeliveryCart:{

        width:"90%",
        backgroundColor:"white",
        marginLeft:"5%",
        marginRight:"5%", 

        borderWidth:1,
        borderColor:"rgba(0,0,0,0.3)",
        borderRadius:4,

    
      },
      detailChildViewText:{
width:"60%", height:"auto",paddingRight:20,backgroundColor:"transparent"



      },
      detailChildViewNumbers:{
        width:"40%", height:"auto",paddingRight:20,backgroundColor:"transparent"
        
        
              },

  
      
      sellerDetailViewText:{
        fontWeight:"bold",

      },
      sellerNameAndLink:{
       flexDirection:"row",
      
        alignItems:"space-between",
      height:40,
      width:"100%",
        backgroundColor:"rgba(0,0,0,0.3)",
      marginTop:-270,
      },
   
     
      
      textProductNameCart:{
        fontSize:17,
        fontWeight:"bold",
        paddingVertical:3,
      
      },
      textProductQuantityCart:{
       
        fontWeight:"bold",
      fontSize:12,
      paddingVertical:3,
      },
      textProductPriceCart:{
 
        fontWeight:"bold",
        fontSize:12,
        paddingVertical:3,
      },
      
      
      
logoImage:{
        height:60, width:60,margin:5,
        
        },
        loginButton :{
         
          justifyContent: 'center',
          alignItems: 'center',
        
        backgroundColor:"#00BFFF",
        maxWidth:50,
        height:50,
        
        
        marginRight:20,
        marginBottom:10,
        marginTop:10,
        
        
        
        
        
        
        
        },
     

                BackButton:{

                        height:50, width:50, backgroundColor:"transparent", alignItems:"center", justifyContent:"center", marginLeft:"auto",marginRight:"auto"
                      }
                    ,
                    ErrorView:{flex:1, flexDirection:"column",

height:"auto", backgroundColor:"rgba(222, 44, 44, 0.92)",
 alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,width:"98%",marginHorizontal:"1%",marginVertical:10,borderRadius:4, borderWidth:1, borderColor:"rgba(0,0,0,0.4)"
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",},
      
      });

export default Cart;