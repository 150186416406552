// YourComponentFile.js
import React, { useEffect, useState } from 'react';
import { View, Text,Dimensions, Platform } from 'react-native';

import { useMyContext } from './MyContext';
import { FontAwesome } from '@expo/vector-icons';



// First component
const BigTunizoonLogo = ({setBackgroundColor}) => {
  const screenWidth = Dimensions.get('window').width;

  const screenHeight = Dimensions.get('window').height;
  const marginTop=screenHeight/2-70;
    //to hide the splash screen after the first display
const {SplashScreenShowEnded,setSplashScreenShowEnded}=useMyContext(false);
  const[RerenderTrigger, setRerenderTrigger]=useState(false);
  const[CursorShown, setCursorShown]=useState(true);
  const[SmileyShown, setSmileyShown]=useState(false);
  const[currentText, setcurrentText]=useState(0);
const[TextColor,setTextColor]=useState('black');
const[theWidth,settheWidth]=useState(350);
const[theHeight,settheHeight]=useState("auto");


  const updateText = async () => {

    setFirstPart('');

    setSecondPart('');
    setThirdPart('');

    let ActualTextArray = ["we'll get it all here ","every single thing you may need ","countdown's on ","tuniz", "oo", "n.com",];
    const typingSpeed = 30; // Typing speed in milliseconds
    const delayBetweenParts =0; // Delay between parts in milliseconds
   let next=false;
    for (let i = 0; i < ActualTextArray.length; i++) {
      
      let currentPart;
 currentPart = ActualTextArray[i];
      



      for (let j = 0; j <= currentPart.length; j++) {
if(j%0===0){
        setCursorShown(precCursorShown=>!precCursorShown);
      }
        const currentChar = currentPart.charAt(j);
     
        switch (i) {


            case 0:

         
                setBackgroundColor("red");
                if (Platform.OS === 'web') {
                 // document.body.style.backgroundColor = 'red'; // Change body background color on web
                }
   
           
        setTextColor('white');
   
      
            setFirstPart(prevPart => prevPart + currentChar);

          
              break;
            
              case 1:
               if(!next){

                await sleep(1000);
   
                setBackgroundColor("#fcb103");
                if (Platform.OS === 'web') {
                 // document.body.style.backgroundColor = '#fcb103'; // Change body background color on web
                }
   
                setFirstPart('');
                setTextColor('#675980');
               }
            
             next=true;
              setFirstPart(prevPart => prevPart + currentChar);
   
              break;
                
              case 2:
               if(next){

                await sleep(1000);
                setBackgroundColor("green");
                if (Platform.OS === 'web') {
                 // document.body.style.backgroundColor = 'green'; // Change body background color on web
                }
                setFirstPart('');
                setTextColor('white');
               }
            
             next=false;
              setFirstPart(prevPart => prevPart + currentChar);
              break;


              case 3:
           
                if(!next){
               
                  await sleep(1000);

                  setBackgroundColor("#00BFFF");
                  if (Platform.OS === 'web') {
                   // document.body.style.backgroundColor = '#00BFFF'; // Change body background color on web
                  }
                  setTextColor('black');
                setFirstPart('');
                setSmileyShown(true);
                settheWidth(316);
                settheHeight(60);
              }
             
                next=true;
             
              setFirstPart(prevPart => prevPart + currentChar);

              break;
            case 4:
            
              setSecondPart(prevPart => prevPart + currentChar);
         
              break;
            case 5:
        
            setCursorShown(precCursorShown=>false);
              setThirdPart(prevPart => prevPart + currentChar);
           
       
      
              if(j== currentPart.length){
        
               
  
             
                await sleep(1000);
  
         
                setCursorShown(prevCursorShown=>false);
                 
                setSplashScreenShowEnded(true);
              }
           
              break;
             
         default:
            break;
        }






  
        await sleep(typingSpeed);
     
      }

      if (i < ActualTextArray.length - 1) {
        await sleep(delayBetweenParts);
      }
  
    }






  };
  
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  

  useEffect(() => {

    updateText();

  }, [RerenderTrigger]);

  const[FirstPart,setFirstPart]=useState('');
  const[SecondPart,setSecondPart]=useState('');
  const[ThirdPart,setThirdPart]=useState('');
  return (

    <View style={{ height:screenHeight, width:"100%",}}>

    <View style={{width:theWidth,maxWidth:theWidth,marginLeft:(screenWidth-theWidth)/2, marginRight:(screenWidth-theWidth)/2,

    position:Platform.OS==="web"?"fixed":"absolute", 
    top:Platform.OS==="web"?marginTop:"42%"}}>
      <View style={{height:theHeight, maxHeight:theHeight, }}>
  <Text style={{fontSize:50, fontWeight:"bold",color:TextColor,  lineHeight: null, }}>{FirstPart}<Text style={{fontSize:50, fontWeight:"bold",color:"#fcb103"}}>{SecondPart}</Text>{ThirdPart}<FontAwesome name="circle" size={30} color={CursorShown?TextColor:"transparent"}/></Text>
  </View>

  {SmileyShown?(<View style={{width:"100%", flex:1, flexDirection:"row",maxHeight:20,overflow:"hidden"}}>
<View style={{height:20, width:103, }}>

</View>
 <View style={{  height: 80,width:80, borderRadius:80, borderWidth:6,
  borderBottomColor:"#fcb103", borderTopColor:"transparent",
  borderLeftColor:"transparent", borderRightColor:"transparent",   transform: [{ translateY: -65 }],
  }}>
 </View>

  </View>
  ):null}

      </View>

         

   
      </View>
  );
};

export default BigTunizoonLogo;
