import React, { createContext, useContext, useState,useEffect } from 'react';

// Create the context
export const MyContext = createContext();

// Create a custom hook to access the context values
export const useMyContext = () => useContext(MyContext);

// Create a provider component to wrap the app
export const MyContextProvider = ({ children }) => {

        //type writer end
      const  [TypeWritercompleted, setTypeWritercompleted] = useState(false);
  //check if all seller in cart have a rib
  const [ribForAllSellerIsThere, setribForAllSellerIsThere]=useState(true);
  //to update the product
  const[ProductChangeTrigger,setProductChangeTrigger]=useState(false);
//to hide the splash screen after the first display
const [SplashScreenShowEnded,setSplashScreenShowEnded]=useState(false);
//input search to show user products based on inputs
const [userSearchInput, setuserSearchInput]=useState('');
  //seller construction array
  const [sellersArray, setsellersArray]=useState([]);
  //cart variables
  const [cart, setCart] = useState([]);
  const [countProductCart, setCount] = useState(0);
const [sellersSku, setsellersSku] = useState([]);
const[totalPrice, settotalPrice]=useState(0);
//user variable
const[User, setUser]=useState({});
const[UserIsLoggedIn, setUserIsLoggedIn]=useState(false);

const[Seller, setSeller]=useState([]);
const[sellerIsLoggedIn, setsellerIsLoggedIn]=useState(false);
//admin section

const[Admin, setAdmin]=useState({});
const[AdminIsLoggedIn, setAdminIsLoggedIn]=useState(false);

const[Sellers, setSellers]=useState({});
const[Users, setUsers]=useState({});
const[Products, setProducts]=useState({});
const[Orders, setOrders]=useState({});
const[adminOrderIdData, setadminOrderIdData]=useState();

const[adminOrderIdDataKopie, setadminOrderIdDataKopie]=useState();





const[userLikeProduct, setuserLikeProduct]=useState([]);
const[userDislikeProduct, setuserDislikeProduct]=useState([]);
//seller variable
const [userLikeSeller, setuserLikeSeller]=useState([]);
const [userDislikeSeller, setuserDislikeSeller]=useState([]);
//context trigger 
const [contextTrigger, setcontextTrigger]=useState(false);

const [totalDeliveryForAll, setTotalDeliveryForAll] = useState(0);
//user notification
const [OrdersNotificationUser ,setOrdersNotificationUser]=useState({});
const [ReplyNotificationUserProduct , setReplyNotificationUserProduct]= useState({});
const [ReplyNotificationUserSeller , setReplyNotificationUserSeller]= useState({});
const [OrdersNotificationsFrames , setOrdersNotificationsFrames]= useState({});
const [userNotificationCount , setuserNotificationCount]= useState(0);

//seller notification
const [OrdersNotificationSeller , setOrdersNotificationSeller]= useState({});
const [CommentNotificationSellerProduct , setCommentNotificationSellerProduct]= useState({});
const [CommentNotificationSellerShop , setCommentNotificationSellerShop]= useState({});
const [ReplyNotificationSellerProduct , setReplyNotificationSellerProduct]= useState({});
const [ReplyNotificationSellerSeller , setReplyNotificationSellerSeller]= useState({});
const [SellerNotificationCount, setSellerNotificationCount]= useState(0);
const [OrdersNotificationsFramesSeller , setOrdersNotificationsFramesSeller]= useState({});
const [likeOrDislikeProducts, setlikeOrDislikeProducts]= useState({});
const [likeOrDislikeShop , setlikeOrDislikeShop]=  useState({});



const [VirtualzedShown,setVirtualzedShown]=useState(true);
const [VirtualizedShownSellerHomePage,setVirtualizedShownSellerHomePage]=useState(true);
const [VirtualizedShownSellerPage,setVirtualizedShownSellerPage]=useState(true);

//seller notification
//hold payement data for new user first order with auto register
const [payementDataNewUser , setpayementDataNewUser]=useState({});
useEffect(() => {
 
  }, [cart,sellersSku,sellersSku, totalPrice]);
  return (
    <MyContext.Provider value={{
//for end type writer
      TypeWritercompleted, setTypeWritercompleted,
      //check if all seller in cart have a rib
      ribForAllSellerIsThere, setribForAllSellerIsThere,
      //to update the prduct
      ProductChangeTrigger,setProductChangeTrigger,
      //otehr
      SplashScreenShowEnded,setSplashScreenShowEnded,
      userSearchInput, setuserSearchInput,
      sellersArray, setsellersArray,
       cart, setCart,
       countProductCart, setCount ,
       VirtualzedShown,setVirtualzedShown,
       VirtualizedShownSellerHomePage,setVirtualizedShownSellerHomePage,
       VirtualizedShownSellerPage,setVirtualizedShownSellerPage,
    sellersSku, setsellersSku,
    totalPrice, settotalPrice,
    totalDeliveryForAll, setTotalDeliveryForAll,
    User,setUser,
    UserIsLoggedIn, setUserIsLoggedIn,

//only admin
    Admin,setAdmin,
    AdminIsLoggedIn, setAdminIsLoggedIn,
    Sellers, setSellers,
    Products, setProducts,
    Orders, setOrders,
    adminOrderIdData, setadminOrderIdData,
    adminOrderIdDataKopie, setadminOrderIdDataKopie,
    Users, setUsers,

//only seller
     Seller,setSeller ,
     sellerIsLoggedIn, setsellerIsLoggedIn,
     userLikeProduct, setuserLikeProduct, 
     userDislikeProduct, setuserDislikeProduct,

    userLikeSeller, setuserLikeSeller,
     userDislikeSeller, setuserDislikeSeller,
     contextTrigger, setcontextTrigger,
     //user Notifications
     OrdersNotificationUser ,setOrdersNotificationUser,
     OrdersNotificationsFrames , setOrdersNotificationsFrames,
     ReplyNotificationUserProduct , setReplyNotificationUserProduct,
     ReplyNotificationUserSeller , setReplyNotificationUserSeller,
     userNotificationCount , setuserNotificationCount,
     //seller Notifications
     OrdersNotificationSeller , setOrdersNotificationSeller,
     CommentNotificationSellerProduct , setCommentNotificationSellerProduct,
     CommentNotificationSellerShop , setCommentNotificationSellerShop,

     ReplyNotificationSellerProduct , setReplyNotificationSellerProduct,
     ReplyNotificationSellerSeller , setReplyNotificationSellerSeller,
     SellerNotificationCount, setSellerNotificationCount,
     OrdersNotificationsFramesSeller , setOrdersNotificationsFramesSeller,
     likeOrDislikeProducts, setlikeOrDislikeProducts,
     likeOrDislikeShop , setlikeOrDislikeShop,

     payementDataNewUser , setpayementDataNewUser


    }}>
      {children}
    </MyContext.Provider>
  );
};