// YourComponentFile.js
import React from 'react';
import { View } from 'react-native';
import Svg, { Text, TSpan, Path } from 'react-native-svg';

// First component
const TunizoonLogo = () => {
  return (
    <View style={{ height: 70,paddingTop:10, width: 140, flexDirection: 'column', }}>
      <Svg height="40" width="220">
        <Text x="10" y="36" fontFamily="Arial" fontSize="20" fontWeight="bold" fill="black">
          tuniz
          <TSpan dx="0" dy="0" fill="#fcb103" fontSize="20" fontWeight="bold">
            oo
          </TSpan>
          n.com
        </Text>
      </Svg>
      <View style={{ height: 20, paddingLeft: 57, justifyContent: 'center' }}>
        <Svg height="20" width="120">
          <Path
            d="M2,2 A2,1 0 0,0 20,2"
            fill="none"
            stroke="#fcb103"
            strokeWidth="4"
            transform="scale(1, 0.8)"
          />
        </Svg>
      </View>
    </View>
  );
};



export default TunizoonLogo ;
