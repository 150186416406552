import React, { useState, useEffect, useRef } from 'react';
import { Text, StyleSheet,View,Image, Platform, Pressable } from 'react-native';
import * as Haptics from 'expo-haptics';
import { FontAwesome } from '@expo/vector-icons';
import { useMyContext } from './MyContext';


const Typewriter = ({ text, delay,color, noHaptics}) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
const[CursorShown, setCursorShown] = useState(true);
const[animationFloomy, setanimationFloomy] = useState(0);



// Function to trigger haptic feedback with irregular intervals and pauses

const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
    if(!noHaptics){
 // Haptics.notificationAsync(
   // Haptics.NotificationFeedbackType.Success);
  }
  }
}
 

const {TypeWritercompleted, setTypeWritercompleted} = useMyContext(false);

const [dots, setDots] = useState('');



useEffect(() => {
  if (!TypeWritercompleted) {
    const interval = setInterval(() => {
      setanimationFloomy(prevAnimationFloomy => prevAnimationFloomy + 1);
    }, 300);

    return () => clearInterval(interval); // Clear the interval on component unmount
  }
}, [TypeWritercompleted]);




//end of the animation
const completeAction = () => {
  setTimeout(() => {
    setTypeWritercompleted(true);
  }, 200);

};









//update dots
const updateDots = () => {
  if (dots.length === 3) {
    setDots('');
  } else {
    setDots(prevDots => prevDots + '.');
  }
};





  useEffect(() => {

  
// Split the text into an array of words
// Split the text into an array of words
const words = text.split(' ');

// Create an array of word lengths, pushing 0 after each length
const wordLengthsWithZero = words.flatMap(word => [word.length, 0]);


    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        if(text[currentIndex+1]!=="#"){
        setCurrentText(prevText => prevText + text[currentIndex]);
     
        setCurrentIndex(prevIndex => prevIndex + 1);
      }
      else{
        setCurrentText(prevText => prevText + text.slice(currentIndex, currentIndex + 9));
     
        setCurrentIndex(prevIndex => prevIndex + 9);

      }

    if(text[currentIndex]===' '||currentIndex%15===0 ||currentIndex%5===0 ){
      setCursorShown(!CursorShown);

     triggerHapticFeedback();

      }



      if(currentIndex%10===0 ){
      updateDots();
    }
      if(currentIndex===text.length-1){

        setCursorShown(true);
        completeAction();
      }
     


      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);










  return (
    <View  style={styles.container}>







<View style={styles.typedTextContainer}>
<View style={[
      styles.logoContainer,
      {
        borderTopColor: animationFloomy%3===0? '#fffbe6' : '#0892D0',
        borderBottomColor: animationFloomy%3===0 ? '#fffbe6' : '#0892D0',
        borderLeftColor: animationFloomy%2===0 ? '#fffbe6' : '#0892D0',
        borderRightColor: animationFloomy%2===0 ? '#fffbe6' : '#0892D0',
      }
    ]}>
   
      <Image style={styles.logo} source={{uri:"https://www.tunizoon.com/chatbot.jpg"}}/>
   
    </View>

      <Text style={[styles.text, { color: color }]}> 
      <Text style={{fontWeight:"bold",color:color,}}>               Floomy {dots} {'\n'}</Text>              {currentText} 
      <Text style={[styles.cursor, { color: color }]}>
        <View style={{backgroundColor:"transparent", paddingLeft:5, paddingtop:7}}>
          <Text><FontAwesome name="circle" size={16} color={CursorShown?color:"transparent"} /></Text>
         </View>
         </Text>


      </Text>
 </View>








    </View>
  );
};

const styles = StyleSheet.create({
  container:{flex:1, flexDirection:"column",
   width:"100%",backgroundColor:"transparent",padding:10,

},



logoContainer: {
  justifyContent:"center", alignItems:"center",
  height: 48,
  width: 48,
  marginRight: 20,
  borderRadius: 10,
  borderWidth: 4,
  marginBottom:-38,

},


typedTextContainer:{width:"auto",textAlign:"left", marginTop:10,},






  logo:{height:36, width:36, borderRadius:4,
  },
  text: {
    fontSize: 16,
    padding:0,margin:0,

  },
});

export default React.memo(Typewriter);
